import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../redux/hook';
import ReunionTable from './Table/ReunionAdminTable';
import { useGetPaysQuery } from '../../../utils/api/pays/pays.api';
import Select from "react-select";
import { isSuperAdmin } from '../../../routes/routerUtils';
import { IUser } from '../../../utils/api/user/user.type';

function getUser(item: IUser) {
  let role = "";

  if (item?.user_type === "membre") {
    if (item?.conseil_administration) {
      role = `conseil_administration`;
    }

    if (item?.conseil_pastoral) {
      role = `conseil_pastoral`;
    }
    if (item?.office_pastoral) {
      role = `conseil_pastoral`;
    }
  }

  return role;
}

const ReunionAdmin = () => {
  const [options, setOptions] = useState<{ label: string; value: number }[]>(
    []
  );
  const { user } = useAppSelector((state) => state.user);
  const [option, setOption] = useState<{ label: string; value: number }>();
  const [inputValue, setInputValue] = useState("");
  const { data = { results: [] } } = useGetPaysQuery({
    q: inputValue,
    limit: 1000,
  });

  const [q_pays, setQPays] = useState("");
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (user?.pays) {
      setQPays(user?.pays?.id);
    }
  }, [user]);

  useEffect(() => {
    if (data?.results?.length) {
      setOptions(
        data?.results?.map((el) => {
          return {
            label: el?.nom,
            value: el?.id,
          };
        })
      );
    }
  }, [data]);

  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <h2 className="chart-item-admin-title mb-4">Tous les réunions</h2>
        <div className="content-graph-admin">
          
            <div className="row country-and-search-bar-content pb-4">
            {is_SuperAdmin && (<div className="col-md-3 country-selection">
                <Select
                  options={options || []}
                  onChange={(val: any) => setOption(val)}
                  value={option}
                  onInputChange={(val) => setInputValue(val)}
                  isClearable
                  placeholder="Pays"
                />
              </div> )}
              <div className="col-md-4 search-bar-container d-flex align-items-center gap-2">
                <input
                  type="search"
                  placeholder="Chercher"
                  className="search-bar-conseil"
                  onChange={(e) => setQuery(e?.target?.value)}
                />
              </div>
            </div>
         
          <div className="row py-5">
            <ReunionTable query={query} pays={option?.value || ""} q_type={getUser(user)} q_pays={q_pays} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReunionAdmin;