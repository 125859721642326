import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { AlertInfo } from "../../../common/Alert";
import TableSkeleton from "../../../common/TableSkeleton";
import { DeleteMembre } from "../../OfficePastoral/OfficePastoralTable/OfficePastoralTable";
import { CustomPagination } from "../../../common/CustomPagination";
import { useAppSelector } from "../../../../redux/hook";
import { isSuperAdmin } from "../../../../routes/routerUtils";
import { useGetReunionsQuery } from "../../../../utils/api/reunion/reunion.api";
import moment from "moment";
import { BsTrash3 } from "react-icons/bs";
import { UseDeleteReunion } from "../../ConseilAdministration/requestForm/UseCrudReunion";

function ReunionTable({ query, pays, q_type, q_pays }: any) {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data, isLoading } = useGetReunionsQuery({
    limit: perPage,
    page,
    q: query,
    q_pays: pays ? pays : q_pays,
    q_type,
  });
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));
  console.log("reunion", data)
  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          {is_SuperAdmin && <DeleteReunionBtn slug={row?.slug} />}
        </div>
      </>
    );
  };

  const dateFormatter = (cell: any, row: any) => {
    return (
      <div className="d-flex gap-3 align-items-center">
        <p className="name-profil-patient-table mb-0">
          {moment(cell).format("ll")}
        </p>
      </div>
    );
  };
  const heureFormatter = (cell: any, row: any) => {
    return (
      <div className="d-flex gap-3 align-items-center">
        <p className="name-profil-patient-table mb-0">
          {moment(cell, "HH:mm").format("HH:mm A")}
        </p>
      </div>
    );
  };
  const paysFormatter = (cell: any, row: any) => {
    return (
      <div className="d-flex gap-3 align-items-center">
        <p className="name-profil-patient-table mb-0">
          {row?.pays?.nom}
        </p>
      </div>
    );
  };
  const columns = [
    {
      dataField: "titre",
      text: `Titre`,
      headerStyle: () => {
        return { width: "250px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "date",
      text: "Date",
      headerStyle: () => {
        return { width: "200px", whiteSpace: "normal" };
      },
      formatter: dateFormatter
    },
    {
      dataField: "pays",
      text: "Pays",
      formatter: paysFormatter
    },
    {
      dataField: "heure_debut",
      text: "Heure de debut",
      formatter: heureFormatter
    },
    {
      dataField: "heure_fin",
      text: "Heure de fin",
      formatter: heureFormatter
    },
    {
      dataField: "id",
      text: "Action",
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results || []}
            columns={columns}
            condensed
            hover
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvée" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage);
                setPage(page);
              }}
              perPage={perPage}
            />
          </div>
        </>
      )}
    </>
  );
}

export default ReunionTable;

function DeleteReunionBtn({ slug }: { slug: string }) {
    const onDelete = UseDeleteReunion(slug);
    return (
      <button 
      className="btn with-tooltip btn-action-icon-delete" 
      data-tooltip-content="Supprimer"
      onClick={() => onDelete()}
      style={{ color: "#E53E3E" }}
      >
        <BsTrash3 />
      </button>
    //   <button
    //   className="btn with-tooltip btn-action-icon-delete"
    //   data-tooltip-content="Supprimer"
    //   style={{ color: "#E53E3E" }}
    //   onClick={deleteMembre}
    // >
    //   <BsTrash3 />
    // </button>
    );
  }