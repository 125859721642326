/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, useState } from "react";
import { useGetDocumentationsQuery } from "../../../../utils/api/documentations/documentations.api";
import { AlertInfo } from "../../../common/Alert";
import {
  createMarkup,
  getAvatar,
  truncateString,
} from "../../../../utils/Utils";
import moment from "moment";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { IDocumentation } from "../../../../utils/api/documentations/documentations.type";
import AddArchivesModal from "../modals/AddArchivesModal";
import { UseDeleteDocumentations } from "../requestForm/UseCrudDocumentation";
import AddVideosModal from "../modals/AddVideosModal";
import Play from "../../../../assets/icons/play.svg";
import ReactPlayer from "react-player";
import { ApiBaseUrl } from "../../../../utils/http";
import ArchiveSkeleton from "../ArchiveSkeleton";
import { CustomPagination } from "../../../common/CustomPagination";
import { boolean } from "yup";

const ArchiveItem = ({
  item,
  type,
  setType,
  format,
  setFormat,
  isSecretaire
}: {
  item: IDocumentation;
  type: string;
  setType: Dispatch<SetStateAction<string>>;
  format: string;
  setFormat: Dispatch<SetStateAction<string>>;
  isSecretaire: boolean;
}) => {
  const [show, setShow] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  return (
    <>
      <div className="content-testimonial-item">
        <div className="d-flex align-items-center justify-content-between">
          <p className="name-user-archive mb-1">{item?.titre}</p>
          <div className="btn-group">
            {isSecretaire && <button
              className="btn btn-secondary btn-sm dropdown-toggle documentation-archive-custom-btn"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <BiDotsHorizontalRounded />
            </button>}
            <ul className="dropdown-menu">
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => {
                    setType("archive")
                    if (item?.format === "textes") {
                      setShow(true);
                    } else {
                      setShowVideo(true);
                    }
                  }}
                >
                  Modifier
                </button>
              </li>

              <li>
                <DeleteArchiveBtn slug={item?.slug} />
              </li>
            </ul>
          </div>
        </div>
        <div className="content-body-testimonial pt-3">
          {item?.format === "textes" ? (
            <p
              className="text-body-archive"
              dangerouslySetInnerHTML={createMarkup(
                truncateString(item?.content, 400)
              )}
            />
          ) : (
            <ReactPlayer
              className="custom-video-archive mb-3"
              controls
              url={ApiBaseUrl + item?.fichier}
              playing={false}
              playIcon={<img src={Play} alt="video" />}
              width="100%"
              height={250}
            />
          )}
        </div>
        <div className="display-info-text-archive">
          <div className="item-display-info-text-archive">
            <p className="text-libelle-info-archive">Date:</p>
            <p className="text-value-info-archive">
              {moment(item?.created_at).format("LL")}
            </p>
          </div>
          <div className="item-display-info-text-archive">
            <div className="content-img-pofil-user-archive">
              <img
                src={getAvatar(item?.user?.avatar)}
                alt="Profil"
                className="img-pofil-user-archive"
              />
            </div>
            <p className="text-value-info-archive">
              {item?.user?.prenom + " " + item?.user?.nom}
            </p>
          </div>
        </div>
      </div>
      <AddArchivesModal
        modalAddArchives={show}
        setModalAddArchives={setShow}
        typeDocumentations={type}
        setTypeDocumentations={setType}
        documentation={item}
      />
      <AddVideosModal
        documentation={item}
        modalAddVideo={showVideo}
        setModalAddVideo={setShowVideo}
        typeDocumentations={type}
        setTypeDocumentations={setType}
        formatDocumentations={format}
        setFormatDocumentations={setFormat}
      />
    </>
  );
};

function ArchivesTab({
  typeDocumentations,
  setTypeDocumentations,
  formatDocumentations,
  setFormatDocumentations,
  query,
  queryFormat,
  is_Secretaire
}: {
  typeDocumentations: any;
  setTypeDocumentations: any;
  modalAddVideo: any;
  setModalAddVideo: any;
  formatDocumentations: any;
  setFormatDocumentations: any;
  query: string;
  queryFormat: string;
  is_Secretaire: boolean;
}) {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [documentation, setDocumentation] = useState<
    IDocumentation | undefined
  >();

  const { data, isLoading } = useGetDocumentationsQuery({
    q_titre: query,
    q_format: queryFormat,
    q_type: "archive",
    page,
    limit: perPage,
  });

  return (
    <div className="content-testimonial-page">
      <div className="row">
        {!!isLoading && <ArchiveSkeleton />}
        {!isLoading &&
          (!!data?.results && data?.results?.length > 0 ? (
            data?.results?.map((item) => (
              <div className="col-lg-6 col-md-12 mb-3" key={item?.id}>
                <ArchiveItem
                  item={item}
                  type={typeDocumentations}
                  setType={setTypeDocumentations}
                  format={formatDocumentations}
                  setFormat={setFormatDocumentations}
                  isSecretaire={is_Secretaire}
                />
              </div>
            ))
          ) : (
            <AlertInfo message="Aucune donnée trouvée" />
          ))}
      </div>
      {data?.results && (
        <div className="custom-pagination-container">
          <CustomPagination
            nbPages={data?.count}
            page={page}
            onChange={(page, perPage) => {
              setPerPage(perPage);
              setPage(page);
            }}
            perPage={perPage}
          />
        </div>
      )}
      {/* <AddArchivesModal
        modalAddArchives={modalAddArchives}
        setModalAddArchives={setModalAddArchives}
        typeDocumentations={typeDocumentations}
        setTypeDocumentations={setTypeDocumentations}
        documentation={documentation}
        setDocumentation={setDocumentation}
      /> */}
      {/* <AddVideosModal
        documentation={documentation}
        modalAddVideo={modalAddVideo}
        setModalAddVideo={setModalAddVideo}
        typeDocumentations={typeDocumentations}
        setTypeDocumentations={setTypeDocumentations}
        formatDocumentations={formatDocumentations}
        setFormatDocumentations={setFormatDocumentations}
        setDocumentation={setDocumentation}
      /> */}
    </div>
  );
}

export default ArchivesTab;

function DeleteArchiveBtn({ slug }: { slug: string }) {
  const onDelete = UseDeleteDocumentations(slug);

  return (
    <button className="dropdown-item" onClick={() => onDelete()}>
      Supprimer
    </button>
  );
}
