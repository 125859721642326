import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { AlertInfo } from "../../../../common/Alert";
import TableSkeleton from "../../../../common/TableSkeleton";
import { CustomPagination } from "../../../../common/CustomPagination";
import {
  formatCurrency,
  formatDateHour,
  getName,
} from "../../../../../utils/Utils";
import { IUser } from "../../../../../utils/api/user/user.type";
import { types } from "../../FinanceTabs/TabBanner";

function FinancesAdminTable(props) {
  const { data, isLoading, page, setPage, limit, setLimit } = props;
  // const [pages, setPages] = useState(FinancesAdminTableDatas);
  // const actionFormatter = (cell: any, row: any) => {
  //   return (
  //     <>
  //       <div className="table-actions-btn-container-commandes d-flex gap-3">
  //         <div className="container-btn-action-icon-modal">
  //           <NavLink
  //             to={`/admin/sous-admins/${row?.slug}`}
  //             className="btn btn-action-modal-icon with-tooltip"
  //             data-tooltip-content="Voir"
  //           >
  //             <AiFillEye />
  //           </NavLink>
  //         </div>
  //         <div className="container-btn-action-icon-modal">
  //           <NavLink
  //             to={`/admin/modifier-sous-admin/${row?.slug}`}
  //             className="btn btn-action-icon-edit with-tooltip"
  //             data-tooltip-content="Modifier"
  //           >
  //             <MdEdit />
  //           </NavLink>
  //         </div>
  //         <div className="container-btn-action-icon-modal">
  //           <button
  //             className="btn with-tooltip btn-action-icon-delete"
  //             data-tooltip-content="Supprimer"
  //             style={{ color: "#E53E3E" }}
  //           >
  //             <BsTrash3 />
  //           </button>
  //         </div>
  //       </div>
  //     </>
  //   );
  // };

  const typeFormatter = (cell: string) => {
    let name = types?.find((type) => type?.value === cell);
    return (
      <div className="content-format-type-finance-table">
        <span className={`format-type-${cell}-table mb-0`}>{name?.label}</span>
      </div>
    );
  };
  const columns = [
    {
      dataField: "created_at",
      text: `Date`,
      formatter: (cell: string) => formatDateHour(cell),
    },
    {
      dataField: "user",
      text: "Nom",
      formatter: (cell: IUser) => getName(cell),
    },
    {
      dataField: "montant_converted",
      text: "Montant",
      formatter: (cell: string) => formatCurrency(cell),
    },
    {
      dataField: "type_collecte",
      text: "Type",
      formatter: typeFormatter,
    },
    // {
    //   dataField: "slug",
    //   text: "Action",
    //   formatter: actionFormatter,
    // },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            condensed
            hover
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvée" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default FinancesAdminTable;
