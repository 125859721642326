import React from "react";
import { MdAddCircleOutline } from "react-icons/md";
import ThemeTable from "../table/ThemeTable";
import CrudThemeModal from "../modal/CrudThemeModal";
import { showModal } from "../../../../utils/Utils";
import { useAppSelector } from "../../../../redux/hook";
import { isResponsableParoissien } from "../../../../routes/routerUtils";

function ThemesStep() {
  const is_Responsable = useAppSelector((s) =>
    isResponsableParoissien(s.user?.user)
  );
  return (
    <div>
      {is_Responsable && <div className="btn-action-and-search d-flex justify-content-between align-items-center pt-4 pb-5">
        <div className="btn-actions">
          <button
            className="btn custom-add-btn"
            onClick={() => showModal("addThemeModal")}
          >
            Ajouter un thème
            <MdAddCircleOutline className="add-icon" />
          </button>
        </div>
      </div>}

      <div className="tabs-component-paroisse pt-4">
        <ThemeTable is_Responsable={is_Responsable} />
      </div>
      <CrudThemeModal modalId="addThemeModal" />
    </div>
  );
}

export default ThemesStep;
