import React, { useEffect, useState } from "react";
import "./FormationAdmin.css";
import { useStepType } from "../../../utils/type";
import { useStep } from "react-hooks-helper";
import { MdAddCircleOutline } from "react-icons/md";
import Seminaire from "./tabs/Seminaire";
import Bapteme from "./tabs/Bapteme";
import Onction from "./tabs/Onction";
import { NavLink } from "react-router-dom";
import { useGetListFormationQuery } from "../../../utils/api/formations/formation.api";
import { PiListMagnifyingGlassThin } from "react-icons/pi";
import { CustomPagination } from "../../common/CustomPagination";
import Skeleton from "react-loading-skeleton";
import { AiOutlineMore } from "react-icons/ai";
import { useAppSelector } from "../../../redux/hook";
import { isSuperAdmin } from "../../../routes/routerUtils";

const steps = [
  { id: "seminaire", Component: Seminaire },
  { id: "bapteme", Component: Bapteme },
  { id: "onction", Component: Onction },
];
const tabs = ["Séminaires", "Cours sur le baptème", "Onction"];

function FormationAdmin() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const [type, setType] = useState("seminaire");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));
  const { data = { results: [], count: 0 }, isLoading } =
    useGetListFormationQuery({
      type,
      page,
      limit,
    });

  useEffect(() => {
    setType(step?.id);
    setPage(1);
    setLimit(12);
  }, [step]);

  const props = {
    data,
    isLoading,
    is_SuperAdmin
  };

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <h2 className="chart-item-admin-title mb-4">Formations</h2>
      </div>

      <div className="formation-page-content">
        <div className="eglise-dash-tabs mb-0 bg-white pt-3 px-4">
          <ul className="tabs-container tabs-container-admin mb-0">
            {tabs.map((tab: any, key: any) => (
              <li
                className={`tab-item  ${
                  index === key && "tab-active-item-admin"
                }`}
                key={key}
                onClick={() => go(key)}
                role="button"
              >
                {tab}
              </li>
            ))}
          </ul>
        </div>

        <div className="formation-tabs-component pt-4">
          <Component {...props} />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
              showPerPage={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormationAdmin;

export const FormationSkeleton = () => {
  return (
    <>
      {[...Array(12)]?.map((el, i) => (
        <div className="col-md-3 card-wrapper mb-4" key={i}>
          <div className="custom-formation-card">
            <span className="video-cover-content">
              <Skeleton className="formation-video" height={190} />
            </span>

            <Skeleton className="title" />
            <div className="d-flex justify-content-between align-items-baseline">
              <span className="author-skeleton pt-2">
                <div className="div-skeleton-icon">
                  <Skeleton className="icon" />
                </div>

                <Skeleton width={`90%`} height={20} />
              </span>
              <div className="btn-group">
                <button
                  className="btn btn-secondary btn-sm dropdown-toggle documentation-archive-custom-btn"
                  type="button"
                >
                  <AiOutlineMore style={{ fontSize: 18 }} />
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
