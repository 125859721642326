import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { getAvatar } from '../../../utils/Utils';
import { AlertInfo } from '../../common/Alert';
import { CustomPagination } from '../../common/CustomPagination';
import PasteurAdminSkeleton from '../PasteursAdmin/PasteurAdminSkeleton';
import { useGetSecretariesQuery } from '../../../utils/api/secretary/secretary.api';
import { useAppSelector } from '../../../redux/hook';
import { isSuperAdmin } from '../../../routes/routerUtils';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { ISecretary } from '../../../utils/api/secretary/secretary.type';
import { UseDeleteSecretaire } from './form/UseCrudSecretaryForm';



const SecretariesAdmin = () => {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(12);
    const { data, isLoading } = useGetSecretariesQuery({
        page,
        limit: perPage,
    });
    const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));
    return (
        <div className="dashbord-admin-component">
            <div className="dash-admin-page-content-container mb-3">
                <h2 className="chart-item-admin-title mb-4">Sécrétaires</h2>
                <div className="content-graph-admin">
                    {is_SuperAdmin && <div className="container-title-dash">
                        <div className="content-btn-theme-title">
                            <NavLink
                                to="/admin/secretaries/add-new"
                                className="btn btn-theme-admin"
                            >
                                Nouveau(elle) sécrétaire
                            </NavLink>
                        </div>
                    </div>}
                    <div className="row py-5">
                        {!!isLoading && <PasteurAdminSkeleton />}
                        {!isLoading &&
                            (!!data && data?.results?.length > 0 ? (
                                data?.results?.map((item) => (
                                    <div
                                        className="col-lg-3 col-md-6 col-item-item text-center mb-3 d-flex"
                                        key={item?.slug}
                                    >
                                        <div className={`w-100`}>
                                            <div className="content-col-item-item w-100 d-flex flex-column position-relative">
                                                <div className="btn-group custom-pos-absolute">
                                                    {is_SuperAdmin && <button
                                                        className="btn btn-secondary btn-sm dropdown-toggle documentation-archive-custom-btn"
                                                        type="button"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        <BiDotsHorizontalRounded />
                                                    </button>}
                                                    <ul className="dropdown-menu">
                                                        <li>
                                                            <DeleteSecretaire item={item} />
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="content-img-profil-item py-3">
                                                    <img
                                                        src={getAvatar(item?.avatar)}
                                                        alt={item?.prenom}
                                                        className="pasteur-img-avatar"
                                                    />
                                                </div>
                                                <p className="name-item-admin">
                                                    {item?.prenom + " " + item?.nom}
                                                </p>
                                                {!!item?.type_secretaire && (
                                                    <p className="py-2">
                                                        <label>Sécrétaire {item?.type_secretaire}</label>
                                                    </p>)}
                                                <div className="container-btn-viez-profil-item pt-3 mt-auto">
                                                    <NavLink
                                                        to={`/admin/secretaries/${item?.slug}`}
                                                        className="btn btn-view-profil-item"
                                                        state={item}
                                                    >
                                                        Voir le profil
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <AlertInfo message="Aucune donnée trouvée" />
                            ))}
                    </div>
                    {data?.results && (
                        <div className="custom-pagination-container">
                            <CustomPagination
                                nbPages={data?.count}
                                page={page}
                                onChange={(page, perPage) => {
                                    setPerPage(perPage);
                                    setPage(page);
                                }}
                                perPage={perPage}
                            />
                        </div>)}
                </div>
            </div>
        </div>
    )
}

export default SecretariesAdmin;

export function DeleteSecretaire({ item }: { item: ISecretary }) {
    const deleteSecretaire = UseDeleteSecretaire(item);
    return (
      <div className="container-btn-action-icon-modal">
        <button
          className="dropdown-item"
          onClick={deleteSecretaire}
        >
          Supprimer
        </button>
      </div>
    );
  }