import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { AiFillEye } from "react-icons/ai";
import { AlertInfo } from "../../../common/Alert";
import TableSkeleton from "../../../common/TableSkeleton";
import { NavLink } from "react-router-dom";
import { useGetMembresConseilPastoralQuery } from "../../../../utils/api/membre/membre.api";
import { DeleteMembre } from "../../OfficePastoral/OfficePastoralTable/OfficePastoralTable";
import { getAvatar } from "../../../../utils/Utils";
import { CustomPagination } from "../../../common/CustomPagination";
import { useAppSelector } from "../../../../redux/hook";
import { isSuperAdmin } from "../../../../routes/routerUtils";

function MembresTable({ q_type }) {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data, isLoading } = useGetMembresConseilPastoralQuery({
    limit: perPage,
    page,
    q_type,
  });

  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));

  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          {is_SuperAdmin && 
            <>
              <div className="container-btn-action-icon-modal">
                <NavLink
                  to={`/admin/conseil-pastoral/${row.slug}`}
                  className="btn btn-action-modal-icon with-tooltip"
                  data-tooltip-content="Voir"
                  state={row}
                >
                  <AiFillEye />
                </NavLink>
              </div>
              <DeleteMembre item={row} />
            </>
          }
        </div>
      </>
    );
  };
  const nameFormatter = (cell: any, row: any) => {
    return (
      <div className="d-flex gap-3 align-items-center">
        <div className="content-img-profil-itemt-table">
          <img
            src={getAvatar(row?.avatar)}
            alt="Profil"
            className="img-profil-itemt-table"
          />
        </div>
        <p className="name-profil-patient-table mb-0">
          {row?.prenom + " " + row?.nom}
        </p>
      </div>
    );
  };
  const columns = [
    {
      dataField: "nom",
      text: `Nom`,
      formatter: nameFormatter,
      headerStyle: () => {
        return { width: "200px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "email",
      text: "Email",
      headerStyle: () => {
        return { width: "250px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "telephone",
      text: "Téléphone",
    },
    {
      dataField: "role",
      text: "Rôle",
    },
    {
      dataField: "id",
      text: "Action",
      formatter: actionFormatter,
    },
  ];
  const selectRow = {
    mode: "checkbox",
  };
  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results || []}
            columns={columns}
            // selectRow={selectRow}
            condensed
            hover
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvée" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage);
                setPage(page);
              }}
              perPage={perPage}
            />
          </div>
        </>
      )}
    </>
  );
}

export default MembresTable;
