import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { IUser, UserFormData } from "../../../../utils/api/user/user.type";

import { useAddOrEditAdminMutation, useDeleteUserMutation } from "../../../../utils/api/user/user.api";
import { Color } from "../../../../utils/theme";
import { cleannerError, validatePhone } from "../../../../utils/Utils";
import { Authorisations } from "../../../../utils/constante";

function UseCrudAdmin(user?: IUser) {
  const [code, setCode] = useState<string>("");
  const [phone, setPhone] = useState<string>();
  const validationSchema = yup.object().shape({
    prenom: yup.string().required("Le prenom est obligatoire"),
    nom: yup.string().required("Le nom est obligatoire"),
    telephone: validatePhone(yup, code),
    email: yup
      .string()
      .email("L'email est invalide")
      .required("L'email est obligatoire"),
      // user_type: yup.string().required().label("Le type d'utilisateur").nullable(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<UserFormData>({
    resolver: yupResolver(validationSchema),
  });

  const navigate = useNavigate();

  const [addOrUpdateUser, { isLoading, isSuccess, error, isError }] =
    useAddOrEditAdminMutation();

  const handleChangePhone = (item, country) => {
    setCode(country?.countryCode);
    setPhone(item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue(`telephone`, "+" + item);
    } else {
      setValue(`telephone`, "");
    }
  };
  const handleChangeAvatar = (e) => {
    let files = e.target.files[0];
    setValue("avatar", files);
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Admin ${user ? "modifié" : "ajouté"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        navigate("/admin/parametres");
      });
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);
      if (err?.data?.email?.includes("L'email a déjà été pris.")) {
        Swal.fire({
          icon: "error",
          title: "Cet email est déjà associé à un compte.",
          showConfirmButton: false,
          timer: 5000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Une erreur de statut ${err?.status} est survenue lors de l'inscription`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [error, isError, isSuccess, navigate]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  useEffect(() => {
    if (user?.id) {
      let fields: (keyof UserFormData)[] = [
        "nom",
        "prenom",
        "email",
        "telephone",
        "dashboard",
        "fideles",
        "demandes",
        "paroisses",
        "actualites",
        "cotisations",
        "formations",
        "messages",
        "parametres",
        "preambules",
        "contitution_bleues",
        "pasteurs",
        "affectations",
        "documentations",
        "carnet_roses",
        "finances",
        "témoignages",
        "necrologies",
        "paroisse_id",
        "responsable_paroissien",
        "responsable_diocesien",
        "objet_religieux",
        "collectes",
        "actualites",
        "ceremonies",
        "conseil_pastoral",
        "conseil_administration",
        "office_pastoral",
        "user_type"
      ];
      for (let field of fields) {
        register(field);
        setValue(field, user[field]);
      }
      if (user?.telephone) {
        setPhone(user?.telephone);
      }
    } else {
      Authorisations.forEach((item) => {
        // if(item?.field === 'dashboard') {
          setValue(item?.field, true);
        // } else {
        //   setValue(item?.field, false);
        // }
      });
    }
  }, [user]);

  const onSubmit = async (data: UserFormData) => {
    data["user_type"] = "admin"
    await addOrUpdateUser({ slug: user?.slug, data: data });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleChangeAvatar,
    phone,
    handleChangePhone,
  };
}

export default UseCrudAdmin;
export function UseDeleteAdmin(slug: string) {
  const [deleteData] = useDeleteUserMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cet administrateur ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: `L'administrateur a été supprimé avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}