/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, useState } from "react";
import Play from "../../../../assets/icons/play.svg";
import ReactPlayer from "react-player";
import AddVideosModal, { imgs, videos } from "../modals/AddVideosModal";
import { useGetDocumentationsQuery } from "../../../../utils/api/documentations/documentations.api";
import { IDocumentation } from "../../../../utils/api/documentations/documentations.type";
import { AlertInfo } from "../../../common/Alert";
import { getImage, get_url_extension } from "../../../../utils/Utils";
import { ApiBaseUrl } from "../../../../utils/http";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { UseDeleteBibliotheque } from "../requestForm/UseCrudBibliotheque";
import BibliothequeSkeleton from "../BibliothequeSkeleton";
import { CustomPagination } from "../../../common/CustomPagination";

const BibliothequeItem = ({
  item,
  type,
  setType,
  format,
  setFormat,
  isSecretaire
}: {
  item: IDocumentation;
  type: string;
  setType: Dispatch<SetStateAction<string>>;
  format: string;
  setFormat: Dispatch<SetStateAction<string>>;
  isSecretaire: boolean;
}) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <div className="content-testimonial-item w-100">
        <div className="d-flex align-items-center justify-content-between">
          <p className="name-user-archive mb-1">{item?.titre}</p>
          <div className="btn-group">
            {isSecretaire && <button
              className="btn btn-secondary btn-sm dropdown-toggle documentation-archive-custom-btn"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <BiDotsHorizontalRounded />
            </button>}
            <ul className="dropdown-menu">
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => {
                    setType("bibliotheque");
                    setShow(true);
                  }}
                >
                  Modifier
                </button>
              </li>
              <li>
                <DeleteBibliothequeBtn slug={item?.slug} />
              </li>
            </ul>
          </div>
        </div>
        {imgs?.includes(get_url_extension(item?.fichier)) && (
          <div className="content-testimonial-item">
            <img
              src={getImage(ApiBaseUrl + item?.fichier)}
              alt="Image"
              className="img-couv-tab"
            />
          </div>
        )}
        {videos?.includes(get_url_extension(item?.fichier)) && (
          <div>
            <ReactPlayer
              className="about-video"
              // light={Couverture1}
              controls
              url={ApiBaseUrl + item?.fichier}
              playing={false}
              playIcon={<img src={Play} alt="video" />}
              width="100%"
              height={320}
            />
          </div>
        )}
      </div>
      <AddVideosModal
        documentation={item}
        modalAddVideo={show}
        setModalAddVideo={setShow}
        typeDocumentations={type}
        setTypeDocumentations={setType}
        formatDocumentations={format}
        setFormatDocumentations={setFormat}
      />
    </>
  );
};

function BibliothequesTab({
  modalAddVideo,
  setModalAddVideo,
  typeDocumentations,
  setTypeDocumentations,
  formatDocumentations,
  setFormatDocumentations,
  is_Secretaire
}: {
  openModalAddArchives: any;
  modalAddArchives: any;
  setModalAddArchives: any;
  modalAddVideo: any;
  setModalAddVideo: any;
  typeDocumentations: any;
  setTypeDocumentations: any;
  formatDocumentations: any;
  setFormatDocumentations: any;
  is_Secretaire: boolean;
}) {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const { data, isLoading } = useGetDocumentationsQuery({
    q_type: "bibliotheque",
    page,
    limit: perPage,
  });
  return (
    <div className="content-testimonial-page">
      <div className="row">
        {!!isLoading && <BibliothequeSkeleton />}
        {!isLoading &&
          (!!data?.results && data?.results?.length > 0 ? (
            data?.results?.map((item) => (
              <div className="col-lg-3 col-md-4 mb-3 d-flex" key={item?.id}>
                <BibliothequeItem
                  item={item}
                  type={typeDocumentations}
                  setType={setTypeDocumentations}
                  format={formatDocumentations}
                  setFormat={setFormatDocumentations}
                  isSecretaire={is_Secretaire}
                />
              </div>
            ))
          ) : (
            <AlertInfo message="Aucune donnée trouvée" />
          ))}
      </div>
      {data?.results && (
        <div className="custom-pagination-container">
          <CustomPagination
            nbPages={data?.count}
            page={page}
            onChange={(page, perPage) => {
              setPerPage(perPage);
              setPage(page);
            }}
            perPage={perPage}
          />
        </div>
      )}
      <AddVideosModal
        modalAddVideo={modalAddVideo}
        setModalAddVideo={setModalAddVideo}
        typeDocumentations={typeDocumentations}
        setTypeDocumentations={setTypeDocumentations}
        formatDocumentations={formatDocumentations}
        setFormatDocumentations={setFormatDocumentations}
      />
    </div>
  );
}

export default BibliothequesTab;

function DeleteBibliothequeBtn({ slug }: { slug: string }) {
  const onDelete = UseDeleteBibliotheque(slug);

  return (
    <button className="dropdown-item" onClick={() => onDelete()}>
      Supprimer
    </button>
  );
}
