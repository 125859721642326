import React, { useEffect, useState } from "react";
import { Wrapper } from "../OfficePastoral/DetailOfficePastoral";
import { FormError, Input } from "../../common/Input";
import { getAvatar, useLocationState } from "../../../utils/Utils";
import { TiCamera } from "react-icons/ti";
import Swal from "sweetalert2";
import { Color } from "../../../utils/theme";
import BreadCrumb from "../../shared/BreadCrumb";
import { useAppSelector } from "../../../redux/hook";
import { isSuperAdmin } from "../../../routes/routerUtils";
import { useUserFromLocation } from "../../../utils/api/user/user.api";
import PhoneInput from "react-phone-input-2";
import GoogleInput from "../../common/GoogleInput";
import ShowCNIUserModal from "../../shared/ShowCNIUserModal";
import { useAddOrEditSecretaryMutation } from "../../../utils/api/secretary/secretary.api";
import UseCrudSecretaryForm from "./form/UseCrudSecretaryForm";
import AuthorizationCheckboxes from "../../common/AutorisationCheckbox";
import { SelectInput } from "../../common/SelectInput";
import { bureauOpt } from "../../../utils/constante";


function SecretaryProfile() {
  const [user] = useUserFromLocation();
  const {
    register,
    onSubmit,
    errors,
    isLoading,
    phone,
    handleChangePhone,
    address,
    onChangeAddress,
  } = UseCrudSecretaryForm(user);
  const [avatar, setAvatar] = useState<any>(null);

  const [sendData, { isSuccess: done, isError, error, data, isLoading: load }] =
    useAddOrEditSecretaryMutation();

  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));

  useEffect(() => {
    if (done) {
      Swal.fire({
        icon: "success",
        title: "Photo de profil modifiée avec succès !",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [done, isError]);

  const changeImage = (e: any) => {
    const file = e.target.files[0];
    setAvatar(URL.createObjectURL(file));
    let fd = new FormData();
    fd.append("avatar", file);
    sendData({ slug: user?.slug, data: fd });
  };
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row mb-4">
          <BreadCrumb pageName="Sécrétaire" secondPage="Profil" />
        </div>
      </div>
      <Wrapper>
        {user?.cni && (
          <div className="d-flex justify-content-end">
            <ShowCNIUserModal
              modalId={`cniModal${user?.slug}`}
              file={user?.cni}
            />
          </div>
        )}

        <div className="office-grid-cover-and-name">
          <div className="container-img-profil-user mb-0">
            <div className="d-flex justify-content-center align-items-center mb-0 leader-auto-user-avatar-container">
              <label htmlFor="input-test" className="input-testt">
                <img
                  src={avatar || getAvatar(user?.avatar)}
                  alt="Profil"
                  className="custom-img-profil-user-admin photo"
                />
                <div className="leader-auto-user-avatar-edit-hover-deux">
                  {load ? (
                    <span
                      className="spinner-border spinner-border-sm custom-spinner text-dark me-1 d-inline-block"
                      role="status"
                    ></span>
                  ) : (
                    <TiCamera className="edit-avatar-camera-icon" />
                  )}
                </div>
              </label>
              <input
                type="file"
                className="input-add-avatar"
                id="input-test"
                accept="image/*"
                onChange={(e) => changeImage(e)}
                disabled={!is_SuperAdmin || false}
              />
            </div>
          </div>
          <span className="name">{user?.prenom + " " + user?.nom}</span>
          {!!user?.type_secretaire && (
            <p className="py-2">
              <label>Sécrétaire {user?.type_secretaire}</label>
            </p>)}
        </div>
      </Wrapper>

      <form onSubmit={onSubmit}>
        <Wrapper customClass="mt-3">
          <div className="content-btn-theme-title custom-wrapper-title-container pb-5">
            <div className="custom-wrapper-title">
              Informations personnelles
            </div>
          </div>
          <div className="form-content">
            <div className="row row-infos-perso">
              <div className="col-md-6">
                <Input
                  type="text"
                  label="Nom"
                  id="nom"
                  placeholder="Nom"
                  {...register("nom")}
                  error={errors.nom}
                  readOnly={!is_SuperAdmin}
                />
              </div>
              <div className="col-md-6">
                <Input
                  type="text"
                  label="Prénom"
                  id="prenom"
                  placeholder="Prénom"
                  {...register("prenom")}
                  error={errors.prenom}
                  readOnly={!is_SuperAdmin}
                />
              </div>
              <div className="col-md-6">
                <Input
                  type="email"
                  label="Email"
                  id="email"
                  placeholder="Email"
                  {...register("email")}
                  error={errors.email}
                  readOnly={!is_SuperAdmin}
                />
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor={"telephone"}
                    className={`form-label form-label-modal-custom`}
                    aria-labelledby={"telephone"}
                  >
                    Téléphone
                  </label>

                  <PhoneInput
                    defaultMask={".. ... .. .."}
                    containerClass="phone-input-admin"
                    inputClass="form-control custom-padding"
                    country={"sn"}
                    placeholder=""
                    inputProps={{
                      name: "telephone",
                      required: true,
                      autoFocus: true,
                    }}
                    countryCodeEditable={false}
                    enableAreaCodes={true}
                    prefix="+"
                    value={phone}
                    onChange={(phone, country: any) => {
                      handleChangePhone(phone, country);
                    }}
                    inputStyle={{
                      width: "100%",
                      paddingBottom: "22px",
                      borderRadius: "10px",
                      paddingTop: "22px",
                      height: "54px",
                      backgroundColor: "#F2F6FA",
                      border: "none",
                    }}
                    dropdownStyle={{
                      backgroundColor: "#F2F6FA",
                    }}
                    enableLongNumbers={true}
                    disabled={!is_SuperAdmin}
                  />
                  <FormError error={errors?.telephone?.message} />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <Input
                  type="text"
                  label="Type de sécrétaire"
                  id="__secretary_type"
                  placeholder="Type de sécrétaire"
                  {...register("type_secretaire")}
                  error={errors.type_secretaire}
                  readOnly={!is_SuperAdmin}
                />
              </div>
              <div className="col-md-6 mb-3">
                <SelectInput
                  label="Bureau"
                  id="bureau"
                  required
                  {...register("bureau")}
                  customClassInput="form-control-modal-custom"
                  error={errors.bureau}
                  options={bureauOpt}
                  disabled={!is_SuperAdmin}
                />
              </div>
              <div className="col-md-6 mb-3">
                <div className="mb-3">
                  <label
                    htmlFor={"address"}
                    className={`form-label form-label-modal-custom`}
                    aria-labelledby={"address"}
                  >
                    Adresse
                  </label>

                  <GoogleInput
                    placeholder="Adresse"
                    className={`form-control form-control-modal-custom`}
                    value={address}
                    onChange={onChangeAddress}
                    isReadOnly={!is_SuperAdmin}
                    isDisable={!is_SuperAdmin}
                  />
                  <FormError error={errors?.adresse?.message} />
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
        {is_SuperAdmin && <Wrapper customClass="mt-3">
          <div className="custom-wrapper-title pb-5">Habilitations</div>
          <div className="container-checkbox-input">
            <div className="form-checkbox-input checkbox-input">
              <div className="custom-permission-container">
                <AuthorizationCheckboxes
                  register={register}
                  disabled={!is_SuperAdmin}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 offset-md-8 pt-5">
            {!!is_SuperAdmin && (
              <button
                className="btn btn-theme-admin w-100"
                disabled={isLoading}
                type="submit"
              >
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                      role="status"
                    ></span>
                    <span>{user ? "Modification..." : "Ajout..."}</span>
                  </>
                ) : user ? (
                  "Modifier"
                ) : (
                  "Ajouter"
                )}
              </button>
            )}
          </div>
        </Wrapper>}
      </form>
    </div>
  );
}

export default SecretaryProfile;
