import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl, useLocationState } from "../../Utils";
import { ObjetReligieux, ObjetReligieuxFormData } from "./objetReligieux.type";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const ObjetReligieuxApi = createApi({
  reducerPath: "objetReligieuxApi",
  tagTypes: ["objetReligieux", "objetReligieuxsList"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    addOrEditObjetReligieux: builder.mutation<
      ObjetReligieux,
      { slug?: string; data: ObjetReligieuxFormData | FormData }
    >({
      invalidatesTags: ["objetReligieux", "objetReligieuxsList"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `objets_religieux/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `objets_religieux/`,
          method: "POST",
          body: data,
        };
      },
    }),

    updateObjetPosition: builder.mutation<
    ObjetReligieux,
			{ slug?: string; data: ObjetReligieuxFormData | FormData }
		>({
			invalidatesTags: ["objetReligieux", "objetReligieuxsList"],
			query: ({ slug, data }) => {
				return {
					url: `objets_religieux/${slug}/`,
					method: "PUT",
					body: data,
				};
			},
		}),

    getListObjetReligieux: builder.query<
      PaginationResults<ObjetReligieux>,
      TypeQuery
    >({
      providesTags: ["objetReligieuxsList"],
      query: (query) => QueryUrl("objets_religieux/", query),
    }),
    getObjetReligieux: builder.query<ObjetReligieux, string>({
      providesTags: ["objetReligieux"],
      query: (slug) => `objets_religieux/${slug}/`,
    }),
    deleteObjetReligieux: builder.mutation<ObjetReligieuxFormData, string>({
      query: (slug) => ({
        url: `objets_religieux/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["objetReligieux", "objetReligieuxsList"],
    }),
  }),
});

export const {
  useAddOrEditObjetReligieuxMutation,
  useDeleteObjetReligieuxMutation,
  useGetListObjetReligieuxQuery,
  useLazyGetObjetReligieuxQuery,
  useUpdateObjetPositionMutation
} = ObjetReligieuxApi;

export function useObjetReligieuxFromLocation(): [
  ObjetReligieux,
  boolean,
  string,
  (id: string) => any
] {
  const itemState = useLocationState<ObjetReligieux | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findById, { data, isError, isLoading }] = useLazyGetObjetReligieuxQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findById(slug as string);
    }
  }, [slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      console.log("data", data);
      setItem(data);
    }
  }, [data]);

  return [item as ObjetReligieux, isLoading, slug as string, findById];
}