import React from 'react'
import "./OfficePastoral.css"
import OfficePastoralTable from "./OfficePastoralTable/OfficePastoralTable"
import { NavLink } from 'react-router-dom';
import { useAppSelector } from '../../../redux/hook';
import { isSuperAdmin } from '../../../routes/routerUtils';

function OfficePastoral() {
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <h2 className="chart-item-admin-title mb-4">
        Office pastoral
        </h2>
        <div className="content-graph-admin">
            {is_SuperAdmin && <div className='content-btn-theme-title'>
              <NavLink
              className="btn btn-theme-admin"
              to="/admin/office-pastoral/add-member"
              state={"office_pastoral"}
              >Nouveau membre</NavLink>
            </div>}
            <div className="admin-table mt-5">
              <OfficePastoralTable />
            </div>
        </div>
      </div>
    </div>
  )
}

export default OfficePastoral
