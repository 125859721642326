import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { IPays } from "../../../../utils/api/pays/pays.type";
import { useGetPaysQuery } from "../../../../utils/api/pays/pays.api";
import AddPaysModal from "../modals/AddPaysModal";
import { AlertInfo } from "../../../common/Alert";
import TableSkeleton from "../../../common/TableSkeleton";
import moment from "moment";
import { MdEdit } from "react-icons/md";
import { BsTrash3 } from "react-icons/bs";
import { UseDeletePays } from "../requestForm/UseCrudPays";
import { CustomPagination } from "../../../common/CustomPagination";

function PaysAdminTable({ modalAddPays, setModalAddPays, openModalAddPays }) {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [pays, setPays] = useState<IPays | undefined>();
  const { data, isLoading } = useGetPaysQuery({
    limit: perPage,
    page,
  });

  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          {/* <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/admin/sous-admins/${row?.slug}`}
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Voir"
            >
              <AiFillEye />
            </NavLink>
          </div> */}
          <div className="container-btn-action-icon-modal">
            <button
              className="btn btn-action-icon-edit with-tooltip"
              data-tooltip-content="Modifier"
              onClick={() => {
                setPays(row);
                openModalAddPays();
              }}
            >
              <MdEdit />
            </button>
          </div>
          <div className="container-btn-action-icon-modal">
            <DeletePaysBtn item={row} />
          </div>
        </div>
      </>
    );
  };
  const nameFormatter = (cell: any, row: any) => {
    return (
      <div className="d-flex gap-3 align-items-center">
        <p className="name-profil-grade-table mb-0">{cell}</p>
      </div>
    );
  };

  const dateAjoutFormatter = (cell: any, row: any) => {
    return <div className="">{!!row?.responsable ? row?.responsable?.prenom + " " + row?.responsable?.nom : null}</div>;
  };
  const columns = [
    {
      dataField: "nom",
      text: `Noms`,
      formatter: nameFormatter,
    },
    {
      dataField: "responsable",
      text: "Responsable",
      formatter: dateAjoutFormatter,
      headerStyle: () => {
        return { width: "350px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "id",
      text: "Action",
      formatter: actionFormatter,
      headerStyle: () => {
        return { width: "150px", whiteSpace: "normal" };
      },
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results || []}
            columns={columns}
            condensed
            hover
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvée" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage);
                setPage(page);
              }}
              perPage={perPage}
            />
          </div>
        </>
      )}
      <AddPaysModal
        modalAddPays={modalAddPays}
        setModalAddPays={setModalAddPays}
        pays={pays}
        setPays={setPays}
      />
    </>
  );
}

export default PaysAdminTable;

export function DeletePaysBtn({ item }: { item: IPays }) {
  const onDelete = UseDeletePays(item);

  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content="Supprimer"
      style={{ color: "#E53E3E" }}
      onClick={() => onDelete()}
    >
      <BsTrash3 />
    </button>
  );
}
