import React, { useState } from "react";
import "./TestimonialsAdmin.css";
import Profil from "../../../assets/appImages/avatar3.png";
import { MdAddCircleOutline } from "react-icons/md";
import AddTemoignageModal from "./modals/AddTemoignageModal";
import { useGetListTemoignageQuery } from "../../../utils/api/temoignage/temoignage.api";
import { UseDeleteTemoignage } from "./request/UseCrudTemoignage";
import { Temoignage } from "../../../utils/api/temoignage/temoignage.type";
import { AlertInfo } from "../../common/Alert";
import { ApiBaseUrl } from "../../../utils/http";
import moment from "moment";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { createMarkup, truncateString } from "../../../utils/Utils";
import { useAppSelector } from "../../../redux/hook";
import { isSuperAdmin } from "../../../routes/routerUtils";

const DeleteTemoignageBtn = ({ slug }: { slug: string }) => {
  const onDelete = UseDeleteTemoignage(slug);
  return (
    <button className="dropdown-item" onClick={() => onDelete()}>
      Supprimer
    </button>
  );
};

function TestimonialsAdmin() {
  const [temoignage, setTemoignage] = useState<Temoignage | undefined>();
  const [modalAddTemoignage, setModalAddTemoignage] = useState<boolean>(false);

  function openModalAddTemoignage() {
    setModalAddTemoignage(true);
  }
  const { data, isLoading } = useGetListTemoignageQuery({});
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <h2 className="chart-item-admin-title mb-4">Témoignages</h2>
        {is_SuperAdmin &&
          <>
            <div className="btn-action-container mb-2">
              <button
                className="btn btn-theme-admin"
                onClick={(e) => openModalAddTemoignage()}
              >
                <span className="pe-2">Ajouter un témoignage</span>
                <span>
                  <MdAddCircleOutline />
                </span>
              </button>
              <AddTemoignageModal
                modalAddTemoignage={modalAddTemoignage}
                setModalAddTemoignage={setModalAddTemoignage}
              />
            </div>
          </>
        }
        <div className="content-testimonial-page">
          <div className="row">
            {!isLoading ? (
              !!data?.results?.length ? (
                data?.results?.map((temoignage, key: number) => (
                  <div
                    className="col-lg-6 col-md-6 mb-3 content-card-text-info"
                    key={`${temoignage?.id}_${key}`}
                  >
                    <div className="content-testimonial-item">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="testimonial-header">
                          <div className="content-img-profil-testimonial">
                            <img
                              src={`${ApiBaseUrl}${temoignage?.image}`}
                              alt="photo"
                              className="img-profil-testimonial"
                            />
                          </div>
                          <div className="text-header-testimonial">
                            <p className="name-userètestimonial mb-1">
                              {temoignage?.user?.nom} {temoignage?.user?.prenom}
                            </p>
                            <p className="text-date-testimonial mb-0">
                              {moment(temoignage?.created_at).format(
                                "DD/MM/YYYY"
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="btn-group">
                          {is_SuperAdmin && <button
                            className="btn btn-secondary btn-sm dropdown-toggle documentation-archive-custom-btn"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <BiDotsHorizontalRounded />
                          </button>}
                          <ul className="dropdown-menu">
                            <li>
                              <button
                                className="dropdown-item"
                                onClick={() => {
                                  setTemoignage(temoignage);
                                  openModalAddTemoignage();
                                }}
                              >
                                Modifier
                              </button>
                            </li>
                            <li>
                              <DeleteTemoignageBtn slug={temoignage?.slug} />
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="content-body-testimonial pt-3">
                        <p
                          className="text-body-testimonial"
                          dangerouslySetInnerHTML={createMarkup(
                            truncateString(temoignage?.content, 300)
                          )}
                        />
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <AlertInfo message="Oups! Aucun témoignage trouvé" />
              )
            ) : (
              <AlertInfo message="Chargement des témoignages..." />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestimonialsAdmin;
