import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";
import { useForm, Controller } from "react-hook-form";
import { ReunionFormdata } from "../../../../utils/api/reunion/reunion.type";
import {
  useAddReunionMutation,
  useDeleteReunionMutation,
} from "../../../../utils/api/reunion/reunion.api";
import { useGetPaysQuery } from "../../../../utils/api/pays/pays.api";


function UseCrudReunion(
  setModalAddMeeting: any,
  membre_type?: any,
  reunion?: any
) {
  const validationSchema = yup.object().shape({
    date: yup.string().required().label("La date"),
    // heure_debut: yup.string().required().label("Heure de début"),
    // heure_fin: yup.string().required().label("Heure de fin").nullable(),
    pays: yup.string().required().label("Le pays").nullable(),
    titre: yup.string().required().label("Le titre").nullable(),
    heure_debut: yup
    .string()
    .required("L'heure de début est obligatoire")
    .test(
      "is-before-end-time",
      "L'heure de début ne doit pas être supérieure à l'heure de fin",
      function (value) {
        const { heure_fin } = this.parent;
        if (!value || !heure_fin) return true;
        return value <= heure_fin;
      }
    ),
    heure_fin: yup
    .string()
    .required("L'heure de fin est obligatoire")
    .test(
      "is-after-start-time",
      "L'heure de fin doit être après l'heure de début",
      function (value) {
        const { heure_debut } = this.parent;
        if (!value || !heure_debut) return true;
        return value >= heure_debut;
      }
    ),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
    setError,
    control,
  } = useForm<ReunionFormdata>({
    resolver: yupResolver(validationSchema),
  });

  const [pays, setPays] = useState<{ label: string; value: number }[]>([]);
  const [selectedPays, setSelectedPays] = useState<{ label: string; value: number }>();
  const { data = { results: [] } } = useGetPaysQuery({
    limit: 1000,
  });
  useEffect(() => {
    if (data?.results?.length) {
      setPays(
        data?.results?.map((el) => {
          return {
            label: el?.nom,
            value: el?.id,
          };
        })
      );
    }
  }, [data]);

  const onSelectPays = (e) => {
    setSelectedPays(e);
    setValue("pays", e?.value);
  };

  const [addOrUpdateReunion, { isLoading, isSuccess, error, isError }] =
    useAddReunionMutation();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: reunion
          ? "Réunion modifiée avec succès"
          : "Réunion ajoutée avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        reset();
        setModalAddMeeting(false);
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Error status ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = (data: any) => {
    data[membre_type] = true;

    let today = new Date();
    today.setHours(0, 0, 0, 0);

    if (data?.date) {
      let dataDate = new Date(data.date);
      dataDate.setHours(0, 0, 0, 0);

      if (dataDate < today && !reunion) {
        return setError("date", { message: "Veuillez entrer une date valide" });
      }
    }
    if (data?.heure_fin === "") {
      data["heure_fin"] = null;
    }
    console.log("data submitted", data);
    addOrUpdateReunion({ slug: reunion?.slug, data });
  };

  const handleReset = () => {
    if (!reunion) {
      reset();
    }
  };

  useEffect(() => {
    if (reunion?.id) {
      const fields: (keyof ReunionFormdata)[] = [
        "date",
        "heure_debut",
        "heure_fin",
        "pays",
        "titre"
      ];
      for (let field of fields) {
        if (field !== "heure_fin") {
          register(field);
          setValue(field, reunion[field]);
        } else if (field === "heure_fin" && reunion["heure_fin"] === null) {
          register(field);
        } else {
          register(field);
          setValue(field, reunion[field]);
        }

        if (field === "pays") {
          setSelectedPays({
            label: reunion.pays?.nom,
            value: reunion.pays?.id,
          });
        }
      }
    }
  }, [reunion]);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleReset,
    Controller,
    control,
    pays,
    onSelectPays,
    selectedPays,
  };
}

export default UseCrudReunion;

export function UseDeleteReunion(slug: string) {
  const [deleteData] = useDeleteReunionMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cette réunion ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: `Réunion supprimée avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}
