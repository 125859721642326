import React, { useState } from "react";
import { AiOutlineMore } from "react-icons/ai";
import { MdAddCircleOutline } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { useGetListActualiteQuery } from "../../../../utils/api/actualite/actualite.api";
import { AlertInfo } from "../../../common/Alert";
import { Actualite } from "../../../../utils/api/actualite/actualite.type";
import {
  createMarkup,
  formatDate,
  getImage,
  getName,
} from "../../../../utils/Utils";
import { CustomPagination } from "../../../common/CustomPagination";
import { UseDeleteActualite } from "../form/UseCrudActualiteForm";
import Skeleton from "react-loading-skeleton";
import { useAppSelector } from "../../../../redux/hook";
import { isResponsableParoissien } from "../../../../routes/routerUtils";

const BtnDelete = ({ slug }: { slug: string }) => {
  const onDelete = UseDeleteActualite(slug);

  return (
    <button className="dropdown-item" onClick={onDelete}>
      Supprimer
    </button>
  );
};
const ActualiteCard = ({ item }: { item: Actualite }) => {
  const is_Responsable = useAppSelector((s) =>
    isResponsableParoissien(s.user?.user)
  );
  return (
    <div className="actuality-card">
      <NavLink
        className="no-link w-100"
        to={`/admin/actualites/details/${item?.slug}`}
        state={item}
      >
        <span className="cover-wrapper">
          <img
            src={getImage(item?.image)}
            alt={item?.titre}
            className="actuality_cover"
          />
        </span>
      </NavLink>

      <div className="wrapper p-2">
        <NavLink
          className="no-link"
          to={`/admin/actualites/details/${item?.slug}`}
          state={item}
        >
          <span className="sm_title">{item?.titre}</span>
          <span
            className="sm_small_description"
            dangerouslySetInnerHTML={createMarkup(
              item?.content?.slice(0, 100) + "..."
            )}
          />
        </NavLink>

        <div className="actu_card__footer pt-3 d-flex align-items-center justify-content-between">
          <NavLink
            className="no-link"
            to={`/admin/actualites/details/${item?.slug}`}
            state={item}
          >
            <div className="small_text publication-date-and-author">
              <span className="publication-date author">
                {formatDate(item?.created_at)}&nbsp;·&nbsp;Par &nbsp;
                {getName(item?.user)}
              </span>
            </div>
          </NavLink>

          {is_Responsable && <div className="btn-group">
            <button
              className="btn btn-secondary btn-sm dropdown-toggle documentation-archive-custom-btn"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <AiOutlineMore style={{ fontSize: 18 }} />
            </button>
            <ul className="dropdown-menu">
              <li>
                <NavLink
                  role="button"
                  to={`/admin/actualites/modifier/${item?.slug}`}
                  className="dropdown-item"
                  state={item}
                >
                  Modifier
                </NavLink>
              </li>
              <li>
                <BtnDelete slug={item?.slug} />
              </li>
            </ul>
          </div>}
        </div>
      </div>
    </div>
  );
};

const ActiviteSkeleton = () => {
  return (
    <>
      {[...Array(12)]?.map((item, i) => (
        <div className="col-md-3 mb-3 custom-wrapper" key={i}>
          <div className="actuality-card w-100">
            <span className="cover-wrapper">
              <Skeleton
                className="actuality_cover"
                height={130}
                borderRadius={10}
              />
            </span>

            <div className="wrapper w-100">
              <Skeleton className="sm_title" height={15} />
              <Skeleton
                className="sm_small_description"
                
                height={60}
              />

              <div className="actu_card__footer pt-3 d-flex align-items-center justify-content-between">
                <div className="small_text publication-date-and-author">
                  <Skeleton className="publication-date author" width={150} height={15} />
                </div>

                <div className="btn-group">
                  <button
                    className="btn btn-secondary btn-sm dropdown-toggle documentation-archive-custom-btn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <AiOutlineMore style={{ fontSize: 18 }} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

function ActualiteStep() {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const { data, isLoading } = useGetListActualiteQuery({
    limit: perPage,
    page,
  });
  const is_Responsable = useAppSelector((s) =>
    isResponsableParoissien(s.user?.user)
  );
  return (
    <div>
      {is_Responsable && <div className="btn-action-container">
        <NavLink
          to={"/admin/actualites/ajouter"}
          className="btn custom-add-btn"
        >
          Nouvelle actualité
          <MdAddCircleOutline className="add-icon" />
        </NavLink>
      </div>}

      <div className="actualities-page-content">
        <div className="custom-content pt-4">
          <div className="row all-actualities-row">
            {!!isLoading && <ActiviteSkeleton />}
            {!isLoading &&
              (data?.results?.length ? (
                data?.results?.map((actu, key) => (
                  <div
                    className="col-md-3 mb-3 custom-wrapper"
                    key={`${key}_${actu.id}`}
                  >
                    <ActualiteCard item={actu} />
                  </div>
                ))
              ) : (
                <AlertInfo message="Aucune donnée trouvée" />
              ))}
          </div>

          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage);
                setPage(page);
              }}
              perPage={perPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActualiteStep;
