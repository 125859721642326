import { configureStore } from "@reduxjs/toolkit";
import { AuthApi } from "../utils/api/auth/auth.api";
import { UserApi } from "../utils/api/user/user.api";
import { currentEnv, Env } from "../utils/http";
import { UserSlice } from "./slice/User.slice";

import { PreambuleApi } from "../utils/api/preambule/preambule.api";
import { PasteurApi } from "../utils/api/pasteur/pasteur.api";
import { ConstitutionBleuApi } from "../utils/api/constitutionBleu/constitutionBleu.api";
import { CarnetRoseApi } from "../utils/api/carnetRoses/carnetRoses.api";
import { NecrologieApi } from "../utils/api/necrologie/necrologie.api";
import { DocumentationsApi } from "../utils/api/documentations/documentations.api";
import { GradesApi } from "../utils/api/grades/grades.api";
import ConditionApi from "../utils/api/contenu/condition.api";
import { FormationApi } from "../utils/api/formations/formation.api";
import { MembreApi } from "../utils/api/membre/membre.api";
import ReunionApi from "../utils/api/reunion/reunion.api";
import { TemoignageApi } from "../utils/api/temoignage/temoignage.api";
import { NotificationApi } from "../utils/api/notification/notification.api";
import { PaysApi } from "../utils/api/pays/pays.api";
import { ParoissesApi } from "../utils/api/paroisses/paroisses.api";
import { FideleApi } from "../utils/api/fideles/fidele.api";
import { ActivitesApi } from "../utils/api/activites/activites.api";
import { CotisationApi } from "../utils/api/cotisation/cotisation.api";
import { ForumApi } from "../utils/api/forum/forum.api";
import { ContactApi } from "../utils/api/contact/contact.api";
import { ThemeApi } from "../utils/api/theme/theme.api";
import { ActualiteApi } from "../utils/api/actualite/actualite.api";
import { CollecteApi } from "../utils/api/collecte/collecte.api";
import { CeremoniesApi } from "../utils/api/ceremonies/ceremonies.api";
import { ObjetReligieuxApi } from "../utils/api/objetReligieux/objetReligieux.api";
import { AffectationApi } from "../utils/api/affectation/affectation.api";
import { ModelesApi } from "../utils/api/modeles/modeles.api";
import { SecretaryApi } from "../utils/api/secretary/secretary.api";
import { DemandesApi } from "../utils/api/demande/demande.api";


const store = configureStore({
  reducer: {
    [AuthApi.reducerPath]: AuthApi.reducer,
    [UserSlice.name]: UserSlice.reducer,
    [UserApi.reducerPath]: UserApi.reducer,
    [PreambuleApi.reducerPath]: PreambuleApi.reducer,
    [PasteurApi.reducerPath]: PasteurApi.reducer,
    [ConstitutionBleuApi.reducerPath]: ConstitutionBleuApi.reducer,
    [CarnetRoseApi.reducerPath]: CarnetRoseApi.reducer,
    [NecrologieApi.reducerPath]: NecrologieApi.reducer,
    [DocumentationsApi.reducerPath]: DocumentationsApi.reducer,
    [GradesApi.reducerPath]: GradesApi.reducer,
    [ConditionApi.reducerPath]: ConditionApi.reducer,
    [FormationApi.reducerPath]: FormationApi.reducer,
    [MembreApi.reducerPath]: MembreApi.reducer,
    [ReunionApi.reducerPath]: ReunionApi.reducer,
    [TemoignageApi.reducerPath]: TemoignageApi.reducer,
    [NotificationApi.reducerPath]: NotificationApi.reducer,
    [PaysApi.reducerPath]: PaysApi.reducer,
    [ParoissesApi.reducerPath]: ParoissesApi.reducer,
    [FideleApi.reducerPath]: FideleApi.reducer,
    [ActivitesApi.reducerPath]: ActivitesApi.reducer,
    [CotisationApi.reducerPath]: CotisationApi.reducer,
    [ForumApi.reducerPath]: ForumApi.reducer,
    [ContactApi.reducerPath]: ContactApi.reducer,
    [ThemeApi.reducerPath]: ThemeApi.reducer,
    [ActualiteApi.reducerPath]: ActualiteApi.reducer,
    [CollecteApi.reducerPath]: CollecteApi.reducer,
    [CeremoniesApi.reducerPath]: CeremoniesApi.reducer,
    [ObjetReligieuxApi.reducerPath]: ObjetReligieuxApi.reducer,
    [AffectationApi.reducerPath]: AffectationApi.reducer,
    [ModelesApi.reducerPath]: ModelesApi.reducer,
    [SecretaryApi.reducerPath]: SecretaryApi.reducer,
    [DemandesApi.reducerPath]: DemandesApi.reducer,
  },
  devTools: Env === currentEnv,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    AuthApi.middleware,
    UserApi.middleware,
    PreambuleApi.middleware,
    PasteurApi.middleware,
    ConstitutionBleuApi.middleware,
    CarnetRoseApi.middleware,
    NecrologieApi.middleware,
    DocumentationsApi.middleware,
    GradesApi.middleware,
    ConditionApi.middleware,
    FormationApi.middleware,
    MembreApi.middleware,
    ReunionApi.middleware,
    TemoignageApi.middleware,
    NotificationApi.middleware,
    PaysApi.middleware,
    ParoissesApi.middleware,
    FideleApi.middleware,
    ActivitesApi.middleware,
    CotisationApi.middleware,
    ForumApi.middleware,
    ContactApi.middleware,
    ThemeApi.middleware,
    ActualiteApi.middleware,
    CollecteApi.middleware,
    CeremoniesApi.middleware,
    ObjetReligieuxApi.middleware,
    AffectationApi.middleware,
    ModelesApi.middleware,
    SecretaryApi.middleware,
    DemandesApi.middleware,
  ],
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export default store;
