import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { Authorisations, FieldType } from '../../utils/constante';

export const roleAuthorizations: Record<string, FieldType[]> = {
  conseil_pastoral: [
    'dashboard',
    'conseil_pastoral',
    'preambules',
    'contitution_bleues',
    'pasteurs',
    'necrologies',
    'carnet_roses',
    'documentations',
    'formations',
    'ceremonies',
    'objet_religieux',
    'témoignages',
    'messages',
    'demandes',
    'actualites',
  ],
  conseil_administration: [
    'dashboard',
    'conseil_administration',
    'preambules',
    'contitution_bleues',
    'pasteurs',
    'necrologies',
    'carnet_roses',
    'documentations',
    'formations',
    'ceremonies',
    'objet_religieux',
    'témoignages',
    'messages',
    'demandes',
    'actualites'
  ],
  office_pastoral: [
    'dashboard',
  ],
  responsable_paroissien: [
    'dashboard',
    'formations',
    'preambules',
    'contitution_bleues',
    'pasteurs',
    'responsable_paroissien'
  ],
};

interface AuthorizationCheckboxesProps {
  register: UseFormRegister<any>;
  userRole?: 'conseil_pastoral' | 'conseil_administration' | 'office_pastoral' | 'responsable_paroissien';
  field?: string;
  disabled?: boolean;
  additionalDisabledFields?: string[];
}


const AuthorizationCheckboxes: React.FC<AuthorizationCheckboxesProps> = ({ register, userRole, field,  disabled, additionalDisabledFields = []}) => {
    const isAuthorizedByRole = (itemField: string) => {
    if (userRole !== 'conseil_pastoral' && userRole !== 'conseil_administration' && userRole !== 'office_pastoral' && userRole !== 'responsable_paroissien') return false;
    return roleAuthorizations[userRole].includes(itemField as FieldType);
};

  return (
    <>
      {Authorisations?.map((item) => {
        const isDisabled = item.field === 'dashboard' || item.field === field || isAuthorizedByRole(item.field) || disabled || additionalDisabledFields.includes(item.field);
        const isCheckedByDefault = item.field === 'dashboard' || item.field === field || isAuthorizedByRole(item.field);

        return (
          <label key={item?.field} className={isDisabled ? "disabled" : ""}>
            <input
              type="checkbox"
              {...register(item?.field)}
              id={`checkbox-${item?.field}`}
              disabled={isDisabled}
              defaultChecked={isCheckedByDefault}
            />
            <span>{item?.label}</span>
          </label>
        );
      })}
    </>
  );
};

export default AuthorizationCheckboxes;