import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { Color } from "../../../../utils/theme";
import { cleannerError } from "../../../../utils/Utils";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hook";
import { Collecte } from "../../../../utils/api/cotisation/cotisation.type";
import { CollecteFormData } from "../../../../utils/api/collecte/collecte.type";
import {
  useAddOrEditCollecteMutation,
  useDeleteCollecteMutation,
} from "../../../../utils/api/collecte/collecte.api";
import { useGetPaysQuery } from "../../../../utils/api/pays/pays.api";
import { useParoisseByPaysQuery } from "../../../../utils/api/paroisses/paroisses.api";

function UseCrudCollecteForm(item?: Collecte) {
  const validationSchema = yup.object().shape({
    titre: yup.string().required().label("Titre"),
    description: yup
      .string()
      .required()
      .label("La description")
      .nullable()
      .transform((val: string) => (val === "<p><br></p>" ? "" : val)),
    image: yup.mixed().label("L'image").nullable(),
    montant: yup.string().required().label("L'objectif").nullable(),
    type_collecte: yup
      .string()
      .required()
      .label("Le type de collecte")
      .nullable(),
      paroisse: yup.string().required().label("La paroisse").nullable(),
      pays: yup.string().required().label("Pays").nullable(),
  });

  const [contenu, setContenu] = useState("");
  const [image, setImage] = useState<File | undefined>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    control,
  } = useForm<CollecteFormData>({
    resolver: yupResolver(validationSchema),
  });
  const { user } = useAppSelector((s) => s?.user);
  const navigate = useNavigate();

  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddOrEditCollecteMutation();

    const [paroisses, setParoisses] = useState<
    { label: string; value: number }[]
  >([]);
  const [selected, setSelected] = useState<{ label: string; value: number }>();
  const [options, setOptions] = useState<{ label: string; value: number }[]>(
    []
  );
  const [option, setOption] = useState<{ label: string; value: number }>();

    const [pays, setPays] = useState<
    { label: string; value: number; slug?: string }[]
  >([]);
  const [selectedPays, setSelectedPays] = useState<{
    label: string;
    value: number;
    slug?: string;
  }>();
  const { data: countries = { results: [] } } = useGetPaysQuery({
    limit: 1000,
  });
  const [showParoisseError, setShowParoisseError] = useState(false);
  const [query, setQuery] = useState("");
  const [search, setSearch] = useState("");
  const { data = { results: [] }, isLoading: isLoadParoisse } =
    useParoisseByPaysQuery({
      limit: 1000,
      q: query,
      slug: selectedPays?.slug,
    });

  useEffect(() => {
    if (image?.name) {
      setValue("image", image);
    }
  }, [image]);

  const handleChange = (val) => {
    setValue("description", val);
    setContenu(val);
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `La collecte a été ${
          item ? "modifiée" : "ajoutée"
        } avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then(() => {
        navigate("/admin/collectes");
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    console.log("err", errors);
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: CollecteFormData) => {
    if (!item) {
      data["user"] = user?.id;
    }
    let fd = new FormData();
    for (let key of Object.keys(data)) {
      if (key === "image") {
        if (data[key] && data[key] !== undefined) {
          fd.append(key, data[key] as any);
        }
      } else {
        fd.append(key, data[key]);
      }
    }

    await sendData({ slug: item?.slug, data: fd });
  };


  useEffect(() => {
    if (data?.results?.length > 0) {
      setParoisses(
        data?.results?.map((el) => {
          return {
            label: el?.nom,
            value: el?.id,
          };
        })
      );
    } else {
      setParoisses([]);
      setSelected(undefined);
    }

    if (countries?.results?.length) {
      setPays(
        countries?.results?.map((el) => {
          return {
            label: el?.nom,
            value: el?.id,
            slug: el?.slug,
          };
        })
      );
    }

  }, [data, countries]);


  const onSelectPays = (e) => {
    setSelectedPays(e);
    setValue("pays", e?.value);
  };

  const onSelectParoisse = (e) => {
    if (!selectedPays) {
      setShowParoisseError(true);
      return;
    }
    setShowParoisseError(false);
    setSelected(e);
    setValue("paroisse", e?.value);
  };

  const handleParoisseFocus = () => {
    if (!selectedPays) {
      setShowParoisseError(true);
    }
  };

  useEffect(() => {
    if (item?.id) {
      const fields: (keyof CollecteFormData)[] = [
        "titre",
        "description",
        "montant",
        "type_collecte",
        "pays",
        // "paroisses",
        "paroisse"
      ];

      for (let field of fields) {
        register(field);
        if (field !== "user" && field !== "paroisse" && field !== "pays") {
          if (item[field]) setValue(field, item[field]);
        } else {
          if (item[field]) {
            setValue(field, item[field]?.id);
          }
        }

        if (item?.paroisse) {
          setSelected({
            label: item.paroisse?.nom,
            value: item.paroisse?.id,
          });
        }
  
        if (item?.pays) {
          setSelectedPays({
            label: item.pays?.nom,
            value: item.pays?.id,
            slug: item.pays?.slug,
          });
        }
      }

      setContenu(item?.description);
    }
  }, [item]);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleChange,
    image,
    setImage,
    contenu,

    Controller,
    control,
    isLoadParoisse,
    pays,
    selectedPays,
    onSelectPays,
    setQuery,
    setSearch,
    isSuccess,
    paroisses,
    onSelectParoisse,
    selected,
    handleParoisseFocus,
    showParoisseError
  };
}

export default UseCrudCollecteForm;

export function UseDeleteCollecte(slug: string, redirectUrl?: string) {
  const [deleteData] = useDeleteCollecteMutation();
  const navigate = useNavigate();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cette collecte ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `La collecte a été supprimée avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            if (redirectUrl) {
              navigate(redirectUrl);
            }
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue!`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}
