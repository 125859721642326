import React from "react"
import MembresTable from "../MembresTable/MembresTable"

const MembresTabs = ({ query, pays, q_type, q_pays }: any) => {
  return (
    <div className="admin-table mt-4">
      <MembresTable query={query} pays={pays} q_type={q_type} q_pays={q_pays} />
    </div>
  );
};
export default MembresTabs