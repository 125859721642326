import { useState } from "react";
import TableSkeleton from "../../../common/TableSkeleton";
import BootstrapTable from "react-bootstrap-table-next";
import { AlertInfo } from "../../../common/Alert";
import { CustomPagination } from "../../../common/CustomPagination";
import { useAddOrEditDemandesMutation, useGetDemandesQuery } from "../../../../utils/api/demande/demande.api";
import moment from "moment";
import { FaCheckCircle } from "react-icons/fa";
import { IDemande } from "../../../../utils/api/demande/demande.type";
import Swal from "sweetalert2";
import { Color } from "../../../../utils/theme";
import { IoClose } from "react-icons/io5";
import { useAppSelector } from "../../../../redux/hook";
import { isSuperAdmin } from "../../../../routes/routerUtils";

function DemandesCertificatTable({ q }: { q: string }) {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const { data, isLoading } = useGetDemandesQuery({
    limit: perPage,
    page,
    q,
  });

  const actionFormatter = (cell: any, row: any) => {
    return (
      <DemandeCertificateActions demande={row} />
    );
  };

  const nameFormatter = (cell: any, row: any) => {
    return (
      <div className="content-cover-paroisse-and-name d-flex gap-3 align-items-center">
        <span className="custom-paroisse-name mb-0">{row?.prenom + " " + row?.nom}</span>
      </div>
    );
  };
  const dateFormatter = (cell: any, row: any) => {
    return (
      <div className="content-cover-paroisse-and-name d-flex gap-3 align-items-center">
        <span className="custom-paroisse-name mb-0">{moment(row?.created_at).format("ll")}</span>
      </div>
    );
  };

  const dateNaissanceFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            {moment(row?.date_naissance).format("ll")}
          </div>
        </div>
      </>
    );
  };
  const paysFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            {row?.user?.pays?.nom}
          </div>
        </div>
      </>
    );
  };
  const statusFormatter = (cell: any, row: any) => {
    return (
      <div className="table-actions-btn-container-commandes d-flex gap-3">
        <span className={`badge ${cell === true ? "bg-success" : "bg-danger"}`}>{cell === true ? "Validée" : "Rejetée"}</span>
      </div>
    );
  };
  const paroisseFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            {row?.user?.paroisse_id?.nom}
          </div>
        </div>
      </>
    );
  };

  const columns = [
    {
      dataField: "nom",
      text: "Noms",
      formatter: nameFormatter,
      headerStyle: () => {
        return { width: "200px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "date_naissance",
      text: "Date de naissance",
      formatter: dateNaissanceFormatter,
    },
    {
      dataField: "created_at",
      text: "Date de demande",
      formatter: dateFormatter,
    },
    {
      dataField: "validate",
      text: "Status",
      formatter: statusFormatter,
    },
    {
      dataField: "pays",
      text: "Pays",
      formatter: paysFormatter,
    },
    {
      dataField: "paroisse_id",
      text: "Paroisse",
      formatter: paroisseFormatter,
    },
    {
      dataField: "id",
      text: "Action",
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results || []}
            columns={columns}
            condensed
            hover
            wrapperClasses="table-responsive custom-eg-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvée" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage);
                setPage(page);
              }}
              perPage={perPage}
            />
          </div>
        </>
      )}
    </>
  );
}


export default DemandesCertificatTable;

const DemandeCertificateActions = ({ demande }: { demande: IDemande }) => {
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));
  const [isValidating, setIsValidating] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [addOrUpdateDemande] = useAddOrEditDemandesMutation();

  const handleValidate = async () => {
    await Swal.fire({
      title: "Êtes-vous sûr de vouloir valider cette demande ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: async () => {
        setIsValidating(true);
        try {
          await addOrUpdateDemande({ slug: demande?.slug, data: { validate: true } });
          return true;
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Une erreur est survenue lors de la validation.",
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
          return false;
        } finally {
          setIsValidating(false);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: "Demande validée avec succès!",
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };

  const handleReject = async () => {
    await Swal.fire({
      title: "Êtes-vous sûr de vouloir rejeter cette demande ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: async () => {
        setIsRejecting(true);
        try {
          await addOrUpdateDemande({ slug: demande?.slug, data: { validate: false } });
          return true;
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Une erreur est survenue lors du rejet.",
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
          return false;
        } finally {
          setIsRejecting(false);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: "Demande rejetée avec succès!",
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };

  return (
    (!demande?.validate && is_SuperAdmin ) ?
      <div className="table-actions-btn-container-commandes d-flex gap-3">
        <div className="container-btn-action-icon-modal">
          <button
            className="btn btn-action-modal-icon with-tooltip"
            data-tooltip-content="Valider la demande"
            onClick={handleValidate}
            disabled={isValidating}
          >
            <FaCheckCircle />
          </button>
        </div>
        {/* <div className="container-btn-action-icon-modal">
          <button
            className="btn btn-action-icon-delete with-tooltip"
            data-tooltip-content="Refuser la demande"
            onClick={handleReject}
            disabled={isRejecting}
          >
            <IoClose />
          </button>
        </div> */}
      </div> : null
  );
};

