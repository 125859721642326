import React from "react";
import BreadCrumb from "../../shared/BreadCrumb";
import { animatedComponents, FormError, Input } from "../../common/Input";
import Select from "react-select";
import MyDropzone, { ImageItem } from "../../common/MyDropzone";
import ReactQuill from "react-quill";
import { useCollecteFromLocation } from "../../../utils/api/collecte/collecte.api";
import UseCrudCollecteForm from "./form/UseCrudCollecteForm";
import { SelectInput } from "../../common/SelectInput";
import { useNavigate } from "react-router-dom";

function AddOrUpdateCollecte() {
  const [item] = useCollecteFromLocation();
  const {
    register,
    errors,
    onSubmit,
    isLoading,
    contenu,
    handleChange,
    image,
    setImage,

    Controller,
    control,
    isLoadParoisse,
    pays,
    selectedPays,
    onSelectPays,
    setQuery,
    setSearch,
    isSuccess,
    paroisses,
    onSelectParoisse,
    selected,
    handleParoisseFocus,
    showParoisseError
  } = UseCrudCollecteForm(item);
  const navigate = useNavigate();

  return (
    <>
      <div className="dashbord-admin-component">
        <div className="dash-admin-page-content-container mb-3">
          <BreadCrumb
            pageName="Collectes"
            secondPage={!item ? "Nouvelle collecte" : "Modifier une collecte"}
          />
        </div>
        <div className="btn-action-container">
          <span className="egs__text">
            {!item ? "Ajouter" : "Modifier"} une collecte
          </span>
        </div>
        <div className="form-content mt-2 bg-white p-3 pb-5">
          <form onSubmit={onSubmit}>
            <div className="row">
              <div className="col-md-6 mb-3">
                <SelectInput
                  label="Type de collecte"
                  {...register("type_collecte")}
                  required
                  error={errors?.type_collecte}
                  options={[
                    {
                      label: "Dernier culte",
                      value: "dernier_culte",
                    },
                    {
                      label: "Dime",
                      value: "dime",
                    },
                    {
                      label: "Dons",
                      value: "dons",
                    },
                    {
                      label: "Quête",
                      value: "quete",
                    },
                  ]}
                />
              </div>
              <div className="col-md-6 mb-3">
                <Input
                  label="Titre"
                  type="text"
                  placeholder="Titre"
                  {...register("titre")}
                  error={errors?.titre}
                  required
                />
              </div>
              <div className="col-md-6">
                <div className="mb-4">
                  <label
                    htmlFor={"pays"}
                    className={`form-label form-label-modal-custom`}
                    aria-labelledby={"pays"}
                  >
                    Pays{" "}
                    <span className="text-danger" style={{ fontSize: 15 }}>
                      *
                    </span>
                  </label>

                  <Controller
                    name="pays"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          // isMulti
                          options={pays}
                          classNamePrefix="react-select-custom"
                          onChange={onSelectPays}
                          value={selectedPays}
                        />
                      );
                    }}
                  />
                  <FormError error={errors.pays} />
                </div>
              </div>
              <div className="col-md-6 d-flex flex-column gap-1 mb-3">
                <label
                  htmlFor=""
                  className="form-label form-label-modal-custom"
                >
                  Paroisse
                  <span className="text-danger" style={{ fontSize: 15 }}>
                    *
                  </span>
                </label>
                <Controller
                  name="paroisse"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        options={paroisses}
                        value={selected ? selected : null}
                        onInputChange={(val) => setQuery(val)}
                        onChange={(val) => onSelectParoisse(val)}
                        onFocus={handleParoisseFocus}
                        className="custom-select-items"
                        isLoading={isLoadParoisse}
                        // isDisabled={isLoadParoisse}
                      />
                    );
                  }}
                />
                {showParoisseError && (
                  <div className="text-danger mt-1">
                    Veuillez d'abord sélectionner un pays.
                  </div>
                )}
                <FormError error={errors.paroisse} />
              </div>
              <div className="col-md-12">
                <Input
                  label="Quel est votre objectif de collecte?"
                  type="number"
                  step={"any"}
                  min={0}
                  placeholder="Montant en (EURO)"
                  {...register("montant")}
                  error={errors?.montant}
                  required
                />
              </div>
              <div className="col-md-12 mb-3">
                <label className="form-label form-label-modal-custom">
                  Description
                  <span className="text-danger" style={{ fontSize: 15 }}>
                    *
                  </span>
                </label>
                <ReactQuill
                  className="editor-cgu"
                  theme="snow"
                  value={contenu}
                  onChange={handleChange}
                />
                <FormError error={errors?.description} />
              </div>
              <div className="col-md-12 mb-3">
                <label className="form-label form-label-modal-custom">
                  Bannière
                </label>
                <MyDropzone
                  setImages={setImage}
                  acceptFile={{
                    "image/jpeg": [],
                    "image/png": [],
                    "image/jpg": [],
                    "image/gif": [],
                  }}
                  maxFile={1}
                />
                {image || item?.image ? (
                  <ImageItem image={image || item?.image} />
                ) : null}
                <FormError error={errors?.image} />
              </div>
            </div>
            <div className="col-md-4 offset-md-8 auth-submit-btn-container text-end pt-3">
              <button
                disabled={isLoading}
                className="btn btn-theme-cancel w-100"
                type="button"
                onClick={() => navigate(-1)}
              >
                Retour
              </button>
              <button
                disabled={isLoading}
                className="btn btn-theme-admin w-100"
                type="submit"
              >
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm me-1 d-inline-block"
                      role="status"
                    ></span>
                    <span>{item ? "Modification..." : "Ajout..."}</span>
                  </>
                ) : item ? (
                  "Modifier"
                ) : (
                  "Ajouter"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddOrUpdateCollecte;
