import React, { useState } from "react";
import BreadCrumb from "../../shared/BreadCrumb";
import { FormError, Input } from "../../common/Input";
import UseCrudPasteur from "./requestForm/UseCrudPasteur";
import PhoneInput from "react-phone-input-2";
import GoogleInput from "../../common/GoogleInput";
import { useUserFromLocation } from "../../../utils/api/user/user.api";
import startsWith from "lodash.startswith";
import ReactQuill from "react-quill";

function AddOrUpdatePasteurAdmin() {
  // const pasteur = useLocationState<IPasteur>(undefined);
  const [item] = useUserFromLocation();
  const {
    register,
    onSubmit,
    errors,
    isLoading,
    isLoadActual,
    phone,
    handleChangePhone,
    address,
    onChangeAddress,
    bio,
    handleChangeBio,
  } = UseCrudPasteur(item);
  const [show, setShow] = useState(false);

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row mb-4">
          <BreadCrumb pageName="Pasteurs" secondPage="Nouveau pasteur" />
        </div>
        <div className="content-graph-admin medsain-page-title-container mb-2">
          <h2 className="chart-item-admin-title mb-4">Nouveau pasteur</h2>
          <div className="form-add-container">
            <form className="mt-4" onSubmit={onSubmit}>
              <div className="row row-add-student my-3">
                <div className="col-md-6 mb-3">
                  <Input
                    type="text"
                    label="Nom"
                    id="nom"
                    placeholder="Nom"
                    {...register("nom")}
                    error={errors.nom}
                    required
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <Input
                    type="text"
                    label="Prénom"
                    id="prenom"
                    placeholder="Prénom"
                    {...register("prenom")}
                    error={errors.prenom}
                    required
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <Input
                    type="email"
                    label="Email"
                    id="email"
                    placeholder="Email"
                    {...register("email")}
                    error={errors.email}
                    required
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <div className="mb-3">
                    <label
                      htmlFor={"telephone"}
                      className={`form-label form-label-modal-custom`}
                      aria-labelledby={"telephone"}
                    >
                      Téléphone
                    </label>

                    <PhoneInput
                      defaultMask={".. ... .. .."}
                      containerClass="phone-input-admin"
                      inputClass="form-control custom-padding"
                      country={"sn"}
                      placeholder=""
                      inputProps={{
                        name: "telephone",
                        required: true,
                        autoFocus: true,
                      }}
                      countryCodeEditable={false}
                      enableAreaCodes={true}
                      prefix="+"
                      value={phone}
                      onChange={(phone, country: any) => {
                        handleChangePhone(phone, country);
                      }}
                      inputStyle={{
                        width: "100%",
                        paddingBottom: "22px",
                        borderRadius: "10px",
                        paddingTop: "22px",
                        height: "54px",
                        backgroundColor: "#F2F6FA",
                        border: "none",
                      }}
                      dropdownStyle={{
                        backgroundColor: "#F2F6FA",
                      }}
                      enableLongNumbers={true}
                    />
                    <FormError error={errors?.telephone?.message} />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <Input
                    type="text"
                    label="Boite postale"
                    id="boite_postale"
                    placeholder="Boite postale"
                    {...register("boite_postale")}
                    error={errors.boite_postale}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <div className="mb-3">
                    <label
                      htmlFor={"address"}
                      className={`form-label form-label-modal-custom`}
                      aria-labelledby={"address"}
                    >
                      Adresse{" "}
                      <span className="text-danger" style={{ fontSize: 15 }}>
                        *
                      </span>
                    </label>

                    <GoogleInput
                      placeholder="Adresse"
                      className={`form-control form-control-modal-custom`}
                      value={address}
                      onChange={onChangeAddress}
                    />
                    <FormError error={errors?.adresse?.message} />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <Input
                    type="date"
                    label="Date de debut"
                    id="date"
                    placeholder="Date de debut"
                    {...register("date_debut_pasteur")}
                    error={errors.date_debut_pasteur}
                    required
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <div>
                    <label
                      htmlFor="date"
                      className="form-label form-label-modal-custom"
                    >
                      À aujourd’hui
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="date"
                      value="true"
                      aria-label="..."
                      {...register("toujours")}
                      onChange={() => setShow(!show)}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  {!show && (
                    <Input
                      type="date"
                      label="Date de fin"
                      id="date"
                      placeholder="Date de fin"
                      {...register("date_fin_pasteur")}
                      error={errors.date_fin_pasteur}
                    />
                  )}
                </div>
                <div className="col-md-12 mb-3">
                  <label
                    htmlFor={"bio"}
                    className={`form-label form-label-modal-custom`}
                    aria-labelledby={"bio"}
                  >
                    Historique (Bio)
                  </label>
                  <ReactQuill
                    className="editor-cgu"
                    theme="snow"
                    value={bio}
                    onChange={handleChangeBio}
                  />
                </div>

                <div className="col-md-3 offset-md-9 auth-submit-btn-container text-end pt-5">
                  <button
                    className="btn btn-theme-admin w-100"
                    disabled={isLoading || isLoadActual}
                    type="submit"
                  >
                    {isLoading || isLoadActual ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                          role="status"
                        ></span>
                        <span>{item ? "Modification..." : "Ajout..."}</span>
                      </>
                    ) : item ? (
                      "Modifier"
                    ) : (
                      "Ajouter"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddOrUpdatePasteurAdmin;
