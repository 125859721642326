import React from "react";
import BreadCrumb from "../../shared/BreadCrumb";
import { Wrapper } from "../OfficePastoral/AddMembreOfficePastoral";
import { CustomOption, FormError, Input, OptionType, animatedComponents } from "../../common/Input";
import PhoneInput from "react-phone-input-2";
import ErrorMessage from "../../common/ErrorMessage";
import { Authorisations } from "../../../utils/constante";
import { useLocationState } from "../../../utils/Utils";
import UseCrudResponsableParoissien from "./UseCrudResponsableParoissien";
import Select from "react-select";
import GoogleInput from "../../common/GoogleInput";
import { SelectInput } from "../../common/SelectInput";
import AuthorizationCheckboxes from "../../common/AutorisationCheckbox";

function AddMembreResponsableParoissien() {
  const field = useLocationState<string>(undefined);
  const userRole = useLocationState<any>(undefined);
  const {
    register,
    onSubmit,
    errors,
    isLoading,
    handelAvatarChange,
    previewAvatar,
    phone,
    handleChangePhone,
    Controller,
    control,
    option,
    options,
    address,
    onChangeAddress,
    setSearch,
    paroisses,
    onSelectParoisse,
    selected,
    setQuery,
    onSelectGrade,
    pays,
    selectedPays,
    onSelectPays,
    isLoadParoisse,
    optionsModeles,
    optionModele,
    onSelectModele,
    isLoadCertificat,
    handleParoisseFocus,
    showParoisseError
  } = UseCrudResponsableParoissien(field);
  return (
    <>
      <div className="dash-admin-page-content-container mb-3">
        <div className="row mb-4">
          <BreadCrumb
            pageName="Responsable paroissial"
            secondPage="Ajouter un membre"
          />
        </div>
      </div>
      <form onSubmit={onSubmit}>
        <Wrapper customClass="mt-3">
          <div className="content-btn-theme-title custom-wrapper-title-container pb-5">
            <div className="custom-wrapper-title">
              Informations personnelles
            </div>
          </div>
          <div className="form-content">
            <div className="row row-infos-perso">
              <div className="col-md-6">
                <Input
                  label="Nom"
                  type="text"
                  placeholder="Nom"
                  {...register("nom")}
                  error={errors.nom}
                  required
                />
              </div>
              <div className="col-md-6">
                <Input
                  label="Prénom"
                  type="text"
                  placeholder="Prénom"
                  {...register("prenom")}
                  error={errors.prenom}
                  required
                />
              </div>
              <div className="col-md-6">
                <Input
                  label="Email"
                  type="email"
                  placeholder="Email"
                  {...register("email")}
                  error={errors.email}
                  required
                />
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor={"telephone"}
                    className={`form-label form-label-modal-custom`}
                    aria-labelledby={"telephone"}
                  >
                    Téléphone
                  </label>

                  <PhoneInput
                    defaultMask={".. ... .. .."}
                    containerClass="phone-input-admin"
                    inputClass="form-control custom-padding"
                    country={"sn"}
                    placeholder=""
                    inputProps={{
                      name: "telephone",
                      required: true,
                      autoFocus: true,
                    }}
                    countryCodeEditable={false}
                    enableAreaCodes={true}
                    prefix="+"
                    value={phone}
                    onChange={(phone, country: any) => {
                      handleChangePhone(phone, country);
                    }}
                    inputStyle={{
                      width: "100%",
                      paddingBottom: "22px",
                      borderRadius: "10px",
                      paddingTop: "22px",
                      height: "54px",
                      backgroundColor: "#F2F6FA",
                      border: "none",
                    }}
                    dropdownStyle={{
                      backgroundColor: "#F2F6FA",
                    }}
                    enableLongNumbers={true}
                  />
                  <FormError error={errors?.telephone?.message} />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <Input
                  type="text"
                  label="Rôle"
                  id="role"
                  placeholder="Rôle"
                  {...register("role")}
                  error={errors.role}
                />
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor={"address"}
                    className={`form-label form-label-modal-custom`}
                    aria-labelledby={"address"}
                  >
                    Adresse
                  </label>
                  <GoogleInput
                    className={`form-control form-control-modal-custom`}
                    placeholder="Adresse"
                    value={address}
                    onChange={onChangeAddress}
                  />
                  <FormError error={errors.adresse} />
                </div>
              </div>

              <div className="col-md-6">
                <Input
                  label="Nationalité"
                  type="text"
                  placeholder="Nationalité"
                  {...register("nationalite")}
                  error={errors.nationalite}
                />
              </div>

              <div className="col-md-6">
                <div className="mb-4">
                  <label
                    htmlFor={"pays"}
                    className={`form-label form-label-modal-custom`}
                    aria-labelledby={"pays"}
                  >
                    Pays{" "}
                    <span className="text-danger" style={{ fontSize: 15 }}>
                      *
                    </span>
                  </label>

                  <Controller
                    name="pays"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          // isMulti
                          options={pays}
                          classNamePrefix="react-select-custom"
                          onChange={onSelectPays}
                          value={selectedPays}
                        />
                      );
                    }}
                  />
                  <FormError error={errors.pays} />
                </div>
              </div>
              <div className="col-md-6 d-flex flex-column gap-1 mb-3">
                <label
                  htmlFor=""
                  className="form-label form-label-modal-custom"
                >
                  Paroisse
                  <span className="text-danger" style={{ fontSize: 15 }}>
                    *
                  </span>
                </label>
                <Controller
                  name="paroisse_id"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        options={paroisses}
                        value={selected ? selected : null}
                        onInputChange={(val) => setQuery(val)}
                        onChange={(val) => onSelectParoisse(val)}
                        onFocus={handleParoisseFocus}
                        className="custom-select-items"
                        isLoading={isLoadParoisse}
                        // isDisabled={isLoadParoisse}
                      />
                    );
                  }}
                />
                {showParoisseError && (
                  <div className="text-danger mt-1">
                    Veuillez d'abord sélectionner un pays.
                  </div>
                )}
                <FormError error={errors.paroisse_id} />
              </div>
              <div className="col-md-6 d-flex flex-column gap-1 mb-3">
                <label
                  htmlFor=""
                  className="form-label form-label-modal-custom"
                >
                  Grade{" "}
                  <span className="text-danger" style={{ fontSize: 15 }}>
                    *
                  </span>
                </label>
                <Controller
                  name="grade"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        options={options}
                        value={option}
                        onInputChange={(val) => setSearch(val)}
                        onChange={(val) => onSelectGrade(val)}
                        className="custom-select-items"
                      />
                    );
                  }}
                />

                <FormError error={errors.grade} />
              </div>
              <div className="col-md-6">
                <Input
                  label="Date du dernier grade"
                  type="date"
                  placeholder="Date du dernier grade"
                  {...register("date_dernier_grade")}
                  error={errors.date_dernier_grade}
                />
              </div>
              <div className="col-md-6 mb-3">
                <SelectInput
                  label="Onction"
                  id="onction"
                  required
                  {...register("onction")}
                  customClassInput="form-control-modal-custom"
                  error={errors.onction}
                  options={[
                    { label: "Prophete", value: "prophete" },
                    { label: "Clerge", value: "clerge" },
                    { label: "Honorable", value: "honorable" },
                    { label: "Membre", value: "membre" },
                  ]}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label
                  htmlFor=""
                  className="form-label form-label-modal-custom"
                >
                  Modele de certificat{" "}
                </label>
                <Controller
                  name="modele"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                      {...field}
                      closeMenuOnSelect={true}
                      components={{
                        ...animatedComponents,
                        Option: CustomOption as any
                      }}
                      options={optionsModeles as OptionType[]}
                      value={optionModele as OptionType}
                      onInputChange={(val) => setSearch(val)}
                      onChange={(val) => onSelectModele(val)}
                      className="custom-select-items"
                      isLoading={isLoadCertificat}
                      // isDisabled={isLoadCertificat}
                    />
                    );
                  }}
                />

                <FormError error={errors.modele} />
              </div>

              <div className="col-md-6 mb-3">
                <div>
                  <Input
                    type="file"
                    label="Ajouter une photo"
                    id="avatar"
                    placeholder="Ajouter une photo"
                    accept="image/*"
                    onChange={(e) => handelAvatarChange(e)}
                    name="avatar"
                  />
                  {errors?.avatar && (
                    <ErrorMessage message={errors?.avatar?.message} />
                  )}
                  {previewAvatar && (
                    <div className="img-preview-container mb-3">
                      <img src={previewAvatar} className="type-img-preview" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Wrapper>

        <Wrapper customClass="mt-3">
          <div className="custom-wrapper-title pb-5">Habilitations</div>
          <div className="container-checkbox-input">
            <div className="form-checkbox-input checkbox-input">
              <div className="custom-permission-container">
                <AuthorizationCheckboxes
                  register={register} 
                  userRole={userRole}
                  field={field}
                  additionalDisabledFields={['conseil_pastoral', 'conseil_administration', 'affectations']}
                />
                {/* {Authorisations?.map((item) => (
                  <label key={item?.field} className={item?.field === `${field}` || item?.field === "dashboard" ? "disabled" : ""}>
                    <input
                      type="checkbox"
                      {...register(item?.field)}
                      id={`checkbox-${item?.field}`}
                      disabled={
                        item?.field === `${field}` ||
                        item?.field === "dashboard"
                      }
                    />
                    <span>{item?.label}</span>
                  </label>
                ))} */}
              </div>
            </div>
          </div>

          <div className="col-md-4 offset-md-8 pt-5">
            <button
              className="btn btn-theme-admin w-100"
              disabled={isLoading}
              type="submit"
            >
              {isLoading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                    role="status"
                  ></span>
                  <span>{"Ajout..."}</span>
                </>
              ) : (
                "Ajouter"
              )}
            </button>
          </div>
        </Wrapper>
      </form>
    </>
  );
}

export default AddMembreResponsableParoissien;
