import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { IPasteur, PasteurFormData } from "./pasteur.type";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl } from "../../Utils";

export const PasteurApi = createApi({
  reducerPath: "pasteurApi",
  tagTypes: ["pasteur", "pasteurList"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    addOrEditPasteur: builder.mutation<
      IPasteur,
      { slug?: string; data: PasteurFormData | FormData }
    >({
      invalidatesTags: ["pasteur", "pasteurList"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `users/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `pasteur/create/`,
          method: "POST",
          body: data,
        };
      },
    }),
    addOrEditActualPasteur: builder.mutation<
      IPasteur,
      { slug?: string; data: PasteurFormData | FormData }
    >({
      invalidatesTags: ["pasteur", "pasteurList"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `users/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `actuel_pasteur/create/`,
          method: "POST",
          body: data,
        };
      },
    }),

    getPasteurs: builder.query<PaginationResults<IPasteur>, TypeQuery>({
      providesTags: ["pasteurList"],
      query: (query) => QueryUrl("pasteurs/", query),
    }),

    deletePasteur: builder.mutation<PasteurFormData, string>({
      query: (slug) => ({
        url: `users/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["pasteur", "pasteurList"],
    }),
  }),
});

export const {
  useAddOrEditPasteurMutation,
  useAddOrEditActualPasteurMutation,
  useDeletePasteurMutation,
  useGetPasteursQuery,
} = PasteurApi;
