import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { 
    ISecretary, 
    SecretaryFormData 
} from "./secretary.type";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl } from "../../Utils";


export const SecretaryApi = createApi({
  reducerPath: "secretaryApi",
  tagTypes: ["secretary", "secretaryList"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    addOrEditSecretary: builder.mutation<
      ISecretary,
      { slug?: string; data: SecretaryFormData | FormData }
    >({
      invalidatesTags: ["secretary", "secretaryList"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `users/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `secretaire/create/`,
          method: "POST",
          body: data,
        };
      },
    }),

    getSecretaries: builder.query<PaginationResults<ISecretary>, TypeQuery>({
      providesTags: ["secretary", "secretaryList"],
      query: (query) => QueryUrl("secretaires/", query),
    }),

    deleteSecretary: builder.mutation<SecretaryFormData, string>({
      query: (slug) => ({
        url: `users/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["secretary", "secretaryList"],
    }),

  }),
});

export const {
  useAddOrEditSecretaryMutation,
  useGetSecretariesQuery,
  useDeleteSecretaryMutation,
} = SecretaryApi;
