import React, { useState } from "react";
import "./NecrologieAdmin.css";
import NecrologieAdminTable from "./NecrologieAdminTable/NecrologieAdminTable";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { BiSearchAlt2 } from "react-icons/bi";
import { BsFillFilterSquareFill } from "react-icons/bs";
import AddNecrologieModal from "./modals/AddNecrologieModal";
import { useAppSelector } from "../../../redux/hook";
import { isSuperAdmin } from "../../../routes/routerUtils";

function NecrologieAdmin() {
  const [query, setQuery] = useState("");
  const [modalAddNecrologie, setModalAddNecrologie] = useState(false);

  function openModalAddNecrologie() {
    setModalAddNecrologie(true);
  }
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <h2 className="chart-item-admin-title mb-4">Nécrologies</h2>
        <div className="content-graph-admin">
          {is_SuperAdmin && <div className="content-btn-theme-title mb-3">
            <button
              onClick={(e) => openModalAddNecrologie()}
              className="btn btn-theme-admin"
            >
              <span>Ajouter un décès</span>
              <span className="ps-2">
                <MdOutlineAddCircleOutline />
              </span>
            </button>
            <AddNecrologieModal
              modalAddNecrologie={modalAddNecrologie}
              setModalAddNecrologie={setModalAddNecrologie}
            />
          </div>}
          <div className="flex-r">
            <div className="content-form-filter mt-4 mb-5 d-flex align-items-center gap-2">
              <form>
                <div className="content-form-search-bloc position-relative">
                  <input
                    type="search"
                    className="form-control form-control-search"
                    placeholder="Tapez pour rechercher"
                    onChange={(e) => setQuery(e?.target?.value)}
                  />
                  <span className="container-icon-searh-form">
                    <BiSearchAlt2 />
                  </span>
                </div>
              </form>
              {/* <button className="btn btn-icon-filter">
                <BsFillFilterSquareFill />
              </button> */}
            </div>
          </div>
          <div className="admin-table mt-5">
            <NecrologieAdminTable q={query} isSuperAdmin={is_SuperAdmin} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NecrologieAdmin;
