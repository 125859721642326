import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { AiFillEye } from "react-icons/ai";
import { BsTrash3 } from "react-icons/bs";
import { MdEdit } from "react-icons/md";
import { AlertInfo } from "../../../common/Alert";
import TableSkeleton from "../../../common/TableSkeleton";
import { NavLink } from "react-router-dom";
import { ParametreGradesTableDatas } from "./ParametreGradesTableDatas";
import { IGrades } from "../../../../utils/api/grades/grades.type";
import { useGetGradesQuery } from "../../../../utils/api/grades/grades.api";
import moment from "moment";
import AddGradeModal from "../modals/AddGradeModal";
import { UseDeleteGrade } from "../requestForm/UseCrudGrade";

function ParametreGradesTable({
  modalAddGrade,
  setModalAddGrade,
  openModalAddGrade,
}) {
  const [pages, setPages] = useState(ParametreGradesTableDatas);

  const [grade, setGrade] = useState<IGrades | undefined>();
  const { data, isLoading } = useGetGradesQuery({});

  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          {/* <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/admin/sous-admins/${row?.slug}`}
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Voir"
            >
              <AiFillEye />
            </NavLink>
          </div> */}
          <div className="container-btn-action-icon-modal">
            <button
              className="btn btn-action-icon-edit with-tooltip"
              data-tooltip-content="Modifier"
              onClick={() => {
                setGrade(row);
                openModalAddGrade();
              }}
            >
              <MdEdit />
            </button>
          </div>
          <div className="container-btn-action-icon-modal">
            <DeleteGradeBtn slug={row?.slug} />
          </div>
        </div>
      </>
    );
  };
  const nameFormatter = (cell: any, row: any) => {
    return (
      <div className="d-flex gap-3 align-items-center">
        <p className="name-profil-grade-table mb-0">{cell}</p>
      </div>
    );
  };
  const typeFormatter = (cell: any, row: any) => {
    return cell === "homme" ? "Grade des hommes" : "Grade des femmes";
  };
  const dateAjoutFormatter = (cell: any, row: any) => {
    return moment(cell).format("ll");
  };
  const columns = [
    {
      dataField: "titre",
      text: `Noms`,
      formatter: nameFormatter,
    },
    {
      dataField: "sexe",
      text: "Type",
      formatter: typeFormatter,
    },
    {
      dataField: "created_at",
      text: "Date d’ajout",
      formatter: dateAjoutFormatter,
    },
    {
      dataField: "id",
      text: "Action",
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results || []}
            columns={columns}
            condensed
            hover
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvée" />
            )}
          />
          {/* <div className="custom-pagination-container">
              <CustomPagination
                  nbPages={FacturePraticienDatas?.length}
                  page={page}
                  onChange={(page, perPage) => {
                  setPerPage(perPage)
                  setPage(page)
                  }}
                  perPage={perPage}
              />
              </div> */}
        </>
      )}
      <AddGradeModal
        modalAddGrade={modalAddGrade}
        setModalAddGrade={setModalAddGrade}
        grade={grade}
        setGrade={setGrade}
      />
    </>
  );
}

export default ParametreGradesTable;

export function DeleteGradeBtn({ slug }: { slug: string }) {
  const onDelete = UseDeleteGrade(slug);

  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content="Supprimer"
      style={{ color: "#E53E3E" }}
      onClick={() => onDelete()}
    >
      <BsTrash3 />
    </button>
  );
}
