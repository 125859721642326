import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl } from "../../Utils";
import { DemandeFormData, IDemande } from "./demande.type";

export const DemandesApi = createApi({
  reducerPath: "demandesApi",
  tagTypes: ["demandes", "demandesList"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    addOrEditDemandes: builder.mutation<
      IDemande,
      { slug?: string; data: DemandeFormData | FormData }
    >({
      invalidatesTags: ["demandes", "demandesList"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `demandes/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `demandes/`,
          method: "POST",
          body: data,
        };
      },
    }),

    getDemandes: builder.query<PaginationResults<IDemande>, TypeQuery>({
      providesTags: ["demandesList"],
      query: (query) => QueryUrl("demandes/", query),
    }),

    deleteDemande: builder.mutation<DemandeFormData, string>({
      query: (slug) => ({
        url: `demandes/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["demandes", "demandesList"],
    }),
  }),
});

export const {
    useAddOrEditDemandesMutation,
    useDeleteDemandeMutation,
    useGetDemandesQuery
} = DemandesApi;
