import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Input } from "../../../common/Input";
import { ObjetReligieux, ObjetReligieuxFormData } from "../../../../utils/api/objetReligieux/objetReligieux.type";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useUpdateObjetPositionMutation } from "../../../../utils/api/objetReligieux/objetReligieux.api";
import { cleannerError } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";

const UpdateOffrePositionModal = ({
  modalUpdateOffrePosition,
  setModalUpdateOffrePosition,
  item,
  setGrade,
}: {
  modalUpdateOffrePosition: any;
  setModalUpdateOffrePosition: any;
  item?: ObjetReligieux;
  setGrade?: any;
}) => {
  function closeModalUpdateOffrePosition() {
    setModalUpdateOffrePosition(false);
  }

  const validationSchema = yup.object().shape({
    position: yup.number().required().label("Position"),
  });

  const [changePosition, { isLoading, isSuccess, error, isError }] =
    useUpdateObjetPositionMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    reset,
  } = useForm<ObjetReligieuxFormData>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (item?.id) {
      setValue("slug", item?.slug);
      setValue("position", item?.position);
    }
  }, [item]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `L'objet religieux a été ${item ? "modifié" : "ajouté"
          } avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      })
        .then(() => {
          closeModalUpdateOffrePosition()
        });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    console.log("err", errors);
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: ObjetReligieuxFormData) => {
    await changePosition({ slug: item?.slug, data: data });
  };


  const resetAll = () => {
    reset();
  };
  return (
    <Modal
      show={modalUpdateOffrePosition}
      onHide={() => {
        resetAll();
        closeModalUpdateOffrePosition();
      }}
      aria-labelledby="Show Fidel Affectation Modal"
      animation={false}
      size="sm"
      centered
    >
      <Modal.Header className="modal-header border-0 p-3" closeButton>
        <Modal.Title className="modal-title">Modifier la position</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <form className="" onSubmit={handleSubmit(onSubmit)}>
            <div className="row row-add-student my-3">
              <div className="col-md-12 mb-3">
                <Input
                  label="Position"
                  id="position"
                  type="number"
                  placeholder="Position"
                  {...register("position")}
                  error={errors?.position}
                />
              </div>
              <div className="col-md-6 offset-md-6 auth-submit-btn-container text-end pt-3">
                <button
                  disabled={isLoading}
                  className="btn btn-theme-admin w-100"
                  type="submit"
                >
                  {isLoading ? (
                    <div className="d-flex align-items-center justify-content-center gap-2">
                      <span
                        className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                        role="status"
                      ></span>
                      <span>{"Modification..."}</span>
                    </div>
                  ) : "Modifier"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateOffrePositionModal;
