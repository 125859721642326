import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { BsTrash3 } from "react-icons/bs";
import { MdEdit } from "react-icons/md";
import { AlertInfo } from "../../../common/Alert";
import TableSkeleton from "../../../common/TableSkeleton";
import moment from "moment";
import { IModeles } from "../../../../utils/api/modeles/modeles.type";
import { useGetModelesQuery } from "../../../../utils/api/modeles/modeles.api";
import AddModeleModal from "../modals/AddModeleModal";
import { UseDeleteModele } from "../requestForm/UseCrudModele";
import { AiFillEye } from "react-icons/ai";
import ViewModeleModale from "../modals/ViewModeleModale";
import { useAppSelector } from "../../../../redux/hook";
import { isSubAdmin } from "../../../../routes/routerUtils";

function ParametreModelesTable({
  modalAddModele,
  setModalAddModele,
  openModalAddModele,
  modalViewModele,
  setModalViewModele,
  openModalViewModele,
}) {
  const is_SubAdmin = useAppSelector((s) => isSubAdmin(s.user?.user));
  const [modele, setModele] = useState<IModeles | undefined>();
  const { data, isLoading } = useGetModelesQuery({});

  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            <button
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Voir"
              onClick={() => {
                setModele(row);
                openModalViewModele();
              }}
            >
              <AiFillEye />
            </button>
          </div>
          {is_SubAdmin && (
            <>
              <div className="container-btn-action-icon-modal">
                <button
                  className="btn btn-action-icon-edit with-tooltip"
                  data-tooltip-content="Modifier"
                  onClick={() => {
                    setModele(row);
                    openModalAddModele();
                  }}
                >
                  <MdEdit />
                </button>
              </div>
              <div className="container-btn-action-icon-modal">
                <DeleteModeleBtn slug={row?.slug} />
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  const couleurFormatter = (cell: any, row: any) => {
    return cell === "bleu"
      ? "Bleu"
      : cell === "violet"
      ? "violet"
      : cell === "beige"
      ? "Beige"
      : "Vert";
  };
  const dateAjoutFormatter = (cell: any, row: any) => {
    return moment(cell).format("ll");
  };
  const columns = [
    {
      dataField: "couleur",
      text: `Couleur`,
      formatter: couleurFormatter,
    },
    {
      dataField: "created_at",
      text: "Date d’ajout",
      formatter: dateAjoutFormatter,
    },
    {
      dataField: "id",
      text: "Action",
      formatter: actionFormatter,
      headerStyle: () => {
        return { width: "170px", whiteSpace: "normal" };
      },
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results || []}
            columns={columns}
            condensed
            hover
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvée" />
            )}
          />
        </>
      )}
      <AddModeleModal
        modalAddModele={modalAddModele}
        setModalAddModele={setModalAddModele}
        modele={modele}
        setModele={setModele}
      />
      <ViewModeleModale
        modalViewModele={modalViewModele}
        setModalViewModele={setModalViewModele}
        modele={modele}
        setModele={setModele}
      />
    </>
  );
}

export default ParametreModelesTable;

export function DeleteModeleBtn({ slug }: { slug: string }) {
  const onDelete = UseDeleteModele(slug);

  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content="Supprimer"
      style={{ color: "#E53E3E" }}
      onClick={() => onDelete()}
    >
      <BsTrash3 />
    </button>
  );
}
