import React, { useState } from 'react'
import { AlertInfo } from '../../../../common/Alert';
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from '../../../../common/TableSkeleton';
import { DocumnentTableData } from '.';
import PdfImg from "../../../../../assets/icons/pdf.png"


function DocumentTable(props) {

  const [pages, setPages] = useState(DocumnentTableData)
  
    const documentFormatter = (cell: any, row: any) => {
      return (
        <div className="d-flex gap-2 align-items-center">
          
          <img src={PdfImg} alt="pdf" className='pdf-fichier-table' />
          <div className="content-format-statut-table">
              <p className='name-doc-format mb-1'>
                {row?.document}
              </p>
          </div>
        </div>
      );
    };

    const columns = [
      {
        dataField: "document",
        text: "Documents",
        formatter: documentFormatter,
      },
      {
        dataField: "uploaded_by",
        text: "Uploaded by",
      },
      {
        dataField: "date",
        text: "Date ",
      },
    ];

    return (
      <>
        {props?.isLoading && <TableSkeleton headers={columns} />}
        {!props?.isLoading && (
          <>
            <BootstrapTable
              keyField="id"
              data={pages}
              columns={columns}
              condensed
              hover
              wrapperClasses="table-responsive admin-table"
              noDataIndication={() => (
                <AlertInfo message="Aucune donnée trouvée" />
              )}
            />
            {/* <div className="custom-pagination-container">
              <CustomPagination
                  nbPages={FacturePraticienDatas?.length}
                  page={page}
                  onChange={(page, perPage) => {
                  setPerPage(perPage)
                  setPage(page)
                  }}
                  perPage={perPage}
              />
              </div> */}
          </>
        )}
      </>
    );
}

export default DocumentTable