import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { AlertInfo } from "../../../common/Alert";
import TableSkeleton from "../../../common/TableSkeleton";
import { NavLink } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { CustomPagination } from "../../../common/CustomPagination";
import { useGetNecrologieQuery } from "../../../../utils/api/necrologie/necrologie.api";
import { DeleteNecrologie } from "../useForm/UseCrudNecrologie";
import AddNecrologieModal from "../modals/AddNecrologieModal";
import { FaUserCircle } from "react-icons/fa";
import { ApiBaseUrl } from "../../../../utils/http";
import { Necrologie } from "../../../../utils/api/necrologie/necrologie.type";

const BtnEditNecrologie = ({ item }: { item: Necrologie }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <button
        className="btn btn-action-icon-edit with-tooltip"
        data-tooltip-content="Modifier"
        onClick={() => setShow(true)}
      >
        <MdEdit />
      </button>
      <AddNecrologieModal
        modalAddNecrologie={show}
        setModalAddNecrologie={setShow}
        necrologie={item}
      />
    </>
  );
};

// const BtnShowNecrologie = ({ item }: { item: Necrologie }) => {
//   const [show, setShow] = useState(false);
//   return (
//     <>
//       <button
//         type="button"
//         onClick={() => setShow(true)}
//         className="btn btn-action-modal-icon with-tooltip"
//         data-tooltip-content="Voir"
//       >
//         <AiFillEye />
//       </button>
//       <ShowNecrologieModal
//         modalShowNecrologie={show}
//         setModalShowNecrologie={setShow}
//         necrologie={item}
//       />
//     </>
//   );
// };

function NecrologieAdminTable({ q, isSuperAdmin }: { q: string, isSuperAdmin?: boolean }) {
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const { data = { results: [], count: 0 }, isLoading } = useGetNecrologieQuery(
    { limit: perPage, page, q }
  );

  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        {isSuperAdmin && <div className="table-actions-btn-container-commandes d-flex gap-3">
          {/* <div className="container-btn-action-icon-modal">
            <BtnShowNecrologie item={row} />
          </div> */}
          <div className="container-btn-action-icon-modal">
            <BtnEditNecrologie item={row} />
          </div>
          <DeleteNecrologie item={row} />
        </div>}
      </>
    );
  };
  const nameFormatter = (cell: any, row: any) => {
    return (
      <div className="d-flex gap-3 align-items-center">
        <div className="content-img-profil-itemt-table">
          {!!row?.image ? (
            <img
              src={`${ApiBaseUrl}${row?.image}`}
              alt="photo"
              className="img-profil-itemt-table"
            />
          ) : (
            <FaUserCircle style={{ color: "#ccc", fontSize: 35 }} />
          )}
        </div>
        <p
          className="name-profil-patient-table mb-0"
          style={{ fontWeight: 700 }}
        >
          {cell}&nbsp;{row?.prenom}
        </p>
      </div>
    );
  };
  const paroisseFormatter = (cell: any, row: any) => {
    return (
      <NavLink
        to={`/admin/paroisses/detail-paroisse/${cell?.slug}`}
        className="d-flex gap-3 align-items-center no-link"
        state={cell}
      >
        <p className="name-profil-grade-table mb-0">{cell?.nom}</p>
      </NavLink>
    );
  };
  const columns = [
    {
      dataField: "nom",
      text: `Noms et Photos`,
      formatter: nameFormatter,
      headerStyle: () => {
        return { width: "200px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "sexe",
      text: "Sexe",
    },
    {
      dataField: "pays.nom",
      text: "Pays",
    },
    {
      dataField: "paroisse",
      text: "Paroisse",
      formatter: paroisseFormatter,
    },
    {
      dataField: "date",
      text: "Décès survenu",
    },
    {
      dataField: "age",
      text: "Âge",
    },
    {
      dataField: "id",
      text: "Action",
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      {!!isLoading && <TableSkeleton headers={columns} />}
      {!isLoading &&
        (data?.results?.length ? (
          <>
            <BootstrapTable
              keyField="id"
              data={data?.results}
              columns={columns}
              condensed
              hover
              wrapperClasses="table-responsive admin-table"
              noDataIndication={() => (
                <AlertInfo message="Aucune donnée trouvée" />
              )}
            />
            <div className="custom-pagination-container">
              <CustomPagination
                nbPages={data?.count}
                page={page}
                onChange={(page, perPage) => {
                  setPerPage(perPage);
                  setPage(page);
                }}
                perPage={perPage}
              />
            </div>
          </>
        ) : (
          <AlertInfo message="Aucune donnée trouvée" />
        ))}
    </>
  );
}

export default NecrologieAdminTable;
