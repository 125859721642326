import React from 'react';

const Ribbon = ({ text }) => {
  return (
    <div className="ribbon-container">
      <div className="ribbon">
        <span>{text}</span>
      </div>
    </div>
  );
};

export default Ribbon;