import React, { useState } from "react";
import { IoNotifications } from "react-icons/io5";
import {
  useGetCompteRendusQuery,
  useGetReunionsAvenirQuery,
  useGetReunionsQuery,
} from "../../../../utils/api/reunion/reunion.api";
import { AlertInfo } from "../../../common/Alert";
import moment from "moment";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { UseDeleteReunion } from "../../ConseilAdministration/requestForm/UseCrudReunion";
import { UseDeleteCompteRendu } from "../../ConseilAdministration/requestForm/UseCrudCompteRendu";
import {
  ICompteRendu,
  IReunion,
} from "../../../../utils/api/reunion/reunion.type";
import AddMeetingModal from "../../ConseilAdministration/modal/AddMeetingModal";
import AddContenuMeetingModal from "../../ConseilAdministration/modal/AddContenuMeetingModal";
import { createMarkup, truncateString } from "../../../../utils/Utils";
import CompteRenduSkeleton from "../../ConseilAdministration/CompteRenduSkeleton";
import ReunionSkeleton from "../../ConseilAdministration/ReunionSkeleton";
import { NavLink } from "react-router-dom";
import { CustomPagination } from "../../../common/CustomPagination";
import { useAppSelector } from "../../../../redux/hook";
import { isSuperAdmin } from "../../../../routes/routerUtils";

function DeleteReunionBtn({ slug }: { slug: string }) {
  const onDelete = UseDeleteReunion(slug);
  return (
    <button className="dropdown-item" onClick={() => onDelete()}>
      Supprimer
    </button>
  );
}
function DeleteCompteRendu({ slug }: { slug: string }) {
  const onDelete = UseDeleteCompteRendu(slug);
  return (
    <button className="dropdown-item" onClick={() => onDelete()}>
      Supprimer
    </button>
  );
}

const ReunionsTabs = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  // const { data, isLoading: isLoad } = useGetReunionsQuery({
  //   q_type: "conseil_pastoral/",
  // });
  const { data, isLoading: isLoad } = useGetReunionsAvenirQuery({
    q_type: "conseil_pastoral/",
  });
  const { data: datas, isLoading } = useGetCompteRendusQuery({
    q_type: "conseil_pastoral",
    page,
    limit: perPage,
  });
  return (
    <div className="row mt-3">
      <div className="col-lg-9 col-md-12">
        <div className="content-graph-admin">
          <div className="row">
            {!!isLoading && <CompteRenduSkeleton />}
            {!isLoading &&
              (!!datas?.results && datas?.results.length > 0 ? (
                datas?.results?.map((item) => (
                  <div
                    className="col-md-6 mb-4 col-container-meeting-bloc"
                    key={item?.slug}
                  >
                    <ReunionItem item={item} />
                  </div>
                ))
              ) : (
                <AlertInfo message="Aucune donnée trouvée" />
              ))}

          </div>
          {datas?.results && (
            <div className="custom-pagination-container">
              <CustomPagination
                nbPages={datas?.count}
                page={page}
                onChange={(page, perPage) => {
                  setPerPage(perPage);
                  setPage(page);
                }}
                perPage={perPage}
              />
            </div>
          )}
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="content-graph-admin px-1">
          {!!isLoad && <ReunionSkeleton />}
          {!isLoad &&
            (!!data?.results && data?.results.length > 0 ? (
              data?.results?.map((item) => (
                <MeetingModal item={item} key={item?.slug} />
              ))
            ) : (
              <AlertInfo message="Aucune donnée trouvée" />
            ))}
          {!!data?.results && data?.results.length > 0 && <NavLink
            to={"/admin/reunions"}
            className="btn custom-btn-view-more mt-4"
          >
            <span>Voir tous les reunions</span>
          </NavLink>}
        </div>
      </div>
    </div>
  );
};
export default ReunionsTabs;

const ReunionItem = ({ item }: { item: ICompteRendu }) => {
  const [show, setShow] = useState(false);
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));
  return (
    <>
      <div className="container-meeting-bloc-item p-2">
        <div className="d-flex justify-content-between align-items-start">
          <h3 className="title-bloc-meeting-item">
            {truncateString(item?.titre, 50)}
          </h3>
          <div className="btn-group">
            {is_SuperAdmin && <button
              className="btn btn-secondary btn-sm dropdown-toggle documentation-archive-custom-btn"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <BiDotsHorizontalRounded />
            </button>}
            <ul className="dropdown-menu">
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  Modifier
                </button>
              </li>
              <li>
                <DeleteCompteRendu slug={item?.slug} />
              </li>
            </ul>
          </div>
        </div>
        <NavLink
          to={`/admin/contenu/${item?.slug}`}
          className="no-link"
          state={item}
        >
          <div className="content-text-bloc-meeting-item my-3">
            <p
              className="text-bloc-meeting-item"
              dangerouslySetInnerHTML={createMarkup(
                truncateString(item?.description, 300)
              )}
            />
          </div>
          <div className="bloc-meeting-date">
            <p className="text-bloc-meeting-item">Date de création</p>
            <p className="text-bold-bloc-meeting-item">
              {moment(item?.created_at).format("ll")}
            </p>
          </div>
          {item?.fichier ? (
            <div className="bloc-meeting-date">
              <p className="text-bloc-meeting-item">Fichiers:</p>
              <p className="text-bold-bloc-meeting-item">1</p>
            </div>
          ) : null}
        </NavLink>
      </div>
      <AddContenuMeetingModal
        modalAddContenuMeeting={show}
        setModalAddContenuMeeting={setShow}
        membre_type={"conseil_pastoral"}
        compteRendu={item}
      />
    </>
  );
};

const MeetingModal = ({ item }: { item: IReunion }) => {
  const [show, setShow] = useState(false);
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));
  return (
    <>
      <div className="content-notif-meeting-item mb-3 p-2" key={item?.slug}>
        <div className="content-text-notif-meeting">
          <p className="text-date-meeting-notif mb-1">
            {moment(item?.heure_debut, "HH:mm").format("HH:mm A")}{" "}
            {item?.heure_fin &&
              "- " + moment(item?.heure_fin, "HH:mm").format("HH:mm A")}
          </p>
          <p className="text-bold-date-meeting-notif mb-0">
            {moment(item?.date).format("LL")}
          </p>
        </div>
        <div className="content-icon-notif-meeting align-items-end">
          <IoNotifications />
          <div className="btn-group">
            {is_SuperAdmin && <button
              className="btn btn-secondary btn-sm dropdown-toggle documentation-archive-custom-btn"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <BiDotsHorizontalRounded />
            </button>}
            <ul className="dropdown-menu">
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  Modifier
                </button>
              </li>
              <li>
                <DeleteReunionBtn slug={item?.slug} />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <AddMeetingModal
        modalAddMeeting={show}
        setModalAddMeeting={setShow}
        membre_type={"conseil_pastoral"}
        reunion={item}
      />
    </>
  );
};
