import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { OnctionData } from "./TabsData";
import FormationCard from "../FormationCard";
import { useStepType } from "../../../../utils/type";
import { useStep } from "react-hooks-helper";
import { MdAddCircleOutline } from "react-icons/md";
import { FormError, Input } from "../../../common/Input";
import { BtnSubmit } from "../../../common/Button";
import {
  useGetListFormationQuery,
  useAddOrEditFormationMutation,
} from "../../../../utils/api/formations/formation.api";
import { AlertInfo } from "../../../common/Alert";
import { Formation } from "../../../../utils/api/formations/formation.type";
import UseCrudOnction from "../request/UseCrudOnction";
import MyDropzoneFile, { RenderFile } from "../../../common/MyDropzoneFile";
import { UseDeleteOnction } from "../request/UseCrudOnction";
import { AiOutlineMore } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import { ApiBaseUrl } from "../../../../utils/http";
import { RiAlarmWarningLine } from "react-icons/ri";
import { PaginationResults } from "../../../../utils/api/user/user.type";
import { FormationSkeleton } from "../FormationAdmin";

function DeleteOnctionBtn({ slug }: { slug: string }) {
  const onDelete = UseDeleteOnction(slug);
  return (
    <button className="dropdown-item" onClick={() => onDelete()}>
      Supprimer
    </button>
  );
}

const ListCoursOnction = ({
  go,
  setItem,
  data,
  isLoading,
  is_SuperAdmin
}: {
  go: any;
  setItem: Dispatch<SetStateAction<Formation | undefined>>;
  data: Formation[];
  isLoading: boolean;
  is_SuperAdmin?: boolean;
}) => {
  return (
    <>
      <div className="row row-onction mt-3">
        {!isLoading ? (
          data?.length > 0 ? (
            data?.map((onction, key) => {
              return (
                <div
                  className="col-md-3 card-wrapper mb-4"
                  key={`${key}_${onction?.id}`}
                >
                  <div className="custom-formation-card">
                    {!!onction?.fichier && onction?.fichier !== undefined ? (
                      <span className="video-cover-content">
                        <video
                          className="formation-video"
                          controls
                          muted
                          loop
                          poster={`${ApiBaseUrl}${onction?.fichier}`}
                        >
                          <source src={`${ApiBaseUrl}${onction?.fichier}`} />
                        </video>
                      </span>
                    ) : (
                      <span className="url-not-found-content">
                        <RiAlarmWarningLine style={{ fontSize: 25 }} /> <br />
                        Oups!! <br />
                        <br />
                        L'url de la vidéo est non définie ou peut-etre vide
                        <br />
                      </span>
                    )}
                    <span className="title">{onction?.titre}</span>
                    <div className="d-flex justify-content-between">
                      <span className="author pt-2">
                        <FaUser className="icon" />
                        {`${onction?.user?.prenom}${" "}${onction?.user?.nom}`}
                      </span>
                      <div className="btn-group">
                        {is_SuperAdmin && <button
                          className="btn btn-secondary btn-sm dropdown-toggle documentation-archive-custom-btn"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <AiOutlineMore style={{ fontSize: 18 }} />
                        </button>}
                        <ul className="dropdown-menu">
                          <li>
                            <button
                              className="dropdown-item"
                              onClick={() => {
                                setItem(onction);
                                go("add-cours-onction");
                              }}
                            >
                              Modifier
                            </button>
                          </li>
                          <li>
                            <DeleteOnctionBtn slug={onction?.slug} />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <AlertInfo message="Oups! Aucune onction trouvée" />
          )
        ) : (
          <FormationSkeleton />
        )}
      </div>
    </>
  );
};

const AddCoursOnction = ({
  onction,
  go,
  setItem,
}: {
  onction?: Formation;
  go?: any;
  setItem: Dispatch<SetStateAction<Formation | undefined>>;
}) => {
  const {
    register,
    onSubmit,
    handleReset,
    setVideo,
    video,
    isLoading,
    errors,
  } = UseCrudOnction(go, onction, setItem);
  return (
    <div className="content-graph-admin">
      <form onSubmit={onSubmit}>
        <div className="row">
          <div className="col-md-12">
            <Input
              label="Titre"
              type="text"
              placeholder="Titre"
              {...register("titre")}
              error={errors?.titre}
              required
            />
          </div>
          <div className="col-md-12">
            <label className="form-label form-label-modal-custom">
              Ajouter une vidéo{" "}
              <span className="text-danger" style={{ fontSize: 15 }}>
                *
              </span>
            </label>
            <MyDropzoneFile isVideoFile setFiles={setVideo} maxFile={1} />
            {!!video && (
              <RenderFile file={video?.name || video} isVideoFile={true} />
            )}
            <FormError error={errors?.fichier} />
            {/* {!!video && video?.type.startsWith("video/") ? (
              <div className="col-md-4 mb-3">
                <div className="image-item properti-img-card ">
                  <img
                    src={URL.createObjectURL(video)}
                    alt={"video"}
                    loading="lazy"
                    width={"100"}
                    className="custom-video"
                  />
                </div>
              </div>
            ) : (
              <div>{video?.name}</div>
            )} */}
          </div>
        </div>
        <div className="col-md-4 offset-md-8 auth-submit-btn-container text-end pt-3">
          <button
            disabled={isLoading}
            className="btn btn-theme-cancel w-100"
            type="button"
            onClick={() => {
              handleReset();
            }}
          >
            Retour
          </button>
          <button
            disabled={isLoading}
            className="btn btn-theme-admin w-100"
            type="submit"
          >
            {isLoading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                  role="status"
                ></span>
                <span>{onction ? "Modification..." : "Ajout..."}</span>
              </>
            ) : onction ? (
              "Modifier"
            ) : (
              "Ajouter"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

const steps = [
  { id: "list-cours-onction", Component: ListCoursOnction },
  { id: "add-cours-onction", Component: AddCoursOnction },
];

function Onction({
  data,
  isLoading,
  is_SuperAdmin
}: {
  data: PaginationResults<Formation>;
  isLoading: boolean;
  is_SuperAdmin?: boolean;
}) {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const [item, setItem] = useState<Formation>();

  const props = { go, onction: item, setItem, data: data?.results, isLoading, is_SuperAdmin };
  return (
    <>
      {is_SuperAdmin && <div className="btn-action-container">
        {index === 0 ? (
          <button
            onClick={() => {
              setItem(undefined);
              go("add-cours-onction");
            }}
            className="btn custom-add-btn"
          >
            Ajouter une onction
            <MdAddCircleOutline className="add-icon" />
          </button>
        ) : (
          <h3 className="custom-add-item-title">
            {!item ? "Ajouter" : "Modifier"} un cours sur l'onction
          </h3>
        )}
      </div>}

      <div className="custom-content pt-1">
        <Component {...props} />
      </div>
    </>
  );
}

export default Onction;
