import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { AiFillEye } from "react-icons/ai";
import { BsFillFilterSquareFill, BsTrash3 } from "react-icons/bs";
import { AlertInfo } from "../../../common/Alert";
import TableSkeleton from "../../../common/TableSkeleton";
import { NavLink } from "react-router-dom";
import { FideleTableData } from "../tables";
import fidel_profil from "../../../../assets/appImages/avatar2.png";
import { BiSearchAlt2 } from "react-icons/bi";
import { useGetListFideleQuery } from "../../../../utils/api/fideles/fidele.api";
import { CustomPagination } from "../../../common/CustomPagination";
import { IFideles } from "../../../../utils/api/fideles/fideles.type";
import { getAvatar } from "../../../../utils/Utils";
import { IParoisse } from "../../../../utils/api/paroisses/paroisses.type";
import { IPays } from "../../../../utils/api/pays/pays.type";
import { MdEdit } from "react-icons/md";
import { UseDeleteFidele } from "../requestForm/UseCrudFideleForm";

function FideleTable({ q }: { q: string }) {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data = { results: [], count: 0 }, isLoading } = useGetListFideleQuery(
    {
      limit: perPage,
      page,
      q,
    }
  );

  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/admin/paroisses/detail-fidele/${row?.slug}`}
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Voir"
              state={row}
            >
              <AiFillEye />
            </NavLink>
          </div>
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/admin/paroisses/modifier-un-fidele/${row?.slug}`}
              className="btn btn-action-icon-edit with-tooltip"
              data-tooltip-content="Modifier"
              state={row}
            >
              <MdEdit />
            </NavLink>
          </div>
          {/* <DeleteFidele item={row} /> */}
          {/* <div className="container-btn-action-icon-modal">
            <button
              className="btn with-tooltip btn-action-icon-delete"
              data-tooltip-content="Supprimer"
              style={{ color: "#E53E3E" }}
            >
              <BsTrash3 />
            </button>
          </div> */}
        </div>
      </>
    );
  };
  const nameFormatter = (cell: string, row: IFideles) => {
    return (
      <div className="content-cover-fidel-and-name d-flex gap-3 align-items-center">
        <img
          src={getAvatar(row?.avatar)}
          alt="Profil"
          className="fidel-profil-cover"
        />
        <span className="custom-fidel-name mb-0">{cell + " " + row?.nom}</span>
      </div>
    );
  };
  const paroisseFormatter = (cell: IParoisse) => {
    return (
      <NavLink
        to={`/admin/paroisses/detail-paroisse/${cell?.slug}`}
        className="custom-paroisse-link"
        state={cell}
      >
        {cell?.nom}
      </NavLink>
    );
  };

  const columns = [
    {
      dataField: "prenom",
      text: "Noms et Photos",
      formatter: nameFormatter,
      // headerStyle: () => {
      //   return { width: "200px", whiteSpace: "normal" };
      // },
    },
    {
      dataField: "sexe",
      text: "Sexe",
    },
    {
      dataField: "pays",
      text: "Pays",
      formatter: (cell: IPays) => cell?.nom,
    },
    {
      dataField: "paroisse",
      text: "Paroisse",
      formatter: paroisseFormatter,
    },
    {
      dataField: "type_fidele",
      text: "Type",
    },
    {
      dataField: "fonction",
      text: "Fonction",
    },
    // {
    //   dataField: "onction",
    //   text: "Onction",
    // },
    // {
    //   dataField: "program",
    //   text: "Programmes",
    // },
    {
      dataField: "slug",
      text: "Action",
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            // selectRow={selectRow}
            condensed
            hover
            wrapperClasses="table-responsive custom-eg-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvée" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage);
                setPage(page);
              }}
              perPage={perPage}
            />
          </div>
        </>
      )}
    </>
  );
}

export default FideleTable;

export function DeleteFidele({ item }: { item: IFideles}) {
	const deleteFidele = UseDeleteFidele(item);
	return (
    <div className="container-btn-action-icon-modal">
            <button
              className="btn with-tooltip btn-action-icon-delete"
              data-tooltip-content="Supprimer"
              style={{ color: "#E53E3E" }}
              // onClick={deleteFidele}
            >
              <BsTrash3 />
            </button>
          </div>
	);
}
