import { ObjetReligieux } from "../../../../utils/api/objetReligieux/objetReligieux.type";
import { PaginationResults } from "../../../../utils/api/user/user.type";
import { AlertInfo } from "../../../common/Alert";
import { ObjetCard } from "../ObjetAdmin";

function PresentationTab({
  data,
  isLoading,
  is_SuperAdmin,
  is_SecretaireOfficePastoral,
  step
}: {
  data: PaginationResults<ObjetReligieux>;
  isLoading: boolean;
  is_SuperAdmin?: boolean
  is_SecretaireOfficePastoral?: boolean
  step?: any
}) {
  return (
    <div className="row all-objects-row">
      {!!isLoading && <p>Chargement...</p>}
      {!isLoading &&
        (data?.results?.length ? (
          data?.results?.map((objr, key) => (
            <div
              className="col-md-4 mb-4 custom-wrapper"
              key={`${key}_${objr.id}`}
            >
              <ObjetCard item={objr} is_SuperAdmin={is_SuperAdmin} is_SecretaireOfficePastoral={is_SecretaireOfficePastoral} step={step} />
            </div>
          ))
        ) : (
          <AlertInfo message="Aucune donnée disponible" />
        ))}
    </div>
  )
}

export default PresentationTab