import React from "react";
import Modal from "react-bootstrap/Modal";
import { MdNotifications } from "react-icons/md";
import { getAvatar } from "../../../../utils/Utils";
import { IAffectation } from "../../../../utils/api/affectation/affectation.type";
import moment from "moment";
import { NavLink } from "react-router-dom";

const ShowDetailAffectationModal = ({
  modalShowFidelAffectation,
  setModalShowFidelAffectation,
  affectation,
}: {
  modalShowFidelAffectation?: any;
  setModalShowFidelAffectation: any;
  affectation?: IAffectation;
}) => {
  function closeModalShowFidelAffectation() {
    setModalShowFidelAffectation(false);
  }
  return (
    <Modal
      show={modalShowFidelAffectation}
      onHide={() => {
        // handleReset();
        closeModalShowFidelAffectation();
      }}
      aria-labelledby="Show Fidel Affectation Modal"
      animation={false}
      centered
    >
      <Modal.Body>
        <div className="show_affectation_modal">
          <div className="modal-header border-0 align-items-center justify-content-between pe-2 py-1">
            <div className="photo-and-name d-flex align-items-center gap-2">
              <span className="photo-wrapper">
                <img
                  src={getAvatar(affectation?.responsable?.avatar)}
                  alt="photo"
                />
              </span>
              <span className="name">
                {affectation?.responsable?.prenom +
                  " " +
                  affectation?.responsable?.nom}
              </span>
            </div>
            <div className="nofif-icon-wrapper">
              <MdNotifications color="#EB5757" size={32} />
            </div>
          </div>
          <div className="modal-body pt-4">
            {affectation?.temps_restant?.annees === 0 &&
            affectation?.temps_restant?.mois < 3 ? (
              <p className="m-0 pb-4 dng_message">
                Ce fidèle est presque à la fin de son affectation
              </p>
            ) : null}
            <p className="m-0 pb-2 actual-affectation">
              <span className="sm-title">
                Date de début de l’actuelle affectation:&nbsp;&nbsp;
              </span>
              <span className="sm_value">
                {moment(affectation?.date_debut).format("ll")}
              </span>
            </p>
            <p className="m-0 pb-2 finish-date-affectation">
              <span className="sm-title">
                Date de fin de l’affectation:&nbsp;&nbsp;
              </span>
              <span className="sm_value">
                {moment(affectation?.date_fin).format("ll")}
              </span>
            </p>
            <p className="m-0 pb-2 next-affectation">
              <span className="sm-title">
                Prochaine affectation:&nbsp;&nbsp;
              </span>
              <span className="sm_value">
                {affectation?.affectation_prochaine?.nom}
              </span>
            </p>
            <p className="m-0 pb-2 next-affectation">
              <span className="sm-title">
                Pays:&nbsp;&nbsp;
              </span>
              <span className="sm_value">
                {affectation?.pays?.nom}
              </span>
            </p>
            <p className="m-0 pb-2 next-affectation">
              <span className="sm-title">
                Adresse:&nbsp;&nbsp;
              </span>
              <span className="sm_value">
                {affectation?.adresse}
              </span>
            </p>
            <div className="btn-content pt-4">
              <NavLink
                to={"/admin/affectation/modifier-affectation"}
                className="btn btn-theme-admin"
                state={affectation}
              >
                <span>Affecter</span>
              </NavLink>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShowDetailAffectationModal;
