import React from "react";
import { Navigate } from "react-router-dom";
import DashboardAdmin from "../components/admin/DashboardAdmin/DashboardAdmin";
import Login from "../components/auth/Login";
import ForgetPassword from "../components/auth/ForgetPassword";
import ResetPassword from "../components/auth/ResetPassword";
import AdminLayout from "../components/shared/AdminLayout/AdminLayout";
import AddOrUpdatePreambuleAdmin from "../components/admin/PreambuleAdmin/AddOrUpdatePreambuleAdmin";
import PagesError404 from "../components/shared/PagesError/PagesError404";
import PagesError401 from "../components/shared/PagesError/PagesError401";
import PreambuleAdmin from "../components/admin/PreambuleAdmin/PreambuleAdmin";
import PasteursAdmin from "../components/admin/PasteursAdmin/PasteursAdmin";
import AddOrUpdatePasteurAdmin from "../components/admin/PasteursAdmin/AddOrUpdatePasteurAdmin";
import ConstitutionBleuAdmin from "../components/admin/ConstitutionBleuAdmin/ConstitutionBleuAdmin";
import AddOrUpdateConstitutionBleu from "../components/admin/ConstitutionBleuAdmin/AddOrUpdateConstitutionBleu";
import ConseilPastoralAdmin from "../components/admin/ConseilPastoralAdmin/ConseilPastoralAdmin";
import OfficePastoral from "../components/admin/OfficePastoral/OfficePastoral";
import DetailOfficePastoral from "../components/admin/OfficePastoral/DetailOfficePastoral";
import ConseilAdministration from "../components/admin/ConseilAdministration/ConseilAdministration";
import ParametreAdmin from "../components/admin/ParametreAdmin/ParametreAdmin";
import AddOrUpdateConditions from "../components/admin/ParametreAdmin/AddOrUpdateConditions";
import AddOrUpdatePolitiques from "../components/admin/ParametreAdmin/AddOrUpdatePolitiques";
import ProfilAdmin from "../components/admin/Profil/ProfilAdmin";
import PaysAdmin from "../components/admin/PaysAdmin/PaysAdmin";
import ParoisseAdmin from "../components/admin/ParoisseAdmin/ParoisseAdmin";
import DetailChef from "../components/admin/ParoisseAdmin/DetailChef/DetailChef";
import DetailParoisse from "../components/admin/ParoisseAdmin/DetailParoisse/DetailParoisse";
import DetailFidele from "../components/admin/ParoisseAdmin/DetailFidele/DetailFidele";
import AffectationAdmin from "../components/admin/AffectationAdmin/AffectationAdmin";
import FormationAdmin from "../components/admin/FormationAdmin/FormationAdmin";

import Messagerie from "../components/admin/Messagerie/Messaagerie";
import DetailMessage from "../components/admin/Messagerie/DetailMessage";
import NecrologieAdmin from "../components/admin/NecrologieAdmin/NecrologieAdmin";
import TestimonialsAdmin from "../components/admin/TestimonialsAdmin/TestimonialsAdmin";
import FinancesAdmin from "../components/admin/FinancesAdmin/FinancesAdmin";
import CarnetRoseAdmin from "../components/admin/CarnetRose/CarnetRoseAdmin";
import DocumentationsAdmin from "../components/admin/DocumentationsAdmin/DocumentationsAdmin";
import ProfilPasteur from "../components/admin/PasteursAdmin/ProfilPasteur";
import {
  IsAdminAllow,
  ProtectedRoutes,
  RedirectAuthRoute,
} from "./routerUtils";
import AddOrUpdateAdmin from "../components/admin/ParametreAdmin/AddOrUpdateAdmin";
import AddMembreOfficePastoral from "../components/admin/OfficePastoral/AddMembreOfficePastoral";

import { UserType } from "../utils/api/user/user.type";
import DetailsCompteRendu from "../components/admin/ConseilAdministration/DetailsCompteRendu";
import AddParoisseForm from "../components/admin/ParoisseAdmin/AddParoisseForm";
import AddActiviteParoisse from "../components/admin/ParoisseAdmin/DetailParoisse/AddActiviteParoisse";
import AddFideleForm from "../components/admin/ParoisseAdmin/AddFideleForm";
import AffectationDetail from "../components/admin/AffectationAdmin/AffectationDetail";
import CommunauteDetails from "../components/admin/ParoisseAdmin/DetailParoisse/tabs/CommunauteDetails";
import ActualiteAdmin from "../components/admin/ActualiteAdmin/ActualiteAdmin";
import CeremonieAdmin from "../components/admin/CeremonieAdmin/CeremonieAdmin";
import CollecteAdmin from "../components/admin/CollecteAdmin/CollecteAdmin";
import AddOrUpdateActualite from "../components/admin/ActualiteAdmin/AddOrUpdateActualite";
import ActualiteDetails from "../components/admin/ActualiteAdmin/ActualiteDetails";
import AddOrUpdateCeremonie from "../components/admin/CeremonieAdmin/AddOrUpdateCeremonie";
import AddOrUpdateCollecte from "../components/admin/CollecteAdmin/AddOrUpdateCollecte";
import CollecteDetails from "../components/admin/CollecteAdmin/CollecteDetails";
import ObjetAdmin from "../components/admin/ObjetAdmin/ObjetAdmin";
import AddOrUpdateObjet from "../components/admin/ObjetAdmin/AddOrUpdateObjet";
import ResponsableParoisien from "../components/admin/ResponsableParoisien/ResponsableParoisien";
import AddMembreResponsableParoissien from "../components/admin/ResponsableParoisien/AddMembreResponsableParoissien";
import DetailResponsableParoissien from "../components/admin/ResponsableParoisien/DetailsResponsableParoissien";
import AddAffectationsAdmin from "../components/admin/AffectationAdmin/AddAffectationsAdmin";
import AddFideleParoisse from "../components/admin/ParoisseAdmin/AddFideleParoisse";
import ResponsableDeDiocese from "../components/admin/ResponsableDeDiocese/ResponsableDeDiocese";
import AddChefDeDiocese from "../components/admin/ResponsableDeDiocese/AddChefDeDicese";
import DetailResponsableDeDiocese from "../components/admin/ResponsableDeDiocese/DetailsResponsableDeDiocese";
import AddMembreParoisse from "../components/admin/ParoisseAdmin/AddMembreParoisse";
import SecretariesAdmin from "../components/admin/SecretariesAdmin/SecretariesAdmin";
import AddOrUpdateSecretaryAdmin from "../components/admin/SecretariesAdmin/AddOrUpdateSecretaryAdmin";
import SecretaryProfile from "../components/admin/SecretariesAdmin/SecretaryProfile";
import ReunionAdmin from "../components/admin/Reunion/ReunionAdmin";
import DetailsObjet from "../components/admin/ObjetAdmin/DetailsObjet";
// UserRoutes

export const AppRoutes = [
  {
    path: "/",
    element: (
      <RedirectAuthRoute>
        <Login />
      </RedirectAuthRoute>
    ),
  },
  {
    path: "/mot-de-passe-oublie",
    element: <ForgetPassword />,
  },
  {
    path: "/reinitialiser-mot-de-passe",
    element: <ResetPassword />,
  },
  {
    path: "*",
    element: <PagesError404 />,
  },
  {
    path: "/not-authorized",
    element: <PagesError401 />,
  },
  {
    path: "admin/",
    element: (
      <ProtectedRoutes
        userType={[UserType.admin, UserType.superAdmin, UserType.membre, UserType.responsable_paroissien, UserType.chef_diocese, UserType.secretaire, UserType.pasteur]}
      >
        <AdminLayout />
      </ProtectedRoutes>
    ),

    children: [
      { element: <Navigate to="/admin/dashboard" replace />, index: true },
      {
        path: "dashboard",
        element: <DashboardAdmin />,
      },
      {
        path: "pasteurs",
        element: (
          <IsAdminAllow field="pasteurs">
            <PasteursAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "pasteurs/:slug",
        element: (
          <IsAdminAllow field="pasteurs">
            <ProfilPasteur />
          </IsAdminAllow>
        ),
      },
      {
        path: "ajouter-pasteur",
        element: (
          <IsAdminAllow field="pasteurs">
            <AddOrUpdatePasteurAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "secretaries",
        element: (
          <IsAdminAllow field="pasteurs">
            <SecretariesAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "secretaries/add-new",
        element: (
          <IsAdminAllow field="pasteurs">
            <AddOrUpdateSecretaryAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "secretaries/:slug",
        element: (
          <IsAdminAllow field="pasteurs">
            <SecretaryProfile />
          </IsAdminAllow>
        ),
      },
      {
        path: "constitution-bleu",
        element: (
          <IsAdminAllow field="contitution_bleues">
            <ConstitutionBleuAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "constitution-bleu/ajouter-document",
        element: (
          <IsAdminAllow field="contitution_bleues">
            <AddOrUpdateConstitutionBleu />
          </IsAdminAllow>
        ),
      },
      {
        path: "constitution-bleu/modifier-document/:slug",
        element: (
          <IsAdminAllow field="contitution_bleues">
            <AddOrUpdateConstitutionBleu />
          </IsAdminAllow>
        ),
      },
      {
        path: "preambule",
        element: (
          <IsAdminAllow field="preambules">
            <PreambuleAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "ajouter-preambule",
        element: (
          <IsAdminAllow field="preambules">
            <AddOrUpdatePreambuleAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "conseil-pastoral",
        element: (
          <IsAdminAllow field="conseil_pastoral">
            <ConseilPastoralAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "conseil-pastoral/add-member",
        element: (
          <IsAdminAllow field="conseil_pastoral">
            <AddMembreOfficePastoral />
          </IsAdminAllow>
        ),
      },
      {
        path: "conseil-pastoral/:slug",
        element: (
          <IsAdminAllow field="conseil_pastoral">
            <DetailOfficePastoral />
          </IsAdminAllow>
        ),
      },
      {
        path: "office-pastoral",
        element: (
          <IsAdminAllow field="office_pastoral">
            <OfficePastoral />
          </IsAdminAllow>
        ),
      },
      {
        path: "office-pastoral/add-member",
        element: (
          <IsAdminAllow field="office_pastoral">
            <AddMembreOfficePastoral />
          </IsAdminAllow>
        ),
      },
      {
        path: "office-pastoral/:slug",
        element: (
          <IsAdminAllow field="office_pastoral">
            <DetailOfficePastoral />
          </IsAdminAllow>
        ),
      },
      {
        path: "conseil-administration",
        element: (
          <IsAdminAllow field="conseil_administration">
            <ConseilAdministration />
          </IsAdminAllow>
        ),
      },
      {
        path: "conseil-administration/add-member",
        element: (
          <IsAdminAllow field="conseil_administration">
            <AddMembreOfficePastoral />
          </IsAdminAllow>
        ),
      },
      {
        path: "conseil-administration/:slug",
        element: (
          <IsAdminAllow field="conseil_administration">
            <DetailOfficePastoral />
          </IsAdminAllow>
        ),
      },
      {
        path: "responsable-paroissien",
        element: (
          <IsAdminAllow field="responsable_paroissien">
            <ResponsableParoisien />
          </IsAdminAllow>
        ),
      },
      {
        path: "responsable-paroissien/:slug",
        element: (
          <IsAdminAllow field="responsable_paroissien">
            <DetailResponsableParoissien />
          </IsAdminAllow>
        ),
      },
      {
        path: "responsable-paroissien/add-member",
        element: (
          <IsAdminAllow field="responsable_paroissien">
            <AddMembreResponsableParoissien />
          </IsAdminAllow>
        ),
      },
      {
        path: "contenu/:slug",
        element: <DetailsCompteRendu />,
      },
      {
        path: "parametres",
        element: (
          <IsAdminAllow field="parametres">
            <ParametreAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "ajouter-conditions-utilisations",
        element: (
          <IsAdminAllow field="parametres">
            <AddOrUpdateConditions />
          </IsAdminAllow>
        ),
      },
      {
        path: "ajouter-politique-confidentialite",
        element: (
          <IsAdminAllow field="parametres">
            <AddOrUpdatePolitiques />
          </IsAdminAllow>
        ),
      },
      {
        path: "ajouter-sous-admin",
        element: (
          <IsAdminAllow field="parametres">
            <AddOrUpdateAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "modifier-admin/:slug",
        element: (
          <IsAdminAllow field="parametres">
            <AddOrUpdateAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "profil",
        element: <ProfilAdmin />,
      },
      {
        path: "pays",
        element: <PaysAdmin />,
      },
      {
        path: "paroisses",
        element: (
          <IsAdminAllow field="paroisses">
            <ParoisseAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "paroisses/ajouter-une-paroisse",
        element: (
          <IsAdminAllow field="paroisses">
            <AddParoisseForm />
          </IsAdminAllow>
        ),
      },
      {
        path: "paroisses/:slug/ajouter-un-fidele",
        element: (
          <IsAdminAllow field="paroisses">
            <AddFideleParoisse />
          </IsAdminAllow>
        ),
      },
      {
        path: "paroisses/:slug/ajouter-un-member",
        element: (
          <IsAdminAllow field="paroisses">
            <AddMembreParoisse />
          </IsAdminAllow>
        ),
      },
      {
        path: "paroisses/:slug/modifier-un-member",
        element: (
          <IsAdminAllow field="paroisses">
            <AddMembreParoisse />
          </IsAdminAllow>
        ),
      },
      {
        path: "paroisses/ajouter-un-fidele",
        element: (
          <IsAdminAllow field="paroisses">
            <AddFideleForm />
          </IsAdminAllow>
        ),
      },
      {
        path: "paroisses/modifier-un-fidele/:slug",
        element: (
          <IsAdminAllow field="paroisses">
            <AddFideleForm />
          </IsAdminAllow>
        ),
      },
      {
        path: "paroisses/modifier-une-paroisse/:slug",
        element: (
          <IsAdminAllow field="paroisses">
            <AddParoisseForm />
          </IsAdminAllow>
        ),
      },
      {
        path: "paroisses/ajouter-une-activite",
        element: (
          <IsAdminAllow field="paroisses">
            <AddActiviteParoisse />
          </IsAdminAllow>
        ),
      },
      {
        path: "paroisses/chef/:slug",
        element: (
          <IsAdminAllow field="paroisses">
            <DetailChef />
          </IsAdminAllow>
        ),
      },
      {
        path: "paroisses/detail-paroisse/:slug",
        element: (
          <IsAdminAllow field="paroisses">
            <DetailParoisse />
          </IsAdminAllow>
        ),
      },
      {
        path: "paroisses/communaute/:slug",
        element: (
          <IsAdminAllow field="paroisses">
            <CommunauteDetails />
          </IsAdminAllow>
        ),
      },
      {
        path: "paroisses/detail-fidele/:slug",
        element: (
          <IsAdminAllow field="fideles">
            <DetailFidele />
          </IsAdminAllow>
        ),
      },
      {
        path: "messages",
        element: (
          <IsAdminAllow field="messages">
            <Messagerie />
          </IsAdminAllow>
        ),
      },
      {
        path: "message/:slug",
        element: (
          <IsAdminAllow field="messages">
            <DetailMessage />
          </IsAdminAllow>
        ),
      },
      {
        path: "necrologies",
        element: (
          <IsAdminAllow field="necrologies">
            <NecrologieAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "temoignages",
        element: (
          <IsAdminAllow field="témoignages">
            <TestimonialsAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "collectes",
        element: (
            <CollecteAdmin />
        ),
      },
      {
        path: "collectes/ajouter",
        element: (
            <AddOrUpdateCollecte />
        ),
      },
      {
        path: "collectes/modifier/:slug",
        element: (
            <AddOrUpdateCollecte />
        ),
      },
      {
        path: "collectes/details/:slug",
        element: (
            <CollecteDetails />
        ),
      },
      {
        path: "finances",
        element: (
          // <IsAdminAllow field="finances">
          <IsAdminAllow field="finances">
            <FinancesAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "carnet-rose",
        element: (
          <IsAdminAllow field="carnet_roses">
            <CarnetRoseAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "objets-religieux",
        element: (
            <ObjetAdmin />
        ),
      },
      {
        path: "objets-religieux/ajouter",
        element: (
            <AddOrUpdateObjet />
        ),
      },
      {
        path: "objets-religieux/modifier/:slug",
        element: (
            <AddOrUpdateObjet />
        ),
      },
      {
        path: "objets-religieux/:slug",
        element: (
            <DetailsObjet />
        ),
      },
      {
        path: "documentations",
        element: (
          <IsAdminAllow field="documentations">
            <DocumentationsAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "actualites",
        element: (
          <IsAdminAllow field="actualites">
            <ActualiteAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "actualites/ajouter",
        element: (
          <IsAdminAllow field="actualites">
            <AddOrUpdateActualite />
          </IsAdminAllow>
        ),
      },
      {
        path: "actualites/modifier/:slug",
        element: (
          <IsAdminAllow field="actualites">
            <AddOrUpdateActualite />
          </IsAdminAllow>
        ),
      },
      {
        path: "actualites/details/:slug",
        element: (
          <IsAdminAllow field="actualites">
            <ActualiteDetails />
          </IsAdminAllow>
        ),
      },
      {
        path: "affectation",
        element: (
          <IsAdminAllow field="affectations">
            <AffectationAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "affectation/add-affectation",
        element: (
          <IsAdminAllow field="affectations">
            <AddAffectationsAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "affectation/modifier-affectation",
        element: (
          <IsAdminAllow field="affectations">
            <AddAffectationsAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "affectation/detail",
        element: (
          <IsAdminAllow field="affectations">
            <AffectationDetail />
          </IsAdminAllow>
        ),
      },
      {
        path: "formations",
        element: (
          <IsAdminAllow field="formations">
            <FormationAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "ceremonies",
        element: (
            <CeremonieAdmin />
        ),
      },
      {
        path: "ceremonies/ajouter",
        element: (
            <AddOrUpdateCeremonie />
        ),
      },
      {
        path: "ceremonies/modifier/:slug",
        element: (
            <AddOrUpdateCeremonie />
        ),
      },
      {
        path: "responsable-dioceses",
        element: (
            <ResponsableDeDiocese />
        ),
      },
      {
        path: "responsable-dioceses/add",
        element: (
            <AddChefDeDiocese />
        ),
      },

      {
        path: "responsable-dioceses/:slug",
        element: (
          // <IsAdminAllow field="responsable_paroissien">
            <DetailResponsableDeDiocese />
          // </IsAdminAllow>
        ),
      },
      {
        path: "reunions",
        element: (
            <ReunionAdmin />
        ),
      },
    ],
  },
  {
    path: "fidele/",
    element: (
      // <IsAdminAllow>
      <AdminLayout />
      // </IsAdminAllow>
    ),
    // children: [
    //   { element: <Navigate to="/fidele/profil" replace />, index: true },

    //   {
    //     path: "profil",
    //     element: <ProfilAdmin />,
    //   },
    // ],
  },
];
