import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { AlertInfo } from "../../../common/Alert";
import TableSkeleton from "../../../common/TableSkeleton";
import { FaTrash } from "react-icons/fa";
import { AiFillEye } from "react-icons/ai";
import ShowDetailAffectationModal from "../modals/ShowDetailAffectationModal";
import { useAppSelector } from "../../../../redux/hook";
import { isSuperAdmin } from "../../../../routes/routerUtils";
import { useGetAffectationQuery } from "../../../../utils/api/affectation/affectation.api";
import { getAvatar } from "../../../../utils/Utils";
import moment from "moment";
import { MdEdit } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { DeleteAffectation } from "../UseCrudAffectation";
import { CustomPagination } from "../../../common/CustomPagination";

const BtnShowAffectation = ({ item }: { item?: any }) => {
  const [show, setShow] = useState<boolean>(false);
  return (
    <div className="container-btn-action-icon-modal">
      <button
        className="btn btn-action-modal-icon with-tooltip"
        data-tooltip-content="Voir"
        onClick={() => setShow(true)}
      >
        <AiFillEye />
      </button>
      <ShowDetailAffectationModal
        modalShowFidelAffectation={show}
        setModalShowFidelAffectation={setShow}
        affectation={item}
      />
    </div>
  );
};

function AffectationCountryTable({ country }: { country?: string }) {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data, isLoading } = useGetAffectationQuery({
    limit: perPage,
    page,
    //   q_type: "office_pastoral",
  });

  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));

  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <BtnShowAffectation item={row} />
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/admin/affectation/modifier-affectation`}
              className="btn btn-action-icon-edit with-tooltip"
              data-tooltip-content="Modifier"
              state={row}
            >
              <MdEdit />
            </NavLink>
          </div>
          <DeleteAffectation item={row} />
        </div>
      </>
    );
  };
  const nameFormatter = (cell: any, row: any) => {
    return (
      <div className="d-flex gap-3 align-items-center">
        <div className="content-img-profil-itemt-table">
          <img
            src={getAvatar(cell?.avatar)}
            alt="photo"
            className="img-profil-itemt-table"
          />
        </div>
        <p
          className="name-profil-patient-table mb-0"
          style={{ fontWeight: 700 }}
        >
          {cell?.prenom}&nbsp;{cell?.nom}
        </p>
      </div>
    );
  };
  const affectationFormatter = (cell: any, row: any) => {
    return (
      <div className="d-flex gap-3 align-items-center">
        <div>{cell?.nom}</div>
      </div>
    );
  };
  const paysFormatter = (cell: any, row: any) => {
    return (
      <div className="d-flex gap-3 align-items-center">
        <div>{cell?.nom}</div>
      </div>
    );
  };
  const dateFormatter = (cell: any, row: any) => {
    return (
      <div className="d-flex gap-3 align-items-center">
        <div>{moment(cell).format("L")}</div>
      </div>
    );
  };
  const remainingTimeFormatter = (cell: any, row: any) => {
    return (
      <div className="remaining-time dng__color">
        {cell?.annees !== 0 ? cell?.annees + "ans " : null}{" "}
        {cell?.mois !== 0 ? cell?.mois + "mois " : null}{" "}
        {cell?.jours !== 0 ? cell?.jours + "jours" : null}
      </div>
    );
  };

  const columns = [
    {
      dataField: "responsable",
      text: `Noms et Photos`,
      formatter: nameFormatter,
      headerStyle: () => {
        return { width: "200px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "affection_actuelle",
      text: "Actuelle affectation",
      formatter: affectationFormatter,
    },
    {
      dataField: "pays",
      text: "Pays affectation",
      formatter: paysFormatter,
    },
    {
      dataField: "date_debut",
      text: "Date de début",
      formatter: dateFormatter,
    },
    {
      dataField: "temps_restant",
      text: "Temps restant",
      formatter: remainingTimeFormatter,
    },
    {
      dataField: "affectation_prochaine",
      text: "Prochaine affectation",
      formatter: affectationFormatter,
    },
    {
      dataField: "id",
      text: "Action",
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      {!isLoading === false && <TableSkeleton headers={columns} />}
      {!isLoading &&
        (!!data?.results ? (
          <>
            <BootstrapTable
              keyField="id"
              data={data?.results}
              columns={columns}
              condensed
              hover
              wrapperClasses="table-responsive admin-table"
              noDataIndication={() => (
                <AlertInfo message="Aucune donnée trouvée" />
              )}
            />
            <div className="custom-pagination-container">
              <CustomPagination
                nbPages={data?.count}
                page={page}
                onChange={(page, perPage) => {
                  setPerPage(perPage);
                  setPage(page);
                }}
                perPage={perPage}
              />
            </div>
          </>
        ) : (
          <AlertInfo message="Aucune donnée trouvée" />
        ))}
    </>
  );
}

export default AffectationCountryTable;
