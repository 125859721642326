import React, { ChangeEvent, useCallback } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { cleannerError, validatePhone } from "../../../utils/Utils";
import { IUser } from "../../../utils/api/user/user.type";
import { IMembre, MembreFormData } from "../../../utils/api/membre/membre.type";
import {
  useAddOrEditMembreMutation,
  useDeleteMembreMutation,
} from "../../../utils/api/membre/membre.api";
import { useParoisseByPaysQuery } from "../../../utils/api/paroisses/paroisses.api";
import { Color } from "../../../utils/theme";
import { Authorisations, FieldType } from "../../../utils/constante";
import { Data } from "../../common/GoogleInput";
import { useGetPaysQuery } from "../../../utils/api/pays/pays.api";
import { useGetGradesFideleQuery } from "../../../utils/api/grades/grades.api";
import { useGetModelesQuery } from "../../../utils/api/modeles/modeles.api";
import { roleAuthorizations } from "../../common/AutorisationCheckbox";

function UseCrudResponsableParoissien(field: string, membre?: IMembre | IUser) {
  const [code, setCode] = useState<string>("");
  const [phone, setPhone] = useState<string>();
  const validationSchema = yup.object().shape({
    prenom: yup.string().required("Prenom est obligatoire").label("Prénom"),
    nom: yup.string().required("Nom est obligatoire").label("Nom"),
    email: yup
      .string()
      .email("L'email est invalide")
      .required("Email est obligatoire"),
    telephone: validatePhone(yup, code),
    role: yup.string().label("Role").nullable(),
    avatar: yup.mixed().nullable().label("Photo"),
    paroisse_id: yup.string().required().label("La paroisse").nullable(),
    onction: yup.string().required().label("L'onction").nullable(),
    modele: yup.string().label("Le model de certificat").nullable(),
    pays: yup.string().required().label("Pays").nullable(),
    grade: yup.string().required().label("Le grade").nullable(),
    date_dernier_grade: yup
      .string()
      .label("La date du dernier grade")
      .nullable(),
    nationalite: yup.string().label("La nationalité").nullable(),
    adresse: yup.string().label("L'adresse").nullable(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
    setError,
    control,
  } = useForm<MembreFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [previewAvatar, setPreviewAvatar] = useState("");
  const [content, setContent] = useState<string>("");
  const [address, setAddress] = useState("");
  const [addOrUpdateMembre, { isLoading, isSuccess, error, isError }] =
    useAddOrEditMembreMutation();

  const [paroisses, setParoisses] = useState<
    { label: string; value: number }[]
  >([]);
  const [selected, setSelected] = useState<{ label: string; value: number }>();
  const [options, setOptions] = useState<{ label: string; value: number }[]>(
    []
  );
  const [optionsModeles, setOptionsModeles] = useState<
    { label: string; value: number, imageUrl: string }[]
  >([]);
  const [option, setOption] = useState<{ label: string; value: number }>();
  const [optionModele, setOptionModele] = useState<{
    label: string;
    value: number;
    imageUrl: string
  }>();
  const [pays, setPays] = useState<
    { label: string; value: number; slug?: string }[]
  >([]);
  const [selectedPays, setSelectedPays] = useState<{
    label: string;
    value: number;
    slug?: string;
  }>();
  const { data: countries = { results: [] } } = useGetPaysQuery({
    limit: 1000,
  });
  const [showParoisseError, setShowParoisseError] = useState(false);
  const [query, setQuery] = useState("");
  const [search, setSearch] = useState("");
  const { data = { results: [] }, isLoading: isLoadParoisse } =
    useParoisseByPaysQuery({
      limit: 1000,
      q: query,
      slug: selectedPays?.slug,
    });

  const { data: grades = { results: [] } } = useGetGradesFideleQuery({
    limit: 1000,
    q: search,
  });

  const { data: modeles = { results: [] }, isLoading: isLoadCertificat } = useGetModelesQuery({
    limit: 1000,
  });

  const handleChangePhone = (item, country) => {
    setCode(country?.countryCode);
    setPhone(item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue(`telephone`, "+" + item);
    } else {
      setValue(`telephone`, "");
    }
  };

  const onChangeAddress = (val: Data) => {
    setAddress(val?.rue);
    setValue("adresse", val?.rue);
  };

  const navigate = useNavigate();
  let path = "/admin/responsable-paroissien";

  useEffect(() => {
    if (data?.results?.length > 0) {
      setParoisses(
        data?.results?.map((el) => {
          return {
            label: el?.nom,
            value: el?.id,
          };
        })
      );
    } else {
      setParoisses([]);
      setSelected(undefined);
    }

    if (countries?.results?.length) {
      setPays(
        countries?.results?.map((el) => {
          return {
            label: el?.nom,
            value: el?.id,
            slug: el?.slug,
          };
        })
      );
    }

    if (grades?.results?.length) {
      setOptions(
        grades?.results?.map((el) => {
          return {
            label: el?.titre,
            value: el?.id,
          };
        })
      );
    }

    if (modeles?.results?.length) {
      setOptionsModeles(
        modeles?.results?.map((el) => {
          return {
            label:
              el?.couleur === "vert"
                ? "Vert"
                : el?.couleur === "bleu"
                ? "Bleu"
                : el?.couleur === "beige"
                ? "Beige"
                : el?.couleur === "violet"
                ? "Violet"
                : "",
            value: el?.id,
            imageUrl: el?.image
          };
        })
      );
    }
  }, [data, grades, countries, modeles]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: membre
          ? "Membre modifié avec succès !"
          : "Membre ajouté avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (!membre) {
          reset();
          setContent("");
        }
        navigate(path);
      });
    }
    const err = error as any;
    if (isError) {
      if (
        err?.data?.email?.includes("Utilisateur avec ce courriel existe déjà.")
      ) {
        Swal.fire({
          icon: "error",
          title: err?.data?.email,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Error status ${err?.status}`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: any) => {
    !membre && (data[field] = true);
    if(data?.conseil_pastoral === undefined) {
      data['conseil_pastoral'] = false
    }
    if(data?.conseil_administration === undefined) {
      data['conseil_administration'] = false
    }
    if(data?.affectations === undefined) {
      data['affectations'] = false
    }
    console.log("data submitted", data);
    let fd = new FormData();
    for (let key of Object.keys(data)) {
      fd.append(key, data[key]);
    }
    await addOrUpdateMembre({ slug: membre?.slug, data: fd, field: field });
  };

  const handelAvatarChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files ? e.target.files[0] : null;

    if (
      file?.type !== "image/jpeg" &&
      file?.type !== "image/png" &&
      file?.type !== "image/jpg" &&
      file?.type !== "image/gif"
    ) {
      return setError("avatar", { message: "Image format is incorrect." });
    }
    if (file) {
      setPreviewAvatar(URL.createObjectURL(file));
      register("avatar");
      setValue("avatar", file);
    }
  }, []);

  const onSelectPays = (e) => {
    setSelectedPays(e);
    setValue("pays", e?.value);
  };

  const onSelectGrade = (e) => {
    setOption(e);
    setValue("grade", e?.value);
  };

  const onSelectModele = (e) => {
    setOptionModele(e);
    setValue("modele", e?.value);
  };

  const onSelectParoisse = (e) => {
    if (!selectedPays) {
      setShowParoisseError(true);
      return;
    }
    setShowParoisseError(false);
    setSelected(e);
    setValue("paroisse_id", e?.value);
  };

  const handleParoisseFocus = () => {
    if (!selectedPays) {
      setShowParoisseError(true);
    }
  };

  const handleReset = () => {
    if (!membre) {
      reset();
    }
  };

  const isAuthorizedByRole = (itemField: string) => {
    if (field !== 'conseil_pastoral' && field !== 'conseil_administration' && field !== 'office_pastoral' && field !== 'responsable_paroissien') return false;
    return roleAuthorizations[field].includes(itemField as FieldType);
};
  useEffect(() => {
    if (membre?.id) {
      // console.log({ membre });
      let fields: (keyof MembreFormData)[] = [
        "id",
        "slug",
        "prenom",
        "nom",
        "telephone",
        "email",
        "role",
        "conseil_pastoral",
        "conseil_administration",
        "office_pastoral",
        "dashboard",
        "fideles",
        "demandes",
        "paroisses",
        "actualites",
        "cotisations",
        "formations",
        "messages",
        "parametres",
        "preambules",
        "contitution_bleues",
        "pasteurs",
        "affectations",
        "documentations",
        "carnet_roses",
        "finances",
        "témoignages",
        "necrologies",
        "paroisse_id",
        "responsable_paroissien",
        "responsable_diocesien",
        "objet_religieux",
        "collectes",
        "actualites",
        "ceremonies",
        "adresse",
        "date_dernier_grade",
        "grade",
        "pays",
        "nationalite",
        "onction",
        "modele",
      ];
      for (let field of fields) {
        register(field);
        if (
          field !== "paroisse_id" &&
          field !== "grade" &&
          field !== "pays" &&
          field !== "modele"
        ) {
          if (membre[field]) setValue(field, membre[field]);
        } else {
          if (membre[field]) {
            setValue(field, membre[field]?.id);
          }
        }
      }
      if (membre?.telephone) {
        setPhone(membre?.telephone);
      }
      if (membre?.adresse) {
        setAddress(membre?.adresse);
      }

      if (membre?.paroisse_id) {
        setSelected({
          label: membre.paroisse_id?.nom,
          value: membre.paroisse_id?.id,
        });
      }

      if (membre?.pays) {
        setSelectedPays({
          label: membre.pays?.nom,
          value: membre.pays?.id,
          slug: membre.pays?.slug,
        });
      }

      if (membre?.grade) {
        setOption({
          label: membre.grade?.titre,
          value: membre.grade?.id,
        });
      }
      if (membre?.modele) {
        setOptionModele({
          label:
            membre?.modele?.couleur === "vert"
              ? "Vert"
              : membre.modele?.couleur === "bleu"
              ? "Bleu"
              : membre?.modele?.couleur === "beige"
              ? "Beige"
              : membre?.modele?.couleur === "violet"
              ? "Violet"
              : "",
          value: membre?.modele?.id,
          imageUrl: membre?.modele?.image
        });
      }
    } else {
      Authorisations.forEach((item) => {
        if(item?.field === field || item?.field === 'dashboard' || isAuthorizedByRole(item.field)) {
          setValue(item?.field, true);
        } else {
          setValue(item?.field, false);
        }
      });
      setOption(undefined);
      setValue("paroisse_id", undefined);
    }
  }, [membre]);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    content,
    handleReset,
    handelAvatarChange,
    previewAvatar,
    phone,
    handleChangePhone,
    Controller,
    control,
    option,
    options,
    onChangeAddress,
    address,
    isLoadParoisse,
    pays,
    selectedPays,
    onSelectPays,
    setQuery,
    setSearch,
    isSuccess,
    onSelectGrade,
    paroisses,
    onSelectParoisse,
    selected,
    optionsModeles,
    optionModele,
    onSelectModele,
    isLoadCertificat,
    handleParoisseFocus,
    showParoisseError
  };
}

export default UseCrudResponsableParoissien;

export function UseDeleteMembre(item: IMembre) {
  const [deleteData] = useDeleteMembreMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce membre ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(item?.slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: `Membre supprimé avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}
