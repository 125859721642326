import React from "react";
import Modal from "react-bootstrap/Modal";
import { MdNotifications } from "react-icons/md";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { IReunion } from "../../../../utils/api/reunion/reunion.type";

const ViewDetailsReunionModal = ({
  modalShowDetailsReunion,
  setModalShowDetailsReunion,
  reunion,
}: {
  modalShowDetailsReunion?: any;
  setModalShowDetailsReunion: any;
  reunion?: IReunion;
}) => {
  function closeModalShowDetailsReunion() {
    setModalShowDetailsReunion(false);
  }
  console.log("reunion", reunion)
  return (
    <Modal
      show={modalShowDetailsReunion}
      onHide={() => {
        // handleReset();
        closeModalShowDetailsReunion();
      }}
      aria-labelledby="Show Details reunion Modal"
      animation={false}
      centered
    >
      <Modal.Body>
        <div className="show_affectation_modal">
          <div className="modal-header border-0 align-items-center justify-content-between pe-2 py-1">
            <div className="photo-and-name d-flex align-items-center gap-2">
              <span className="name">
                {reunion?.titre}
              </span>
            </div>
            <div className="nofif-icon-wrapper">
              <MdNotifications color="#EB5757" size={32} />
            </div>
          </div>
          <div className="modal-body pt-4">
            <p className="m-0 pb-2 actual-affectation">
              <span className="sm-title">
                Date de la reunion:&nbsp;&nbsp;
              </span>
              <span className="sm_value">
                {moment(reunion?.date).format("ll")}
              </span>
            </p>
            <p className="m-0 pb-2 finish-date-affectation">
              <span className="sm-title">
                Heure de début:&nbsp;&nbsp;
              </span>
              <span className="sm_value">
                {moment(reunion?.heure_debut, "HH:mm").format("HH:mm A")}{" "}
              </span>
            </p>
            <p className="m-0 pb-2 finish-date-affectation">
              <span className="sm-title">
                Heure de fin:&nbsp;&nbsp;
              </span>
              <span className="sm_value">
                {moment(reunion?.heure_fin, "HH:mm").format("HH:mm A")}{" "}
              </span>
            </p>
            <p className="m-0 pb-2 next-affectation">
              <span className="sm-title">
                Pays:&nbsp;&nbsp;
              </span>
              <span className="sm_value">
                {reunion?.pays?.nom}
              </span>
            </p>
            <div className="btn-content pt-4">
              <NavLink
                to={"/admin/reunions"}
                className="btn btn-theme-admin"
                state={reunion}
                onClick={() => {
                  closeModalShowDetailsReunion();
                }}
              >
                <span>Voir tous les reunions</span>
              </NavLink>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewDetailsReunionModal;
