import React, { useState } from "react";
import "./HeaderAdmin.css";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import SidebarMobile from "../SidebarAdmin/SidebarMobile";
import "../SidebarAdmin/SidebarAdmin.css";
import { IoNotifications } from "react-icons/io5";
import { useAppSelector } from "../../../../redux/hook";
import { createMarkup, getAvatar } from "../../../../utils/Utils";
import {
  useGetNotificationQuery,
  useUpdateReadNotificationMutation,
} from "../../../../utils/api/notification/notification.api";
import { INotification } from "../../../../utils/api/notification/notification.type";
import moment from "moment";
import ViewDetailsReunionModal from "../../../admin/ConseilAdministration/modal/ViewDetailsReunion";
import { IReunion } from "../../../../utils/api/reunion/reunion.type";

function getRoute(item: INotification) {
  let route = "/";
  if (item?.type === "pasteur_creation" || item?.type === "pasteur") {
    route = `/admin/pasteurs/${item?.data?.slug}`;
  }
  if (item?.type === "secretaire") {
    route = `/admin/secretaries/${item?.data?.slug}`;
  }
  if (item?.type === "message_contact") {
    route = "/admin/messages";
  }

  if (item?.type === "creation_membre") {
    if (item?.data?.conseil_administration) {
      route = `/admin/conseil-administration/${item?.data?.slug}`;
    }

    if (item?.data?.conseil_pastoral) {
      route = `/admin/conseil-pastoral/${item?.data?.slug}`;
    }
    if (item?.data?.office_pastoral) {
      route = `/admin/office-pastoral/${item?.data?.slug}`;
    }
  }
  if (item?.type === "membre") {
    if (item?.content?.toLowerCase().includes("responsable paroissien")) {
      route = `/admin/responsable-paroissien/${item?.data?.slug}`;
    }

    if (item?.content?.toLowerCase().includes("un membre du conseil administration")) {
      route = `/admin/conseil-administration/${item?.data?.slug}`;
    }

    if (item?.content?.toLowerCase().includes("un membre du conseil pastoral")) {
      route = `/admin/conseil-pastoral/${item?.data?.slug}`;
    }
  }
  if (item?.type === "chef_diocese") {
    route = `/admin/responsable-dioceses/${item?.data?.slug}`;
  }
  if (item?.type === "reunion") {
    route = `/admin/responsable-dioceses/${item?.data?.slug}`;
  }
  if (item?.type === "certificat") {
    route = `/admin/paroisses`;
  }

  return route;
}

const HeaderAdmin: React.FC = () => {
  const { user } = useAppSelector((state) => state.user);
  const [page] = useState(1);
  const [perPage] = useState(100);

  var [showNotifications, setShowNotifications] = React.useState(false);
  const [item, setItem] = useState<INotification>();
  const [updateNotif] = useUpdateReadNotificationMutation();
  const navigate = useNavigate();

  var onShowAndHideNotification = () => {
    if (showNotifications === false) {
      setShowNotifications(true);
    } else {
      setShowNotifications(false);
    }
  };

  const [show, setShow] = useState<boolean>(false);
  const [reunion, setReunion] = useState<IReunion>();

  const { data = { results: [], count: 0 } } = useGetNotificationQuery({
    limit: perPage,
    page,
  });
  const onUpdateReadNotification = (notif: INotification) => {
    if (notif?.type !== "reunion" && notif?.type !== "certificat") {
      setShowNotifications(false);
      setItem(notif);
    } else if (notif?.type === "certificat") {
      setShowNotifications(false);
      navigate(getRoute(notif), { state: "demandes-certificats" });
    } else {
      setReunion(notif?.data)
      setShowNotifications(false);
      setShow(true)
    }
    updateNotif({ slug: notif.slug, read: true });
  };

  const filterNotificationsByUser = (notifications: INotification[], userId: number) => {
    return notifications.filter(notif => {
      if (notif.type !== "reunion") {
        return true; // Toutes les notifications sauf "reunion"
      } else {
        return notif?.receiver?.id === userId; // Les notifications "reunion" avec le bon receiver
      }
    });
  };

  return (
    <div className="admin-header-component">
      <nav className="empty-fluidy alling-header navbar navbar-expand-md navbar-dark fixed-top">
        <div className="headering-admin">
          <div className="header-others-items">
            <div className="container-info-user-connect-header">
              <div className="container-menu-header-admin gap-3">
                <div
                  className="notification-item-container linkable position-relative"
                  onClick={() => onShowAndHideNotification()}
                >
                  <div className="notification-item-content">
                    <IoNotifications className="notif-icon" />
                    {filterNotificationsByUser(data?.results, user?.id)
                      ?.filter((x) => x.read !== true)?.length ==
                      0 ? null : (
                      <span className="notification-counter">
                        {filterNotificationsByUser(data?.results, user?.id)
                          ?.filter((x) => x.read !== true)?.length}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="connected-user-container" id="header-user-admin">
                <div className="connected-user-content ml-auto">
                  <div className="dropdown dis-flex">
                    <NavLink
                      className="user-acount-dashboard-header dropdown-toggle btn"
                      to="/admin/profil"
                    >
                      <div className="user-acount-dashboard-header-display g-2">
                        <img
                          src={getAvatar(user?.avatar)}
                          className="user-avatar"
                          alt="User Avatar"
                        />
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="hiddenable-nav-mobile-container no-view-desktop">
                <div className="hiddenable-nav-mobile-content">
                  <div className="container-nav-mobile">
                    <SidebarMobile />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showNotifications && (
          <div
            className={
              filterNotificationsByUser(data?.results, user?.id)
                ?.filter((x) => x.read !== true)?.length
                ? "header-hiddenable-notification-section"
                : "header-hiddenable-notification-section"
            }
          >
            <ul
              className={
                filterNotificationsByUser(data?.results, user?.id)
                  ?.filter((x) => x.read !== true)?.length
                  ? "header-hiddenable-notification-ul"
                  : "header-hiddenable-notification-ul"
              }
            >
              {filterNotificationsByUser(data?.results, user?.id)
                ?.filter((x) => x.read !== true)?.length == 0 ? (
                <li
                  className="header-hiddenable-notification-li"
                  style={{ fontSize: 16 }}
                >
                  <div className="notification-item-link d-flex no-link">
                    <div className="content-text-notif ps-2">
                      <p className="text-notif mb-1">
                        Il n'y a aucune notification .
                      </p>
                    </div>
                  </div>
                </li>
              ) : (
                filterNotificationsByUser(data?.results, user?.id)
                  ?.filter((x) => x.read !== true)
                  ?.map((item) => (
                    <li
                      key={item?.slug}
                      className="header-hiddenable-notification-li"
                      onClick={() => onUpdateReadNotification(item)}
                    >
                      {(item?.type !== "reunion" && item?.type !== "certificat") ? <NavLink
                        to={getRoute(item)}
                        className="notification-item-link d-flex no-link"
                        state={item}
                      >
                        <div className="content-text-notif ps-2">
                          <p
                            className="text-notif mb-1"
                            dangerouslySetInnerHTML={createMarkup(
                              item?.content
                            )}
                          />
                          <p className="text-date-notif mb-0">
                            {moment(item?.created_at).format(
                              "DD/MM/yy - HH:mm"
                            )}
                          </p>
                        </div>
                      </NavLink> :
                        <div
                          className="notification-item-link d-flex no-link"
                        >
                          <div className="content-text-notif ps-2">
                            <p
                              className="text-notif mb-1"
                              dangerouslySetInnerHTML={createMarkup(
                                item?.content
                              )}
                            />
                            <p className="text-date-notif mb-0">
                              {moment(item?.created_at).format(
                                "DD/MM/yy - HH:mm"
                              )}
                            </p>
                          </div>
                        </div>
                      }
                    </li>
                  ))
              )}
            </ul>
          </div>
        )}
      </nav>
      <ViewDetailsReunionModal
        modalShowDetailsReunion={show}
        setModalShowDetailsReunion={setShow}
        reunion={reunion}
      />
    </div>
  );
};

export default HeaderAdmin;
