import { ObjetReligieux } from "../../../utils/api/objetReligieux/objetReligieux.type";
import { createMarkup, getImage, useLocationState } from "../../../utils/Utils";


function DetailsObjet() {
    const item = useLocationState<ObjetReligieux>(undefined);
    return (
        <div className="dashbord-admin-component">
            <div className="dash-admin-page-content-container mb-3">
                <h2 className="chart-item-admin-title mb-4">Objets religieux</h2>
            </div>

            <div className="objects-page-content">
                <div className="custom-content pt-4">
                    <div className="row h-100">
                        <div className="col-md-6 mb-3 d-flex">
                            <div className="details-objet-image-container w-100">
                                <img
                                    src={getImage(item?.image)}
                                    alt={item?.nom}
                                    className="details-objet-image h-100 w-100 object-fit-cover"
                                />
                            </div>
                        </div>
                        <div className="col-md-6 mb-3 d-flex px-3">
                            <div className="w-100">
                                <div className="details-objet-nom-boutique mb-3">
                                    {item?.nom_magasin}
                                </div>
                                <div className="details-objet-numero-produit mb-3">Position du produit: <span>{item?.position}</span></div>
                                <div className="details-objet-numero-produit mb-3">{item?.nom}</div>
                                <div className="details-objet-sub-infos mb-3">
                                    <div className="details-objet-sub-infos-label">Adresse</div>
                                    <div className="details-objet-sub-infos-value">{item?.ville_magasin}</div>
                                </div>
                                <div className="details-objet-sub-infos mb-3">
                                    <div className="details-objet-sub-infos-label">Telephone</div>
                                    <div className="details-objet-sub-infos-value">{item?.tel_magasin}</div>
                                </div>
                                <div className="details-objet-sub-infos mb-3">
                                    <div className="details-objet-sub-infos-label">Email</div>
                                    <div className="details-objet-sub-infos-value">{item?.email_magasin}</div>
                                </div>
                                <div className="details-objet-sub-infos mb-3">
                                    <div className="details-objet-sub-infos-label">Pays</div>
                                    <div className="details-objet-sub-infos-value">{item?.pays?.nom}</div>
                                </div>
                                <div className="details-objet-sub-infos mb-3">
                                    <div className="details-objet-sub-infos-label">Ville</div>
                                    <div className="details-objet-sub-infos-value">{item?.ville_magasin}</div>
                                </div>
                                <div>
                                    <div className="details-objet-description-boutique-title mb-3">Description de la boutique</div>
                                    <div className="details-objet-description-boutique"
                                        dangerouslySetInnerHTML={createMarkup(
                                            item?.description_magasin
                                        )}
                                    />
                                    {/* Lorem ipsum dolor sit amet consectetur. Libero at nunc sapien odio ac vel. Cras donec in pretium mauris nunc id. Elementum mi accumsan donec duis. Vitae mauris placerat nisl orci sem donec ante feugiat est. Nisl vitae diam viverra sed dui. Tempus lectus erat proin tellus tristique. 
                                    faucibus tempus ipsum nulla vitae pellentesque. Sagittis natoque commodo vel turpis fermentum suspendisse sed quis neque. Consectetur purus tristique placerat mi sed vulputate morbi. Faucibus volutpat suspendisse ut sed dolor. Ut interdum sollicitudin tincidunt convallis massa odio amet ornare. Sagittis id bibendum sagittis etiam euismod orci. Mauris nisi sagittis sit.</div> */}
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-12 mb-3 mt-5">
                            <div className="details-objet-titre-autre-produits mb-3">Autres produits de cette boutique</div>
                            <div className="row">
                                {[...Array(4)]?.map((item, i) => (
                                    <div className="col-md-3 mb-3" key={i}>
                                        <div className="details-objet-titre-autre-produits-img-container">
                                            <img src={getImage(item?.image)} alt={item?.nom} className="details-objet-titre-autre-produits-img" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetailsObjet