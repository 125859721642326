import React, { useEffect, useState } from "react";
import "./ObjetAdmin.css";
import { MdAddCircleOutline } from "react-icons/md";
import { Link, NavLink } from "react-router-dom";
import { CustomPagination } from "../../common/CustomPagination";
import { AiOutlineMore } from "react-icons/ai";
import { ObjetReligieux } from "../../../utils/api/objetReligieux/objetReligieux.type";
import { createMarkup, getImage, truncateString } from "../../../utils/Utils";
import { useGetListObjetReligieuxQuery } from "../../../utils/api/objetReligieux/objetReligieux.api";
import { AlertInfo } from "../../common/Alert";
import { UseDeleteObjetReligieux } from "./form/AddOrUpdateForm";
import { useAppSelector } from "../../../redux/hook";
import { isSecretaireOfficePastoral, isSuperAdmin } from "../../../routes/routerUtils";
import UpdateOffrePositionModal from "./Modals/UpdatePositionModal";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../utils/type";
import PresentationTab from "./tabs/PresentationTab";
import SalesTab from "./tabs/Salestab";

const steps = [
  { id: "presentation", Component: PresentationTab },
  { id: "sales", Component: SalesTab },
];
const tabs = ["Présentation des objets", "Objets en vente"];

const BtnDelete = ({ slug }: { slug: string }) => {
  const onDelete = UseDeleteObjetReligieux(slug);
  return (
    <button className="dropdown-item" onClick={onDelete}>
      Supprimer
    </button>
  );
};

function ObjetAdmin() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const [page, setPage] = useState(1);
  const { data = { results: [], count: 0 }, isLoading } =
    useGetListObjetReligieuxQuery({
      page,
      limit: 9,
    });
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));
  const is_SecretaireOfficePastoral = useAppSelector((s) =>
    isSecretaireOfficePastoral(s.user?.user)
  );

  const props = {
    data,
    isLoading,
    is_SuperAdmin,
    is_SecretaireOfficePastoral,
    step
  };
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <h2 className="chart-item-admin-title mb-4">Objets religieux</h2>
      </div>

      <div className="eglise-dash-tabs mb-3 bg-white pt-3 px-4">
        <ul className="tabs-container tabs-container-admin mb-0">
          {tabs.map((tab: any, key: any) => (
            <li
              className={`tab-item  ${index === key && "tab-active-item-admin"
                }`}
              key={key}
              onClick={() => go(key)}
              role="button"
            >
              {tab}
            </li>
          ))}
        </ul>
      </div>

      {(is_SuperAdmin || is_SecretaireOfficePastoral) && 
      <div className="btn-action-container">
        <NavLink
          role="button"
          to={`/admin/objets-religieux/ajouter`}
          className="btn custom-add-btn"
        >
          Ajouter un nouvel objet
          <MdAddCircleOutline className="add-icon" />
        </NavLink>
      </div>}

      <div className="objects-page-content">
        <div className="custom-content pt-4">
          <Component {...props} />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPage(page);
              }}
              perPage={9}
              showPerPage={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export const ObjetCard = ({ item, is_SuperAdmin, is_SecretaireOfficePastoral, step }: { item: ObjetReligieux, is_SuperAdmin?: boolean, is_SecretaireOfficePastoral?: boolean, step?: any }) => {
  const { nom, image, slug, position, description_magasin, nom_magasin, tel_magasin, ville_magasin } = item;
  const [itemP, setItemP] = useState<any>();

  const [modalUpdateOffrePosition, setModalUpdateOffrePosition] = useState(false);

  function openModalUpdateOffrePosition() {
    setModalUpdateOffrePosition(true);
  }

  return (
    <>
      <div className="objet-card">
        <div className="cover-wrapper position-relative">
          <img src={getImage(image)} alt={nom} />
          {step.id === "presentation" && (
            <div className="custom-btn-position-container">
              <button
                className={`btn custon-btn-position px-4`}
                onClick={() => {
                  if (is_SuperAdmin || is_SecretaireOfficePastoral) {
                    setItemP(item),
                      openModalUpdateOffrePosition()
                  }
                }}
              >
                {position}
              </button>
            </div>
          )}
          <div className="btn-group custom-btn-groupe-position">
            {(is_SuperAdmin || is_SecretaireOfficePastoral) && <button
              className="btn btn-sm dropdown-toggle documentation-archive-custom-btn d-flex justify-ontent-center align-items-center"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <AiOutlineMore style={{ fontSize: 18 }} />
            </button>}
            <ul className="dropdown-menu">
              <li>
                <NavLink
                  role="button"
                  to={`/admin/objets-religieux/modifier/${slug}`}
                  className="dropdown-item"
                  state={item}
                >
                  Modifier
                </NavLink>
              </li>
              <li>
                <BtnDelete slug={slug} />
              </li>
            </ul>
          </div>
        </div>
        <div className="py-3 px-2 w-100 wrapper no-link">
          <NavLink to={`/admin/objets-religieux/${slug}`} state={item} className="no-link">
            <p className="mb-3 object__name">{nom}</p>
            {step.id === "presentation" && (
              <div
                dangerouslySetInnerHTML={createMarkup(
                  truncateString(description_magasin, 200)
                )}
              />
            )}
            {step.id === "sales" && (
              <>
                <div className="d-flex justify-content-between gap-3">
                  <div className="mb-3 object__name" style={{ fontSize: "11px" }}>
                    {nom_magasin}
                  </div>
                  <div className="mb-3 object__name" style={{ fontSize: "11px" }}>
                    {ville_magasin}
                  </div>
                </div>
                <div className="d-flex justify-content-between gap-3">
                  <div className="mb-3 object__name" style={{ fontSize: "11px" }}>
                    {tel_magasin}
                  </div>
                </div>
              </>
            )}
          </NavLink>
        </div>
      </div>
      <UpdateOffrePositionModal
        modalUpdateOffrePosition={modalUpdateOffrePosition}
        setModalUpdateOffrePosition={setModalUpdateOffrePosition}
        item={itemP}
      />
    </>
  );
};



export default ObjetAdmin;
