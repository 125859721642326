import { useState } from "react";
import DemandesCertificatTable from "../tables/DemandesCertificatTable";

function DemandeCertificats() {
    const [query, setQuery] = useState("")

    return (
        <div className="tabs-component-paroisse pt-4">
          <DemandesCertificatTable q={query} />
        </div>
    );
  }
  export default DemandeCertificats;