import React from "react";
import Modal from "react-bootstrap/Modal";
import { animatedComponents, FormError, Input } from "../../../common/Input";
import UseCrudReunion from "../requestForm/UseCrudReunion";
import Select from "react-select";


export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    zIndex: 99999,
  },
};

function AddMeetingModal({
  modalAddMeeting,
  setModalAddMeeting,
  membre_type,
  reunion,
}: {
  modalAddMeeting: any;
  setModalAddMeeting: any;
  membre_type?: any;
  reunion?: any;
}) {
  function closeModalAddMeeting() {
    setModalAddMeeting(false);
  }
  const { 
    register, 
    onSubmit, 
    errors, 
    isLoading, 
    handleReset,
    Controller,
    control,
    pays,
    onSelectPays,
    selectedPays, 
  } = UseCrudReunion(
    setModalAddMeeting,
    membre_type,
    reunion
  );
  return (
    <Modal
      show={modalAddMeeting}
      onHide={() => {
        handleReset();
        closeModalAddMeeting();
      }}
      aria-labelledby="Show Fidel Affectation Modal"
      animation={false}
      size="lg"
      centered
    >
      <Modal.Header className="modal-header border-0 p-3" closeButton>
        <Modal.Title className="modal-title">
          {reunion ? "Modifier la réunion" : "Programmer une réunion"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <form className="mt-4" onSubmit={onSubmit}>
            <div className="row row-add-student my-3">
              <div className="col-md-12 mb-3">
                <Input
                  type="text"
                  label="Titre"
                  id="titre"
                  placeholder="Titre"
                  {...register("titre")}
                  error={errors.titre}
                  required
                />
              </div>
              <div className="col-md-12 mb-3">
                <Input
                  type="date"
                  label="Date"
                  id="date"
                  placeholder="Date"
                  {...register("date")}
                  error={errors.date}
                  required
                />
              </div>
              <div className="col-md-12 mb-3">
                <Input
                  type="time"
                  label="Heure de début"
                  id="heure_debut"
                  placeholder="Heure de début"
                  {...register("heure_debut")}
                  error={errors.heure_debut}
                  required
                />
              </div>
              <div className="col-md-12 mb-3">
                <Input
                  type="time"
                  label="Heure de fin"
                  id="heure_fin"
                  placeholder="Heure de fin"
                  {...register("heure_fin")}
                  error={errors.heure_fin}
                  required
                />
              </div>
              <div className="col-md-12">
                <div className="mb-4">
                  <label
                    htmlFor={"pays"}
                    className={`form-label form-label-modal-custom`}
                    aria-labelledby={"pays"}
                  >
                    Pays{" "}
                  </label>
                  <Controller
                    name="pays"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          options={pays}
                          classNamePrefix="react-select-custom"
                          onChange={onSelectPays}
                          value={selectedPays}
                        />
                      );
                    }}
                  />
                  <FormError error={errors.pays} />
                </div>
              </div>

              <div className="col-md-6 offset-md-6 auth-submit-btn-container text-end pt-3">
                <button
                  disabled={isLoading}
                  className="btn btn-theme-admin w-100"
                  type="submit"
                >
                  {isLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                        role="status"
                      ></span>
                      <span>{reunion ? "Modification..." : "Ajout..."}</span>
                    </>
                  ) : reunion ? (
                    "Modifier"
                  ) : (
                    "Ajouter"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AddMeetingModal;
