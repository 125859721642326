import React, { useState } from "react";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../utils/type";
import BreadCrumb from "../../shared/BreadCrumb";
import "./DocumentationsAdmin.css";
import { BiSearchAlt2 } from "react-icons/bi";
import { BsFillFilterSquareFill } from "react-icons/bs";
import { MdAddCircleOutline } from "react-icons/md";
import ArchivesTab from "./DocumentationTabs/ArchivesTab";
import BibliothequesTab from "./DocumentationTabs/BibliothequesTab";
import AddArchivesModal from "./modals/AddArchivesModal";
import AddVideosModal from "./modals/AddVideosModal";
import { useAppSelector } from "../../../redux/hook";
import { isSecretaire } from "../../../routes/routerUtils";

const steps = [
  { id: "archive ", Component: ArchivesTab },
  { id: "bibliotheque", Component: BibliothequesTab },
];

const tabs = ["Archives ", "Bibliothèques"];

function DocumentationsAdmin() {
  const is_Secretaire = useAppSelector((s) =>
    isSecretaire(s.user?.user)
  );
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const [modalAddArchives, setModalAddArchives] = useState(false);
  const [modalAddVideo, setModalAddVideo] = useState(false);
  const [typeDocumentations, setTypeDocumentations] = useState(step?.id);
  const [formatDocumentations, setFormatDocumentations] = useState("");

  function openModalAddArchives() {
    setModalAddArchives(true);
    setTypeDocumentations("archive");
  }
  function openModalAddVideo() {
    setModalAddVideo(true);
    setTypeDocumentations("bibliotheque");
    setFormatDocumentations("videos");
  }
  function openModalAddVideoArchive() {
    setModalAddVideo(true);
    setTypeDocumentations("archive");
    setFormatDocumentations("videos");
  }

  const [showBtnVideo, setShowBtnVideo] = useState(false);
  const [query, setQuery] = useState("");
  const [queryFormat, setQueryFormat] = useState("texte");

  function handleChangeFormat(e) {
    setQueryFormat(e?.target?.value);
    if (e?.target?.value === "textes") {
      setShowBtnVideo(false);
    } else {
      setShowBtnVideo(true);
    }
  }

  const props = {
    openModalAddArchives,
    modalAddArchives,
    setModalAddArchives,
    modalAddVideo,
    setModalAddVideo,
    typeDocumentations,
    formatDocumentations,
    setTypeDocumentations,
    setFormatDocumentations,
    query,
    queryFormat,
    is_Secretaire
  };
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row mb-4">
          <BreadCrumb pageName="Documentations" />
        </div>
        <div className="content-graph-admin mb-2">
          <ul className="tabs-container tabs-container-admin pb-0 mb-0 pt-3">
            {tabs.map((tab: any, key: any) => (
              <li
                className={`tab-item  ${
                  index === key && "tab-active-item-admin"
                }`}
                key={key}
                onClick={() => go(key)}
                role="button"
              >
                {tab}
              </li>
            ))}
          </ul>
          {index === 0 ? (
            <div className="content-title-page-finance">
              <div className="content-select-filter-form py-3">
                <select
                  className="form-control form-control-select"
                  onChange={(e) => handleChangeFormat(e)}
                >
                  <option value="textes">Textes</option>
                  <option value="videos">Vidéos</option>
                </select>
              </div>
              <div className="content-form-filter d-flex align-items-center gap-2">
                <form>
                  <div className="content-form-search-bloc position-relative">
                    <input
                      type="search"
                      className="form-control form-control-search"
                      placeholder="Tapez pour rechercher"
                      value={query}
                      onChange={(e) => setQuery(e?.target?.value)}
                    />
                    <span className="container-icon-searh-form">
                      <BiSearchAlt2 />
                    </span>
                  </div>
                </form>
                {/* <button className="btn btn-icon-filter">
                  <BsFillFilterSquareFill />
                </button> */}
              </div>
            </div>
          ) : null}
          {is_Secretaire && <div className="page-title-container my-2">
            {index === 0 ? (
              <div className="container-display-title-theme">
                {showBtnVideo ? (
                  <button
                    className="btn btn-theme-admin"
                    onClick={(e) => {
                      openModalAddVideoArchive();
                    }}
                  >
                    <span>Ajouter une vidéo</span>
                    <span className="ps-2">
                      <MdAddCircleOutline />
                    </span>
                  </button>
                ) : (
                  <button
                    className="btn btn-theme-admin"
                    onClick={(e) => {
                      openModalAddArchives();
                    }}
                  >
                    <span>Ajouter un texte</span>
                    <span className="ps-2">
                      <MdAddCircleOutline />
                    </span>
                  </button>
                )}
              </div>
            ) : (
              <div className="container-display-title-theme">
                <button
                  onClick={(e) => {
                    openModalAddVideo();
                  }}
                  className="btn btn-theme-admin"
                >
                  <span>Ajouter une photo/vidéo</span>
                </button>
              </div>
            )}
          </div>}
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="tabs-component-container-finance">
              <Component {...props} />
            </div>
          </div>
        </div>
      </div>
      <AddArchivesModal
        modalAddArchives={modalAddArchives}
        setModalAddArchives={setModalAddArchives}
        typeDocumentations={typeDocumentations}
        setTypeDocumentations={setTypeDocumentations}
      />
      <AddVideosModal
        modalAddVideo={modalAddVideo}
        setModalAddVideo={setModalAddVideo}
        typeDocumentations={typeDocumentations}
        setTypeDocumentations={setTypeDocumentations}
        formatDocumentations={formatDocumentations}
        setFormatDocumentations={setFormatDocumentations}
      />
    </div>
  );
}

export default DocumentationsAdmin;
