import React, { useCallback } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import {
  IPasteur,
  PasteurFormData,
} from "../../../../utils/api/pasteur/pasteur.type";
import { useAddOrEditActualPasteurMutation, useAddOrEditPasteurMutation, useDeletePasteurMutation } from "../../../../utils/api/pasteur/pasteur.api";
import { cleannerError, validatePhone } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";
import { Data } from "../../../common/GoogleInput";
import { IUser } from "../../../../utils/api/user/user.type";
import moment from 'moment';
import { Authorisations } from "../../../../utils/constante";

function UseCrudPasteur(pasteur?: IPasteur | IUser) {
  const [code, setCode] = useState<string>("");
  const [phone, setPhone] = useState<string>();
  const [bio, setBio] = useState<string>("");
  const validationSchema = yup.object().shape({
    prenom: yup.string().required("Prenom est obligatoire").label("Prénom"),
    nom: yup.string().required("Nom est obligatoire").label("Nom"),
    email: yup
      .string()
      .email("L'email est invalide")
      .required("Email est obligatoire"),
      date_debut_pasteur: yup
      .date()
      .required("Date de début est obligatoire")
      .typeError("Le format de la date est incorrect. Utilisez le format AAAA-MM-JJ.")
      .transform((value, originalValue) => {
        const parsedDate = originalValue ? parseDate(originalValue) : null;
        return parsedDate && parsedDate.isValid() ? parsedDate.toDate() : null;
      })
      .label("Date de début"),
    date_fin_pasteur: yup
      .date()
      .nullable()
      .transform((value, originalValue) => {
        const parsedDate = originalValue ? parseDate(originalValue) : null;
        return parsedDate && parsedDate.isValid() ? parsedDate.toDate() : null;
      })
      .typeError("Le format de la date est incorrect. Utilisez le format AAAA-MM-JJ.")
      .min(
        yup.ref('date_debut_pasteur'),
        "La date de fin ne peut pas être antérieure à la date de début"
      )
      .label("Date de fin"),
    telephone: validatePhone(yup, code),
    adresse: yup.string().label("Adresse").required().nullable(),
    boite_postale: yup.string().label("Boite postale").nullable(),
    bio: yup.string().label("Historique").nullable(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
  } = useForm<PasteurFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [address, setAddress] = useState("");
  const [content, setContent] = useState<string>("");
  const [addOrUpdatePasteur, { isLoading, isSuccess, error, isError }] =
    useAddOrEditPasteurMutation();
  const [addOrUpdateActualPasteur, { isLoading: isLoadActual, isSuccess: isSucc, error: erre, isError: isErr }] =
    useAddOrEditActualPasteurMutation();

  const navigate = useNavigate();

  const handleChangePhone = (item, country) => {
    setCode(country?.countryCode);
    setPhone(item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue(`telephone`, "+" + item);
    } else {
      setValue(`telephone`, "");
    }
  };

  const onChangeAddress = (val: Data) => {
    setAddress(val?.rue);
    setValue("adresse", val?.rue);
  };

  const handleChangeBio = React.useCallback((text: string) => {
    setBio(text);
    setValue("bio", text);
  }, [])

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: pasteur
          ? "Pasteur modifié avec succès !"
          : "Pasteur ajouté avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (!pasteur) {
          reset();
          setContent("");
        }
        navigate("/admin/pasteurs");
      });
    }
    const err = error as any;
    if (isError) {
      if (err?.data?.email?.includes("l'utilisateur avec cet email existe déjà.")) {
        Swal.fire({
          icon: "error",
          title: "Un utilisateur avec cet e-mail existe déjà.",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Error status ${err?.status}`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (isSucc) {
      Swal.fire({
        icon: "success",
        title: pasteur
          ? "Pasteur modifié avec succès !"
          : "Pasteur ajouté avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (!pasteur) {
          reset();
          setContent("");
        }
        navigate("/admin/pasteurs");
      });
    }
    const err = erre as any;
    if (isErr) {
      if (err?.data?.email?.includes("l'utilisateur avec cet email existe déjà.")) {
        Swal.fire({
          icon: "error",
          title: "Un utilisateur avec cet e-mail existe déjà.",
          showConfirmButton: false,
          timer: 2000,
        });
      } else if (err?.data?.message?.includes("Poste de pasteur déjà occupé.")) {
        Swal.fire({
          icon: "error",
          title: "Poste de pasteur déjà occupé.",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Error status ${err?.status}`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [isLoadActual]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = (data: any) => {
    if (data?.toujours) {
      data["date_fin_pasteur"] = "";
      data["actuel_pasteur"] = true;
    } else {
      data["actuel_pasteur"] = false;
    }

    if (data.date_debut_pasteur) {
      data.date_debut_pasteur = formatDate(data.date_debut_pasteur);
    }
    if (data.date_fin_pasteur) {
      data.date_fin_pasteur = formatDate(data.date_fin_pasteur);
    }

    if (!data.date_fin_pasteur) {
      delete data.date_fin_pasteur;
    }

    Authorisations.forEach((item) => {
      data[item?.field] = true
    });
    let fd = new FormData();
    for (let key of Object.keys(data)) {
      fd.append(key, data[key]);
    }
    data?.toujours ? addOrUpdateActualPasteur({ slug: pasteur?.slug, data: fd }) : addOrUpdatePasteur({ slug: pasteur?.slug, data: fd });
  };

  const handleReset = () => {
    if (!pasteur) {
      reset();
    }
  };

  useEffect(() => {
    if (pasteur?.id) {
      
      const fields: (keyof PasteurFormData)[] = [
        "prenom",
        "nom",
        "email",
        "date_debut_pasteur",
        "date_fin_pasteur",
        "telephone",
        "adresse",
        "boite_postale",
        "actuel_pasteur"
      ];

      for (let field of fields) {

        if (field === "date_debut_pasteur" || field === "date_fin_pasteur") {
          const dateValue = pasteur[field];
          console.log('dateValue', dateValue)
          if (dateValue) {
            const formattedDate = formatDate(dateValue);
            setValue(field, formattedDate);
          } else {
            setValue(field, null);
          }
        } else {
          setValue(field, pasteur[field]);
        }
      }
      if (pasteur?.telephone) {
        setPhone(pasteur?.telephone);
      }
      if (pasteur?.bio) {
        setBio(pasteur?.bio);
      }
      if (pasteur?.actuel_pasteur) {
        setValue("toujours", pasteur["actuel_pasteur"]);
      }

      setAddress(pasteur?.adresse);
    }
  }, [pasteur]);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    isLoadActual,
    content,
    handleReset,
    phone,
    handleChangePhone,
    address,
    onChangeAddress,
    bio, 
    handleChangeBio
  };
}

export default UseCrudPasteur;

function parseDate(dateString: string): moment.Moment | null {
  const formats = ['YYYY-MM-DD', 'DD/MM/YYYY', 'MM/DD/YYYY'];
  const parsedDate = moment(dateString, formats, true);
  return parsedDate.isValid() ? parsedDate : null;
}

function formatDate(date: Date | string): string {
  return moment(date).format('YYYY-MM-DD');
}

export function UseDeletePasteur(item: IPasteur) {
  const [deleteData] = useDeletePasteurMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce pasteur ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(item?.slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: `Pasteur supprimé avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}